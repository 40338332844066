import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, BehaviorSubject, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { Field, Update } from '@modules/programs/program-interfaces';

interface BulkUpdate {
  id: number;
  dateAndTime: Date;
  details: string;
  programs: number[];
}
@Injectable({
  providedIn: 'root',
})
export class ProgramsService {
  setAdditionalRequirementsSubject: any = new BehaviorSubject<string>('');

  public bulkUpdates: BulkUpdate;

  additionalRequirements: any;

  constructor(private http: HttpClient) {}

  // get program images *
  managerhostingprogramimages(prefix: any): Observable<any> {
    return this.http.get(`${environment.api}/configs/static?prefix=${prefix}`);
  }

  // get program dependent documents for applications *
  managerhostingprogramdocuments(prefix: any): Observable<any> {
    return this.http.get(`${environment.api}/managements/objectlist?prefix=${prefix}/documents`);
  }

  // remove document from a program in aws *
  removeS3Document(document: any): Observable<any> {
    return this.http.get(`${environment.api}/managements/objectremove?key=${document}`);
  }

  // *************************** START HOSTING ENDPOINTS ****************************

  // main program details for hosts *
  managerhostingprogramshow(id: string): Observable<any> {
    return this.http.get(`${environment.api}/managements/${id}/managerhostingprogramshow`);
  }

  // get applications that are tied to a certain program *
  hostingprogramapplicationslist(programid: string, query?: any): Observable<any> {
    const encodedQuery = encodeURI(`query=${JSON.stringify(query)}`);
    return this.http.get(`${environment.api}/managements/${programid}/hostingprogramapplicationslist?${encodedQuery}`);
  }

  // update program with new values *
  managerhostingprogramupdate(programid: string, program: any): Observable<any> {
    return this.http.put(`${environment.api}/managements/${programid}/managerhostingprogramupdate`, program);
  }

  /**
   * @description amo employee save program endpoint
   * @param program program form
   * @returns object
   */
  amomanagerprogramsave(programid: string, program: any): Observable<any> {
    return this.http.put(`${environment.api}/programs/${programid}/update`, program);
  }

  // create a new program *
  managerCreateProgram(program: any) {
    return this.http.post(`${environment.api}/managements/managerhostingcreatenewprogram`, program);
  }

  // create a new program *
  amoManagerCreateProgram(program: any) {
    return this.http.post(`${environment.api}/programs/newprogram`, program);
  }

  // list of hosts tied to a certain program *
  managerHostsList() {
    return this.http.get(`${environment.api}/managements/managerhostinghostslist`);
  }

  // *************************** END HOSTING ENDPOINTS ****************************

  // *************************** START AMOEMPLOYEE ENDPOINTS ****************************

  // main program details for AMO admins *
  adminprogramshow(id: string): Observable<any> {
    return this.http.get(`${environment.api}/managements/${id}/adminprogramshow`);
  }

  amoprogramshow(id: string): Observable<any> {
    return this.http.get(`${environment.api}/programs/${id}/adminshow?ngsw-bypass=true`);
  }

  programAvailabilityList(programId: string): Observable<any> {
    // 100000177 no program id (only pid entering)
    return this.http.get(`${environment.api}/programs/${programId}/listavailability`);
  }

  amoShowAdminProgram(programId: string): Observable<any> {
    return this.http.get(`${environment.api}/programs/${programId}/adminpublicshow`);
  }

  // create a new program *
  programselectquery(query: any) {
    return this.http.post(`${environment.api}/programs/programselectquery`, query);
  }

  createprogrambundle(form: any) {
    return this.http.post(`${environment.api}/programbundles/create`, form);
  }

  getlistApplicationRequirement(query: any) {
    return this.http.post(`${environment.api}/applicationrequirementmaps/listapplicationrequirements`, query);
  }

  AddExistingRequirementSelectionquery(query: any) {
    return this.http.post(`${environment.api}/applicationrequirements/applicationrequirementselectquery`, query);
  }

  //addexisting requirement
  createNewAssociation(data: any) {
    return this.http.post(`${environment.api}/applicationrequirementmaps/createnewassociation`, data);
  }

  // *************************** END AMOEMPLOYEE ENDPOINTS ****************************

  // UTILITY FUNCTIONS

  /**
   *
   * @param images program images
   * @returns list of images for program details pages
   * @description Common filter for images to display program details
   */
  programImagesFilter(images: any[]): any[] {
    if (images.length > 0) {
      const modifiedImageList = [...images].filter(image => {
        if (image.size === 'md' && image.Key.endsWith('.webp')) {
          return image;
        }
      });
      return modifiedImageList;
    } else {
      return [];
    }
  }

  programapplicationslist(programid: string, query: any) {
    const encodedQuery = `query=${encodeURIComponent(JSON.stringify(query))}`;
    return this.http.get(`${environment.api}/applications/fullindex?${encodedQuery}&ngsw-bypass=true`);
  }

  /**
   *
   * @returns list of coupons without filter
   */
  programCoupons(): Observable<any> {
    return this.http.get(`${environment.api}/coupons`);
  }

  /**
   *
   * @returns creates coupon with valid form payload
   */
  createCoupon(payload: any): Observable<any> {
    return this.http.post(`${environment.api}/coupons/create`, payload);
  }

  /**
   * Export the Grid to CSV
   * @param queryObject
   * @param columnHeaders
   */
  exportGridToCsv(queryObject: any, columnHeaders: any): Observable<any> {
    return this.http.post(`${environment.api}/programs/exportprogramscsv`, {
      query: JSON.stringify(queryObject),
      columns: JSON.stringify(columnHeaders),
    });
  }

  /**
   * @description Delete program from database superuser only
   * @param id Program ID delete
   * @returns It returns http api response obj
   */
  deleteProgram(id: string): Observable<any> {
    return this.http.delete(`${environment.api}/programs/${id}`);
  }

  /**
   * Bulk update programs
   * @param updateObject
   */
  bulkUpdate(selectedPrograms: any[], fieldArray: Field[], update: Update[]): Observable<any> {
    // OLD API REQUEST FOR REFERENCE
    //   {
    //     "ids": [
    //         "100000188",
    //         "100000189"
    //     ],
    //     "programtags": {
    //         "datatype": "csv",
    //         "action": "suffix",
    //         "actionName": "Suffix existing value with",
    //         "updatevalue": "eee",
    //         "updateLabel": "Program Tags"
    //     }
    //  }

    const updateOptions = { ids: [] };

    // add programs
    selectedPrograms.forEach(program => updateOptions.ids.push(program.id));

    // add all fields...
    fieldArray.forEach((field: Field) => {
      // list new entries by name
      const newEntry = {};

      newEntry['datatype'] = field.datatype;

      // add to object
      updateOptions[field.field] = newEntry;
    });

    update.forEach((update: Update) => {
      // list new entries by name
      const newEntry = {};
      newEntry['updatevalue'] = update.value || '';
      newEntry['field'] = update.field.field;
      newEntry['action'] = update.action;
      newEntry['datatype'] = update.field.datatype;

      // add to object
      updateOptions[update.field.field] = newEntry;
    });

    return this.http.post(`${environment.api}/programs/bulkupdate`, updateOptions);
  }

  validateHubspotProgram(programid: string, hubspotdealid: string): Observable<any> {
    return this.http.post(`${environment.api}/programs/${programid}/validateHubspotProgram?ngsw-bypass=true`, { hubspotdealid });
  }
}
