import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ApplicationService } from '../application/application.service';
import { format, addYears } from 'date-fns';
import { isNil, rangeOfYears } from '../../../shared/utils';
// Grid Components
import { GridAnchorRenderer } from './components/grid-anchor';
import { GridSelectionRenderer } from './components/grid-selection';
import GridFields from '../../../../assets/data/grid.json';
import { ListsService } from '../lists/lists.service';
import { ProgramsService } from '../programs/programs.service';
import moment, { utc } from 'moment';
import { FieldOfStudiesService } from '../../services/fieldofstudies/fieldofstudies.service';
import { getDeadlineDaysValues } from '../../../shared/utils';
import { amoJsonToString } from '../../../shared/utils';

interface GridSave {
  action: boolean;
  grid: string;
}

interface TextColumn {
  headerName: string;
  field: string;
  enablePivot: boolean;
  enableRowGroup: boolean;
  filter: string;
  sortable: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class GridsService {
  constructor(
    private applicationService: ApplicationService,
    private listService: ListsService,
    private programsService: ProgramsService,
    private fieldOfStudiesService: FieldOfStudiesService,
  ) {}

  // GRID ACTIONS TRIGGERS

  // Save Grid Action
  setSaveGridSubject: any = new BehaviorSubject<GridSave>({
    action: false,
    grid: '',
  });
  getSaveGridSubject = (): Observable<GridSave> => this.setSaveGridSubject.asObservable();

  // Refresh Grid Action
  setRefreshGridSubject: any = new BehaviorSubject<GridSave>({
    action: false,
    grid: '',
  });
  getRefreshGridSubject = (): Observable<GridSave> => this.setRefreshGridSubject.asObservable();

  // Reset Filters Grid Action
  setResetGridFiltersSubject: any = new BehaviorSubject<GridSave>({
    action: false,
    grid: '',
  });

  getResetGridSubject = (): Observable<GridSave> => this.setResetGridFiltersSubject.asObservable();

  // Reset Filters Grid Action
  setResetGridSubject: any = new BehaviorSubject<GridSave>({
    action: false,
    grid: '',
  });

  getResetGridsSubject = (): Observable<GridSave> => this.setResetGridSubject.asObservable();

  // Reset Pivots Grid Action
  setResetPivotGridSubject: any = new BehaviorSubject<GridSave>({
    action: false,
    grid: '',
  });
  getResetPivotGridSubject = (): Observable<GridSave> => this.setResetPivotGridSubject.asObservable();

  // Reset Pivots Grid Action
  setDownloadCSVGridSubject: any = new BehaviorSubject<GridSave>({
    action: false,
    grid: '',
  });
  getDownloadCSVGridSubject = (): Observable<GridSave> => this.setDownloadCSVGridSubject.asObservable();

  // Switch Selection Mode Grid Action
  setSwitchSelectionGridSubject: any = new BehaviorSubject<GridSave>({
    action: false,
    grid: '',
  });

  getSwitchSelectionGridSubject = (): Observable<GridSave> => this.setSwitchSelectionGridSubject.asObservable();

  // Export CSV All Grid Action
  setExportCSVAllGridSubject: any = new BehaviorSubject<GridSave>({
    action: false,
    grid: '',
  });
  getExportCSVAllGridSubject = (): Observable<GridSave> => this.setExportCSVAllGridSubject.asObservable();

  // Export CSV Visible Grid Action
  setExportCSVVisibleGridSubject: any = new BehaviorSubject<GridSave>({
    action: false,
    grid: '',
  });
  getExportCSVVisibleGridSubject = (): Observable<GridSave> => this.setExportCSVVisibleGridSubject.asObservable();

  // behavior subject to toggle selection mode based off queryParams (pgm_availability)
  setSelectionModeSubject: any = new BehaviorSubject<boolean>(false);
  getSelectionModeSubject = (): Observable<boolean> => this.setSelectionModeSubject.asObservable();

  /**
   * To add components or html elements to ag-grid cells,
   * 1. create component class file in ./components
   * 2. reference component in grid-components.module.ts
   * 3. add the component to this file
   * 4. use 'cellRenderer' to column with component name
   * reference: https://www.ag-grid.com/javascript-grid-cell-rendering-components/?utm_source=medium&utm_medium=blog&utm_campaign=mkaren#angular-methods-lifecycle
   */

  // TODO
  // make one default columns function

  dateColumns = [
    'startdate',
    'enddate',
    'expires',
    'expiredate',
    'visameetingdate',
    'accepteddate',
    'reserveddated',
    'enrolleddate',
    'signedcontract',
    'signedcontractdate',
    'createdAt',
    'applicationcreateddate',
    'updatedAt',
    'applicationupdateddate',
    'livedate',
    'birthday',
    'endsAt',
    'startsAt',
    'accountrcreated',
    'reserveddate',
    'migrationdocumentdate',
    'postponedtoreserveddate',
  ];

  priceHide = [
    'deposit',
    'depositid',
    'price',
    'pricechange',
    'untilchange',
    'checkoutprice',
    'paymentid',
    'paymenttype',
    'checkoutprice',
    'coupon',
    'coupons',
    'couponid',
    'pricedropamount',
    'pricedropenddate',
    'pricedropstartdate',
    'nolatefee',
    'stripeinvoicedepositid',
    'stripeorderid',
    'hourlyprice',
  ];

  hostProgramColumns = ['live', 'name', 'Host', 'offered', 'specialty', 'specialties', 'exposuretype'];

  hostHostColumns = ['name', 'phone', 'cellphone', 'workphone', 'workphoneext', 'experiencetypes', 'specialties', 'subspecialties'];

  hostDashBoardColumns = [
    'name',
    'states',
    'startmonth',
    'startyear',
    'startsAt',
    'endsAt',
    'reserveddate',
    'specialty',
    'specialties',
    'type',
    'physician',
    'visitoremail',
    'visitorphone',
    'resume',
  ];

  hospitalAdminDashBoardColumns = [
    'name',
    'states',
    'startmonth',
    'startyear',
    'startsAt',
    'endsAt',
    'reserveddate',
    'specialty',
    'specialties',
    'programname',
    'type',
    'physician',
    'visitoremail',
    'visitorphone',
    'resume',
  ];

  hostApplicationColumns = ['fullname', 'states', 'specialty', 'startdate', 'enddate', 'month', 'startyear'];

  vrProgramHide = ['description', 'months', 'featured', 'createdAt', 'programlocation', 'Host', 'live', 'programinternalname', 'deposit', 'permitted'];

  vrApplicationHide = ['coordinator'];

  generalProgramHide = [
    'tags',
    'physician',
    'contactname',
    'contactemail',
    'phone',
    'physicianemail',
    'trellourl',
    'startday',
    'startyear',
    'image',
    'quantity',
    'images',
    'deletedAt',
    'enddate',
    'description',
    'documents',
    'printabledocuments',
    'activities',
    'faxnumber',
    '',
  ];

  generalReviewHide = [
    'User',
    'Application',
    'Program',
    'skills',
    'schedule',
    'activities',
    'orientation',
    'discontentment',
    'advise',
    'additionalservices',
    'recommend',
    'housing',
    'predepartureinfo',
    'usercontent',
    'feedback',
  ];

  notEmployeeProgramHide = ['programinternalname'];
  hostingManagerHide = [
    'hubspotdealid',
    'offerswitchprotection',
    'hubspotproductid',
    'limbo',
    'interaltags',
    'additionalrequirements',
    'stipendcost',
    'notes',
    'programinternalname',
    'permitted',
    'deposit',
  ];

  paymentsGridHide = [
    'coupons',
    'couponid',
    'Management',
    'Application',
    'Program',
    'User',
    'OrderProgramRotation',
    'timestamp',
    'sortId',
    'tieBreakerId',
    'tieBreaker',
    'tiebreaker',
  ];
  generalProgramBundlesHide = ['images', 'deletedAt', 'metadata', 'coupons'];

  hostApplicationHide = ['User', 'Program', 'physicianemail', 'physician', 'referredhostpaid', 'trellourl'];

  // /**
  //  *
  //  * @param entryData object with field and header data
  //  * @param user management profile
  //  * @param gridType format of grid
  //  */
  //   defaultGridColumns(entryData: any, user: any, gridType: string) {
  //     // make one function that is the combination of all grid when I have more time
  //   }

  /**
   * @function defineTextColumn
   *
   * @param field
   * @param headerName
   * @returns TextColumn Interface
   *
   * @description Define the column properties for a type text column
   */
  defineTextColumn(field: string, headerName: string): TextColumn {
    return {
      headerName,
      field,
      enablePivot: true,
      enableRowGroup: true,
      filter: 'agTextColumnFilter',
      colId: field,
      sortable: true,
      filterParams: {
        filterOptions: [
          'contains',
          'startsWith',
          'endsWith',
          'equals',
          {
            displayKey: 'blanks',
            displayName: 'Empty Values',
            test: function (filterValue, cellValue) {
              return cellValue == undefined;
            },
            hideFilterInput: true,
            values: [null],
          },
        ],
        debounceMs: 2000,
        trimInput: true,
      },
    } as TextColumn;
  }

  /**
   * @function defineDayColumn
   *
   * @param _field
   * @param _headerName
   * @returns object
   */
  defineDayColumn(_field, _headerName) {
    return {
      headerName: _headerName,
      field: _field,
      enablePivot: true,
      enableRowGroup: true,
      valueFormatter: this.dayFormatter,
      filter: 'agTextColumnFilter',
      sortable: true,
    };
  }

  /**
   * @function defineNumberColumn
   *
   * @param _field
   * @param _headerName
   * @returns object
   *
   * @description Define the column properties for a type number column
   */
  defineNumberColumn(_field, _headerName) {
    return {
      headerName: _headerName,
      field: _field,
      // enablePivot: true,
      // enableRowGroup: true,
      filter: 'agNumberColumnFilter',
      sortable: true,
      filterParams: {
        debounceMs: 2000,
      },
    };
  }

  /**
   * @function defineDateColumn
   *
   * @param _field
   * @param _headerName
   * @returns object
   *
   * @description Define the column properties for a type date column
   */
  defineDateColumn(_field: string, _headerName: string, birthday?: boolean) {
    return {
      headerName: _headerName,
      field: _field,
      filter: 'agDateColumnFilter',
      sortable: true,
      enablePivot: true,
      enableRowGroup: true,
      valueFormatter: birthday ? this.birthdayDateFormatter : this.dateFormatter,
      filterParams: {
        filterOptions: [
          {
            displayKey: 'equals',
            displayName: 'On',
            predicate: this.dateOn,
          },
          {
            displayKey: 'lessThan',
            displayName: 'Before',
            predicate: this.dateLessThan,
          },
          {
            displayKey: 'greaterThan',
            displayName: 'After',
            predicate: this.dateGreaterThan,
          },
          {
            displayKey: 'inRange',
            displayName: 'Between',
            numberOfInputs: 2,
            predicate: ([fv1, fv2], cellValue) => {
              const startOfDay = moment.utc(fv1).startOf('day');
              const endOfDay = moment.utc(fv2).endOf('day');
              return new Date(cellValue) == null || (startOfDay <= moment.utc(cellValue) && endOfDay >= moment.utc(cellValue));
            },
          },
        ],
        inRangeInclusive: true,
        suppressAndOrCondition: true,
        buttons: ['reset', 'apply'],
        closeOnApply: true,
        comparator: this.dateFilter,
      },
    };
  }

  monthColumnFilter(_field: string, _headerName: string) {
    return {
      headerName: _headerName,
      field: _field,
      menuTabs: ['filterMenuTab'],
      filter: 'agSetColumnFilter',
      filterParams: {
        debounceMs: 3000,
        suppressSorting: true,
        values: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      },
    };
  }

  applicationStatusColumnFilter(_field: string, _headerName: string) {
    return {
      headerName: _headerName,
      field: _field,
      menuTabs: ['filterMenuTab'],
      filter: 'agSetColumnFilter',
      filterParams: {
        debounceMs: 3000,
        suppressSorting: true,
        values: [
          'Pending',
          'Pending-Booked',
          'Accepted',
          'Reserved',
          'Enrolled',
          'Completed',
          'Postponed',
          'Switched',
          'Cancelled',
          'Transferred',
          'Refunded',
          'Hold',
          'Enrolled-Pending',
        ],
      },
    };
  }

  usStatesColumnFilter(_field: string, _headerName: string) {
    return {
      headerName: _headerName,
      field: _field,
      menuTabs: ['filterMenuTab'],
      filter: 'agSetColumnFilter',
      filterParams: {
        debounceMs: 3000,
        suppressSorting: true,
        values: [
          'Alabama',
          'Alaska',
          'American Samoa',
          'Arizona',
          'Arkansas',
          'California',
          'Colorado',
          'Connecticut',
          'Delaware',
          'District of Columbia',
          'Federated States of Micronesia',
          'Florida',
          'Georgia',
          'Guam',
          'Hawaii',
          'Idaho',
          'Illinois',
          'Indiana',
          'Iowa',
          'Kansas',
          'Kentucky',
          'Louisiana',
          'Maine',
          'Marshall Islands',
          'Maryland',
          'Massachusetts',
          'Michigan',
          'Minnesota',
          'Mississippi',
          'Missouri',
          'Montana',
          'Nebraska',
          'Nevada',
          'New Hampshire',
          'New Jersey',
          'New Mexico',
          'New York',
          'North Carolina',
          'North Dakota',
          'Northern Mariana Islands',
          'Ohio',
          'Oklahoma',
          'Oregon',
          'Palau',
          'Pennsylvania',
          'Puerto Rico',
          'Rhode Island',
          'South Carolina',
          'South Dakota',
          'Tennessee',
          'Texas',
          'Utah',
          'Vermont',
          'Virgin Island',
          'Virginia',
          'Washington',
          'West Virginia',
          'Wisconsin',
          'Wyoming',
        ],
      },
    };
  }

  applicationYearFilterColumnFilter(_field: string, _headerName: string) {
    const futureYear = addYears(new Date(), 4).getFullYear();
    const yearList = rangeOfYears(2016, futureYear);

    return {
      headerName: _headerName,
      field: _field,
      menuTabs: ['filterMenuTab'],
      filter: 'agSetColumnFilter',
      filterParams: {
        debounceMs: 3000,
        suppressSorting: true,
        values: yearList,
      },
    };
  }

  booleanColumnFilter(_field: string, _headerName: string) {
    return {
      headerName: _headerName,
      field: _field,
      menuTabs: ['filterMenuTab'],
      filter: 'agSetColumnFilter',
      filterParams: {
        debounceMs: 2000,
        suppressSorting: true,
        values: ['True', 'False'],
      },
    };
  }

  paymentStatusColumnFilter(_field: string, _headerName: string) {
    return {
      headerName: _headerName,
      field: _field,
      menuTabs: ['filterMenuTab'],
      filter: 'agSetColumnFilter',
      filterParams: {
        debounceMs: 2000,
        suppressSorting: true,
        values: ['Pending', 'Complete'],
      },
    };
  }

  academicColumnFilter(_field: string, _headerName: string) {
    return {
      headerName: _headerName,
      field: _field,
      menuTabs: ['filterMenuTab'],
      filter: 'agSetColumnFilter',
      filterParams: {
        debounceMs: 2000,
        suppressSorting: true,
        values: ['Student', 'Graduate', 'Pre-Med'],
      },
    };
  }

  genderColumnFilter(_field: string, _headerName: string) {
    return {
      headerName: _headerName,
      field: _field,
      menuTabs: ['filterMenuTab'],
      filter: 'agSetColumnFilter',
      filterParams: {
        debounceMs: 3000,
        suppressSorting: true,
        values: ['Female', 'Male', 'Non-Binary'],
      },
    };
  }

  orderStatusColumnFilter(_field: string, _headerName: string) {
    return {
      headerName: _headerName,
      field: _field,
      menuTabs: ['filterMenuTab'],
      filter: 'agSetColumnFilter',
      filterParams: {
        debounceMs: 2000,
        suppressSorting: true,
        values: ['Order Received', 'Order in Progress', 'Requirements in Progress', 'Complete', 'Needs Recruitment', 'Cancelled'],
      },
    };
  }

  orderCellClass(params) {
    if (params.value === 'Requirements in Progress') {
      return 'yellow-text';
    } else if (params.value === 'Complete') {
      return 'green-text';
    } else if (params.value === 'Cancelled') {
      return 'red-text';
    } else {
      return 'black-text';
    }
  }

  orderProgramStatusColumnFilter(_field: string, _headerName: string) {
    return {
      headerName: _headerName,
      field: _field,
      menuTabs: ['filterMenuTab'],
      filter: 'agSetColumnFilter',
      filterParams: {
        debounceMs: 2000,
        suppressSorting: true,
        values: [
          'Request in Progress',
          'Hold',
          'Reserved',
          'Received Full Payment',
          'Complete',
          'Credit Available',
          'No Credit',
          'Request Seats',
          'Unfulfilled',
        ],
      },
    };
  }

  orderProgramCellClass(params) {
    if (params.value === 'Request in Progress') {
      return 'yellow-text';
    } else if (params.value === 'Hold') {
      return 'green-text';
    } else if (params.value === 'Unfulfilled') {
      return 'red-text';
    } else {
      return 'black-text';
    }
  }

  payoutGrid = (): any[] => {
    return [
      {
        headerName: 'Trainee Name',
        field: 'User.name',
      },
      {
        headerName: 'Stipend Paid',
        field: 'hostpaid',
      },
      {
        headerName: 'Stripe Stipend ID',
        field: 'stripetransfers',
      },
      {
        headerName: 'Application Program Type',
        field: 'type',
      },
      {
        headerName: 'Application Status',
        field: 'states',
      },
      {
        headerName: 'Start Date',
        field: 'startdate',
      },
      {
        headerName: 'End Date',
        field: 'enddate',
      },
      {
        headerName: '',
        resizable: false,
        minWidth: 47,
        maxWidth: 47,
        pinned: 'left',
        editable: false,
        suppressMenu: true,
        filter: null,
        hide: false,
        suppressColumnsToolPanel: true,
        colId: 'link',
        sortable: false,
        suppressMovable: true,
        lockPinned: true,
        lockVisible: true,
        cellRendererParams: { page: 'application' },
        cellRenderer: GridSelectionRenderer,
      },
    ];
  };

  nestedCouponGrid = (): any[] => {
    return [
      {
        headerName: 'ID',
        field: 'id',
      },
      {
        headerName: 'Code',
        field: 'code',
      },
      {
        headerName: 'Type',
        field: 'type',
      },
      {
        headerName: 'Discount',
        field: 'discount',
      },
      {
        headerName: '',
        resizable: false,
        minWidth: 47,
        maxWidth: 47,
        pinned: 'left',
        editable: false,
        suppressMenu: true,
        filter: null,
        hide: false,
        suppressColumnsToolPanel: true,
        colId: 'link',
        sortable: false,
        suppressMovable: true,
        lockPinned: true,
        lockVisible: true,
        cellRendererParams: { page: 'coupons' },
        cellRenderer: GridSelectionRenderer,
      },
    ];
  };

  programBundleProgramsGrid = (): any[] => {
    return [
      {
        headerName: 'Program ID',
        field: 'id',
      },
      {
        headerName: 'Program Name',
        field: 'name',
      },
      {
        headerName: 'Live',
        field: 'live',
      },
      {
        headerName: 'Featured',
        field: 'featured',
      },
      {
        headerName: 'Program Name',
        field: 'name',
      },
      {
        headerName: 'Rotation Type',
        field: 'type',
      },
      {
        headerName: 'Program Tags',
        field: 'programtags',
      },
      {
        headerName: 'Permitted Trainees',
        field: 'permitted',
      },
      {
        headerName: 'Host ID',
        field: 'hostid',
      },
      {
        headerName: 'Last Minute Placement',
        field: 'lastminute',
      },
      {
        headerName: 'Stipend Cost',
        field: 'stipendcost',
      },
      {
        headerName: 'Price',
        field: 'price',
      },
      {
        headerName: 'Deposit',
        field: 'deposit',
      },
      {
        headerName: 'Internal Tags',
        field: 'itnernaltags',
      },
      {
        headerName: 'Require Pre Approval',
        field: 'preapprovalrequired',
      },
      {
        headerName: 'Deadline Days',
        field: 'deadlinedays',
      },
      {
        headerName: 'Exposure Type',
        field: 'exposuretype',
      },
      {
        headerName: 'Default Student Coordinator',
        field: 'coach',
      },
      {
        headerName: 'Default Program Coordinator',
        field: 'coordinator',
      },
      {
        headerName: 'Inpatient Exposure',
        field: 'Inpatient Exposure',
      },
      {
        headerName: 'Specialty',
        field: 'specialty',
      },
      {
        headerName: 'Specialties',
        field: 'specialties',
      },
      {
        headerName: 'Program City',
        field: 'city',
      },
      {
        headerName: 'Program State',
        field: 'state',
      },
      {
        headerName: 'Program Location',
        field: 'programlocation',
      },
      {
        ...this.defineTextColumn('ProgramHostInstitution.account', 'Host Institution'),
        valueGetter: params => {
          if (params.data && params.data.Host && params.data.Host.Department && params.data.Host.Department.account) {
            return params.data.Host.Department.account;
          } else {
            return '';
          }
        },
      },
      {
        ...this.defineTextColumn('ProgramHost.name', 'Host Name'),
        valueGetter: params => (params.data && params.data.Host ? params.data.Host.name : ''),
      },
      {
        ...this.defineTextColumn('ProgramHost.phone', 'Host Phone'),
        valueGetter: params => (params.data && params.data.Host ? params.data.Host.phone : ''),
      },
      {
        ...this.defineTextColumn('ProgramHost.cellphone', 'Host Mobile Phone'),
        valueGetter: params => (params.data && params.data.Host ? params.data.Host.cellphone : ''),
      },
      {
        ...this.defineTextColumn('ProgramHost.clinicphone', 'Host Clinic Phone'),
        valueGetter: params => (params.data && params.data.Host ? params.data.Host.clinicphone : ''),
      },
      {
        ...this.defineTextColumn('ProgramHost.clinicphoneext', 'Host Clinic Phone Ext'),
        valueGetter: params => (params.data && params.data.Host ? params.data.Host.clinicphoneext : ''),
      },
      {
        ...this.defineTextColumn('ProgramHost.email', 'Host Email'),
        valueGetter: params => (params.data && params.data.Host ? params.data.Host.email : ''),
      },
      {
        ...this.defineTextColumn('ProgramHost.contactphone', 'Host Admin Phone'),
        valueGetter: params => (params.data && params.data.Host.contactphone ? params.data.Host.contactphone : ''),
      },
      {
        ...this.defineTextColumn('ProgramHost.contactphoneext', 'Host Admin Phone Ext'),
        valueGetter: params => (params.data && params.data.Host.contactphoneext ? params.data.Host.contactphoneext : ''),
      },
      {
        ...this.defineTextColumn('ProgramHost.contactemail', 'Host Admin Email'),
        valueGetter: params => (params.data && params.data.Host ? params.data.Host.contactemail : ''),
      },
      {
        ...this.defineTextColumn('ProgramHost.contactname', 'Host Admin Name'),
        valueGetter: params => (params.data && params.data.Host.contactname ? params.data.Host.contactname : ''),
      },
      {
        headerName: '',
        resizable: false,
        minWidth: 47,
        maxWidth: 47,
        pinned: 'left',
        checkboxSelection: true,
        editable: false,
        suppressMenu: true,
        filter: null,
        suppressColumnsToolPanel: true,
        colId: 'selection',
        sortable: false,
        suppressMovable: true,
        lockPinned: true,
        lockVisible: true,
      },
      {
        headerName: '',
        resizable: false,
        minWidth: 47,
        maxWidth: 47,
        pinned: 'left',
        editable: false,
        suppressMenu: true,
        filter: null,
        hide: false,
        suppressColumnsToolPanel: true,
        colId: 'link',
        sortable: false,
        suppressMovable: true,
        lockPinned: true,
        lockVisible: true,
        cellRendererParams: { page: 'program', user: { type: 'AMOEMPLOYEE' } },
        cellRenderer: GridSelectionRenderer,
      },
    ];
  };

  esTraineeGrid = (data: any, user: any, selection): any[] => {
    const columns = data
      .map((col: any) => {
        // *** Columns that will vary to show by user type, will be filtered by API ***
        if (col === 'hubspotcontactid') {
          return { ...this.defineNumberColumn('hubspotcontactid', 'HubSpot Contact ID') };
        }
        if (col === 'optout') {
          return { ...this.booleanColumnFilter('optout', 'Opt Out') };
        }
        if (col === 'requestcount') {
          return { ...this.defineTextColumn('requestcount', 'Request Count') };
        }
        if (col === 'utm_signup_source') {
          return { ...this.defineTextColumn('utm_signup_source', 'UTM Signup Source') };
        }
        if (col === 'profile_block_completed') {
          return { ...this.booleanColumnFilter('profile_block_completed', 'Completed Profile Block') };
        }
        if (col === 'profile_block_completed_created_date') {
          return { ...this.defineDateColumn('profile_block_completed_created_date', 'Completed Profile Block Date') };
        }
        if (col === 'subscribed') {
          return { ...this.booleanColumnFilter('subscribed', 'Subscribed') };
        }
        if (col === 'notifications') {
          return { ...this.booleanColumnFilter('notifications', 'Notifications') };
        }
      })
      .filter((col: any) => {
        if (['sortId', 'tieBreakerId', 'pgm_geoloc', 'timestamp', undefined].includes(col)) {
          return false;
        } else {
          return true;
        }
      });

    columns.push(
      // *** Default Columns ***
      {
        headerName: '',
        resizable: false,
        minWidth: 47,
        maxWidth: 47,
        pinned: 'left',
        checkboxSelection: true,
        editable: false,
        suppressMenu: true,
        filter: null,
        hide: true,
        suppressColumnsToolPanel: true,
        colId: 'selection',
        sortable: false,
        suppressMovable: true,
        lockPinned: true,
        lockVisible: true,
      },
      {
        headerName: '',
        resizable: false,
        minWidth: 47,
        maxWidth: 47,
        pinned: 'left',
        editable: false,
        suppressMenu: true,
        filter: null,
        hide: false,
        suppressColumnsToolPanel: true,
        colId: 'link',
        sortable: false,
        suppressMovable: true,
        lockPinned: true,
        lockVisible: true,
        cellRendererParams: { page: 'trainee' },
        cellRenderer: GridSelectionRenderer,
      },
      { ...this.defineTextColumn('id', 'Trainee ID') },
      { ...this.academicColumnFilter('academicstatus', 'Academic Status') },
      { ...this.defineTextColumn('name', 'Trainee Name') },
      { ...this.defineTextColumn('email', 'Trainee Email') },
      { ...this.genderColumnFilter('gender', 'Trainee Gender') },
      { ...this.defineDateColumn('birthday', 'Trainee Birthday', true) },
      { ...this.defineTextColumn('country', 'Trainee Country') },
      {
        ...this.defineTextColumn('countryofbirth', 'Trainee Country of Birth'),
      },
      { ...this.booleanColumnFilter('activated', 'Activated') },
      { ...this.booleanColumnFilter('boardexam1passed', 'Board Exam 1 Passed') },
      { ...this.booleanColumnFilter('hasvisa', 'Trainee Has Visa') },
      {
        ...this.defineTextColumn('passportcountry', 'Trainee Passport Country'),
      },
      {
        ...this.defineTextColumn('preferredspecialty', 'Preferred Specialty'),
        valueGetter: params => {
          if (params.data && params.data.preferredspecialty) {
            let parsedPref;
            try {
              parsedPref = params.data.preferredspecialty;

              if (parsedPref && typeof parsedPref === 'string') {
                parsedPref = JSON.parse(parsedPref);
              }
              if (parsedPref.length > 0) {
                let datalist = parsedPref.map(a => {
                  return a.text;
                });
                const dataString = datalist.join(', ');
                return dataString;
              } else {
                return '';
              }
            } catch (e) {
              return '';
            }
          }
        },
      },
      {
        ...this.defineTextColumn('preferredlocation', 'Preferred Location'),
        valueGetter: params => {
          if (params.data && params.data.preferredlocation) {
            let parsedPref;
            try {
              parsedPref = params.data.preferredlocation;
              if (parsedPref && typeof parsedPref === 'string') {
                parsedPref = JSON.parse(parsedPref);
              }
              if (parsedPref.length > 0) {
                let datalist = parsedPref.map(a => {
                  return a.text;
                });
                const dataString = datalist.join(', ');
                return dataString;
              } else {
                return '';
              }
            } catch (e) {
              return '';
            }
          }
        },
      },
      {
        ...this.defineTextColumn('preferredmonth', 'Preferred Month'),
        valueGetter: params => {
          if (params.data && params.data.preferredmonth) {
            let parsedPref = params.data.preferredmonth;
            try {
              if (parsedPref && typeof parsedPref === 'string') {
                parsedPref = JSON.parse(parsedPref);
              }
              if (parsedPref.length > 0) {
                let datalist = parsedPref.map(a => {
                  return a.text;
                });
                const dataString = datalist.join(', ');

                return dataString;
              } else {
                return '';
              }
            } catch (e) {
              return '';
            }
          }
        },
      },
      { ...this.defineTextColumn('fieldofstudy', 'Trainee Field of Study') },
      {
        ...this.defineTextColumn('Department.account', 'Institution Name'),
        valueGetter: params => {
          if (params.data && params.data && params.data.Department && params.data.Department.account) {
            return params.data.Department.account;
          } else {
            return '';
          }
        },
      },
      { ...this.defineTextColumn('departmentid', 'Trainee Institution ID') },
      { ...this.defineTextColumn('acquisition', 'Acquisition') },
      {
        ...this.defineTextColumn('university', 'University'),
        valueGetter: params => {
          if (params.data && params.data.university) {
            let parsedPref;
            try {
              parsedPref = params.data.university;
              if (parsedPref && typeof parsedPref === 'string') {
                parsedPref = JSON.parse(parsedPref);
              }
              if (parsedPref.length > 0) {
                let datalist = parsedPref.map(a => {
                  if (a.custom) {
                    return a.custom;
                  } else {
                    return a.schoolname;
                  }
                });
                const dataString = datalist.join(', ');

                return dataString;
              } else {
                return '';
              }
            } catch (e) {
              return '';
            }
          }
        },
      },
      { ...this.defineTextColumn('yearsattended', 'Years Attended') },
      { ...this.defineDateColumn('cvuploadat', 'Cv Upload At') },
      { ...this.defineTextColumn('phone', 'Trainee Phone') },
      { ...this.defineDateColumn('createddate', 'Trainee Created Date') },
      { ...this.defineDateColumn('updateddate', 'Trainee Updated Date') },
    );
    return columns;
  };

  // TODO - remove when transitioning VR/Hosting Grid over to elasticsearch
  visitorsGrid = (): any[] => {
    return [
      {
        headerName: '',
        resizable: false,
        minWidth: 47,
        maxWidth: 47,
        pinned: 'left',
        checkboxSelection: true,
        editable: false,
        suppressMenu: true,
        filter: null,
        hide: true,
        suppressColumnsToolPanel: true,
        colId: 'selection',
        sortable: false,
        suppressMovable: true,
        lockPinned: true,
        lockVisible: true,
      },
      {
        headerName: '',
        resizable: false,
        minWidth: 47,
        maxWidth: 47,
        pinned: 'left',
        editable: false,
        suppressMenu: true,
        filter: null,
        hide: false,
        suppressColumnsToolPanel: true,
        colId: 'link',
        sortable: false,
        suppressMovable: true,
        lockPinned: true,
        lockVisible: true,
        cellRendererParams: { page: 'trainee' },
        cellRenderer: GridSelectionRenderer,
      },
      { ...this.defineTextColumn('id', 'Trainee ID') },
      { ...this.academicColumnFilter('academicstatus', 'Academic Status') },
      { ...this.defineTextColumn('name', 'Trainee Name') },
      { ...this.defineTextColumn('email', 'Trainee Email') },
      { ...this.genderColumnFilter('gender', 'Trainee Gender') },
      { ...this.defineDateColumn('birthday', 'Trainee Birthday', true) },
      { ...this.defineTextColumn('country', 'Trainee Country') },
      {
        ...this.defineTextColumn('countryofbirth', 'Trainee Country of Birth'),
      },
      { ...this.booleanColumnFilter('activated', 'Activated') },
      { ...this.defineTextColumn('usmle', 'Board Exam 1 Passed') },
      { ...this.booleanColumnFilter('hasvisa', 'Trainee Has Visa') },
      {
        ...this.defineTextColumn('passportcountry', 'Trainee Passport Country'),
      },
      { ...this.booleanColumnFilter('profile_block_completed', 'Completed Profile Block') },
      { ...this.defineDateColumn('profile_block_completed_created_date', 'Completed Profile Block Date') },
      {
        ...this.defineTextColumn('prefspecialty', 'Preferred Specialty'),
        valueGetter: params => {
          if (params.data && params.data.prefspecialty) {
            let parsedPref;
            try {
              parsedPref = params.data.prefspecialty;

              if (parsedPref && typeof parsedPref === 'string') {
                parsedPref = JSON.parse(parsedPref);
              }
              if (parsedPref.length > 0) {
                let datalist = parsedPref.map(a => {
                  return a.text;
                });
                const dataString = datalist.join(', ');
                return dataString;
              } else {
                return '';
              }
            } catch (e) {
              return '';
            }
          }
        },
      },
      {
        ...this.defineTextColumn('preflocation', 'Preferred Location'),
        valueGetter: params => {
          if (params.data && params.data.preflocation) {
            let parsedPref;
            try {
              parsedPref = params.data.preflocation;
              if (parsedPref && typeof parsedPref === 'string') {
                parsedPref = JSON.parse(parsedPref);
              }
              if (parsedPref.length > 0) {
                let datalist = parsedPref.map(a => {
                  return a.text;
                });
                const dataString = datalist.join(', ');
                return dataString;
              } else {
                return '';
              }
            } catch (e) {
              return '';
            }
          }
        },
      },
      {
        ...this.defineTextColumn('prefmonth', 'Preferred Month'),
        valueGetter: params => {
          if (params.data && params.data.prefmonth) {
            let parsedPref = params.data.prefmonth;
            try {
              if (parsedPref && typeof parsedPref === 'string') {
                parsedPref = JSON.parse(parsedPref);
              }
              if (parsedPref.length > 0) {
                let datalist = parsedPref.map(a => {
                  return a.text;
                });
                const dataString = datalist.join(', ');

                return dataString;
              } else {
                return '';
              }
            } catch (e) {
              return '';
            }
          }
        },
      },
      // { ...this.defineTextColumn("fieldofstudy", "Trainee Field of Study") },
      // {
      //   ...this.defineTextColumn('fieldofstudy', 'Trainee Field of Study'),
      //   valueGetter: params => {
      //     if (params.data && params.data && params.data.fieldofstudy) {
      //       return params.data.fieldofstudy;
      //     } else {
      //       return '';
      //     }
      //   },
      // },
      {
        ...this.defineTextColumn('Department.account', 'Institution Name'),
        valueGetter: params => {
          if (params.data && params.data && params.data.Department && params.data.Department.account) {
            return params.data.Department.account;
          } else {
            return '';
          }
        },
      },
      { ...this.defineTextColumn('departmentid', 'Trainee Institution ID') },
      { ...this.defineTextColumn('acquisition', 'Acquisition') },
      {
        ...this.defineTextColumn('university', 'University'),
        valueGetter: params => {
          if (params.data && params.data.university) {
            let parsedPref;
            try {
              parsedPref = params.data.university;
              if (parsedPref && typeof parsedPref === 'string') {
                parsedPref = JSON.parse(parsedPref);
              }
              if (parsedPref.length > 0) {
                let datalist = parsedPref.map(a => {
                  if (a.custom) {
                    return a.custom;
                  } else {
                    return a.schoolname;
                  }
                });
                const dataString = datalist.join(', ');

                return dataString;
              } else {
                return '';
              }
            } catch (e) {
              return '';
            }
          }
        },
      },
      { ...this.defineTextColumn('yearsattended', 'Years Attended') },
      { ...this.defineDateColumn('cvuploadat', 'Cv Upload At') },
      { ...this.booleanColumnFilter('subscribed', 'Subscribed') },
      { ...this.booleanColumnFilter('notifications', 'Notifications') },
      { ...this.defineTextColumn('phone', 'Trainee Phone') },
      { ...this.booleanColumnFilter('optout', 'Opt Out') },
      { ...this.defineTextColumn('requestcount', 'Request Count') },
      { ...this.defineTextColumn('utm_signup_count', 'UTM Signup Count') },
      { ...this.defineTextColumn('hubspotcontactid', 'HubSpot Contact ID') },
      { ...this.defineDateColumn('createdAt', 'Trainee Created Date') },
      { ...this.defineDateColumn('updatedAt', 'Trainee Updated Date') },
    ];
  };

  couponsGrid = (): any[] => {
    return [
      {
        headerName: '',
        resizable: false,
        minWidth: 47,
        maxWidth: 47,
        pinned: 'left',
        checkboxSelection: true,
        editable: false,
        suppressMenu: true,
        filter: null,
        hide: true,
        suppressColumnsToolPanel: true,
        colId: 'selection',
        sortable: false,
        suppressMovable: true,
        lockPinned: true,
        lockVisible: true,
      },
      {
        headerName: '',
        resizable: false,
        minWidth: 47,
        maxWidth: 47,
        pinned: 'left',
        editable: false,
        suppressMenu: true,
        filter: null,
        hide: false,
        suppressColumnsToolPanel: true,
        colId: 'link',
        sortable: false,
        suppressMovable: true,
        lockPinned: true,
        lockVisible: true,
        cellRendererParams: { page: 'coupons' },
        cellRenderer: GridSelectionRenderer,
      },
      { ...this.defineTextColumn('id', 'Coupon ID') },
      { ...this.defineTextColumn('type', 'Type') },
      { ...this.defineTextColumn('code', 'Code') },
      { ...this.defineTextColumn('userid', 'Trainee ID') },
      { ...this.defineTextColumn('programid', 'Program ID') },
      { ...this.defineTextColumn('programtypes', 'Program Types') },
      { ...this.defineNumberColumn('discount', 'Discount') },
      { ...this.defineTextColumn('limit', 'Limit') },
      { ...this.defineTextColumn('notes', 'Notes') },
      { ...this.defineTextColumn('usedby', 'Usedby') },
      { ...this.defineDateColumn('expires', 'Expires') },
      { ...this.defineTextColumn('departmentid', 'Institution ID') },
      { ...this.defineTextColumn('inviteid', 'Invite ID') },
      { ...this.defineDateColumn('createdAt', 'Created Date') },
      { ...this.defineDateColumn('updatedAt', 'Updated Date') },
    ];
  };

  // Visitor and Hosting Application List
  applicationsGrid = (objectkey, user: any): Observable<any> => {
    let columns = objectkey
      .filter((col: any) => {
        if (col === 'visastatus') {
          return false;
        } else if (user.rules && user.rules.permissions.includes('program:hideprices') && this.priceHide.indexOf(col) !== -1) {
          return false;
        } else {
          return true;
        }
      })
      .map((data: any) => {
        if (data === 'states') {
          return {
            headerName: 'Application Status',
            field: data,
            filter: true,
            comparator: this.customComparator,
            cellClass: this.statusStyleChange,
          };
        }

        if (GridFields.application.visitorAndHosing[data]) {
          return this.defineTextColumn(data, GridFields.application.visitorAndHosing[data].header);
        }
        if (data === 'startdate') {
          if (this.dateColumns.indexOf(data) !== -1) {
            let settingsSort;
            if (user.settings && user.settings.ui && user.settings.ui.sortedDate) {
              if (isNil(user.settings.ui.sortedDate)) {
                settingsSort = 'desc';
              } else if (user.settings.ui.sortedDate !== 'startdate') {
                settingsSort = null;
              } else {
                settingsSort = 'desc';
              }
            } else {
              settingsSort = 'desc';
            }
            return {
              headerName: 'Start Date',
              field: data,
              filter: 'agDateColumnFilter',
              sort: settingsSort,
              sortable: true,
              valueFormatter: this.dateFormatter,
              filterParams: {
                filterOptions: [
                  {
                    displayKey: 'equals',
                    displayName: 'On',
                    predicate: this.dateOn,
                  },
                  {
                    displayKey: 'lessThanOrEqual',
                    displayName: 'Before',
                    predicate: this.dateLessThan,
                  },
                  {
                    displayKey: 'greaterThanOrEqual',
                    displayName: 'After',
                    predicate: this.dateGreaterThan,
                  },
                  {
                    displayKey: 'inRange',
                    displayName: 'Between',
                    predicate: this.dateRange,
                  },
                ],
                inRangeInclusive: true,
                suppressAndOrCondition: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
                // provide comparator function
                comparator: this.dateFilter,
              },
            };
          }
        }
        if (data === 'createdAt') {
          if (this.dateColumns.indexOf(data) !== -1) {
            let settingsSort;
            if (user.settings && user.settings.ui && user.settings.ui.sortedDate === 'createddate') {
              settingsSort = user.settings.ui.sortedDate === 'createddate' ? 'desc' : '';
            }
            return {
              headerName: 'Created Date',
              field: data,
              filter: 'agDateColumnFilter',
              valueFormatter: this.dateFormatter,
              sort: settingsSort,
              comparator: this.customComparator,
              sortable: true,
              filterParams: {
                filterOptions: [
                  {
                    displayKey: 'equals',
                    displayName: 'On',
                    predicate: this.dateOn,
                  },
                  {
                    displayKey: 'lessThanOrEqual',
                    displayName: 'Before',
                    predicate: this.dateLessThan,
                  },
                  {
                    displayKey: 'greaterThanOrEqual',
                    displayName: 'After',
                    predicate: this.dateGreaterThan,
                  },
                  {
                    displayKey: 'inRange',
                    displayName: 'Between',
                    predicate: this.dateRange,
                  },
                ],
                inRangeInclusive: true,
                suppressAndOrCondition: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
                // provide comparator function
                comparator: this.dateFilter,
              },
            };
          }
        }
        if (data === 'enddate') {
          if (this.dateColumns.indexOf(data) !== -1) {
            return {
              headerName: 'End Date',
              field: data,
              filter: 'agDateColumnFilter',
              valueFormatter: this.dateFormatter,
              sortable: true,
              filterParams: {
                filterOptions: [
                  {
                    displayKey: 'equals',
                    displayName: 'On',
                    predicate: this.dateOn,
                  },
                  {
                    displayKey: 'lessThanOrEqual',
                    displayName: 'Before',
                    predicate: this.dateLessThan,
                  },
                  {
                    displayKey: 'greaterThanOrEqual',
                    displayName: 'After',
                    predicate: this.dateGreaterThan,
                  },
                  {
                    displayKey: 'inRange',
                    displayName: 'Between',
                    predicate: this.dateRange,
                  },
                ],
                inRangeInclusive: true,
                suppressAndOrCondition: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
                // provide comparator function
                comparator: this.dateFilter,
              },
            };
          }
        }
        if (data === 'accepteddate') {
          if (this.dateColumns.indexOf(data) !== -1) {
            let settingsSort;
            if (user.settings && user.settings.ui && user.settings.ui.sortedDate === 'accepteddate') {
              settingsSort = user.settings.ui.sortedDate === 'accepteddate' ? 'desc' : null;
            } else {
              settingsSort = null;
            }
            return {
              headerName: 'Accepted Date',
              field: data,
              filter: 'agDateColumnFilter',
              sortable: true,
              sort: settingsSort,
              valueFormatter: this.dateFormatter,
              filterParams: {
                filterOptions: [
                  {
                    displayKey: 'equals',
                    displayName: 'On',
                    predicate: this.dateOn,
                  },
                  {
                    displayKey: 'lessThanOrEqual',
                    displayName: 'Before',
                    predicate: this.dateLessThan,
                  },
                  {
                    displayKey: 'greaterThanOrEqual',
                    displayName: 'After',
                    predicate: this.dateGreaterThan,
                  },
                  {
                    displayKey: 'inRange',
                    displayName: 'Between',
                    predicate: this.dateRange,
                  },
                ],
                inRangeInclusive: true,
                suppressAndOrCondition: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
                // provide comparator function
                comparator: this.dateFilter,
              },
            };
          }
        }
        if (data === 'expires') {
          if (this.dateColumns.indexOf(data) !== -1) {
            return {
              headerName: 'Expire Date',
              field: data,
              filter: 'agDateColumnFilter',
              sortable: true,
              valueFormatter: this.dateFormatter,
              filterParams: {
                filterOptions: [
                  {
                    displayKey: 'equals',
                    displayName: 'On',
                    predicate: this.dateOn,
                  },
                  {
                    displayKey: 'lessThanOrEqual',
                    displayName: 'Before',
                    predicate: this.dateLessThan,
                  },
                  {
                    displayKey: 'greaterThanOrEqual',
                    displayName: 'After',
                    predicate: this.dateGreaterThan,
                  },
                  {
                    displayKey: 'inRange',
                    displayName: 'Between',
                    predicate: this.dateRange,
                  },
                ],
                inRangeInclusive: true,
                suppressAndOrCondition: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
                // provide comparator function
                comparator: this.dateFilter,
              },
            };
          }
        }
        if (data === 'reserveddate') {
          if (this.dateColumns.indexOf(data) !== -1) {
            return {
              headerName: 'Reserved Date',
              field: data,
              filter: 'agDateColumnFilter',
              sortable: true,
              valueFormatter: this.dateFormatter,
              filterParams: {
                filterOptions: [
                  {
                    displayKey: 'equals',
                    displayName: 'On',
                    predicate: this.dateOn,
                  },
                  {
                    displayKey: 'lessThanOrEqual',
                    displayName: 'Before',
                    predicate: this.dateLessThan,
                  },
                  {
                    displayKey: 'greaterThanOrEqual',
                    displayName: 'After',
                    predicate: this.dateGreaterThan,
                  },
                  {
                    displayKey: 'inRange',
                    displayName: 'Between',
                    predicate: this.dateRange,
                  },
                ],
                inRangeInclusive: true,
                suppressAndOrCondition: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
                // provide comparator function
                comparator: this.dateFilter,
              },
            };
          }
        }
        if (data === 'enrolleddate') {
          if (this.dateColumns.indexOf(data) !== -1) {
            return {
              headerName: 'Enrolled Date',
              field: data,
              filter: 'agDateColumnFilter',
              sortable: true,
              valueFormatter: this.dateFormatter,
              filterParams: {
                filterOptions: [
                  {
                    displayKey: 'equals',
                    displayName: 'On',
                    predicate: this.dateOn,
                  },
                  {
                    displayKey: 'lessThanOrEqual',
                    displayName: 'Before',
                    predicate: this.dateLessThan,
                  },
                  {
                    displayKey: 'greaterThanOrEqual',
                    displayName: 'After',
                    predicate: this.dateGreaterThan,
                  },
                  {
                    displayKey: 'inRange',
                    displayName: 'Between',
                    predicate: this.dateRange,
                  },
                ],
                inRangeInclusive: true,
                suppressAndOrCondition: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
                // provide comparator function
                comparator: this.dateFilter,
              },
            };
          }
        }
        if (data === 'signedcontract') {
          if (this.dateColumns.indexOf(data) !== -1) {
            return {
              headerName: 'Signed Contract Date',
              field: data,
              filter: 'agDateColumnFilter',
              sortable: true,
              valueFormatter: this.dateFormatter,
              filterParams: {
                filterOptions: [
                  {
                    displayKey: 'equals',
                    displayName: 'On',
                    predicate: this.dateOn,
                  },
                  {
                    displayKey: 'lessThanOrEqual',
                    displayName: 'Before',
                    predicate: this.dateLessThan,
                  },
                  {
                    displayKey: 'greaterThanOrEqual',
                    displayName: 'After',
                    predicate: this.dateGreaterThan,
                  },
                  {
                    displayKey: 'inRange',
                    displayName: 'Between',
                    predicate: this.dateRange,
                  },
                ],
                inRangeInclusive: true,
                suppressAndOrCondition: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
                // provide comparator function
                comparator: this.dateFilter,
              },
            };
          }
        }
        if (data === 'updatedAt') {
          if (this.dateColumns.indexOf(data) !== -1) {
            let settingsSort;
            if (user.settings && user.settings.ui && user.settings.ui.sortedDate === 'updatedate') {
              settingsSort = user.settings.ui.sortedDate === 'updatedate' ? 'desc' : '';
            }
            return {
              headerName: 'Updated Date',
              field: data,
              sortable: true,
              sort: settingsSort,
              filter: 'agDateColumnFilter',
              valueFormatter: this.dateFormatter,
              filterParams: {
                filterOptions: [
                  {
                    displayKey: 'equals',
                    displayName: 'On',
                    predicate: this.dateOn,
                  },
                  {
                    displayKey: 'lessThanOrEqual',
                    displayName: 'Before',
                    predicate: this.dateLessThan,
                  },
                  {
                    displayKey: 'greaterThanOrEqual',
                    displayName: 'After',
                    predicate: this.dateGreaterThan,
                  },
                  {
                    displayKey: 'inRange',
                    displayName: 'Between',
                    predicate: this.dateRange,
                  },
                ],
                inRangeInclusive: true,
                suppressAndOrCondition: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
                // provide comparator function
                comparator: this.dateFilter,
              },
            };
          }
        }
        if (data === 'migrationdocumentdate') {
          if (this.dateColumns.indexOf(data) !== -1) {
            let settingsSort;
            if (user.settings && user.settings.ui && user.settings.ui.sortedDate === 'migrationdocumentdate') {
              settingsSort = user.settings.ui.sortedDate === 'updatedate' ? 'desc' : '';
            }
            return {
              headerName: 'Migration Document Date',
              field: data,
              sortable: true,
              sort: settingsSort,
              filter: 'agDateColumnFilter',
              valueFormatter: this.dateFormatter,
              filterParams: {
                filterOptions: [
                  {
                    displayKey: 'equals',
                    displayName: 'On',
                    predicate: this.dateOn,
                  },
                  {
                    displayKey: 'lessThanOrEqual',
                    displayName: 'Before',
                    predicate: this.dateLessThan,
                  },
                  {
                    displayKey: 'greaterThanOrEqual',
                    displayName: 'After',
                    predicate: this.dateGreaterThan,
                  },
                  {
                    displayKey: 'inRange',
                    displayName: 'Between',
                    predicate: this.dateRange,
                  },
                ],
                inRangeInclusive: true,
                suppressAndOrCondition: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
                // provide comparator function
                comparator: this.dateFilter,
              },
            };
          }
        }
      });
    //
    columns.push(
      { ...this.defineTextColumn('User.country', 'Trainee Country') },
      {
        headerName: '',
        resizable: false,
        minWidth: 47,
        maxWidth: 47,
        pinned: 'left',
        editable: false,
        suppressMenu: true,
        sortable: false,
        suppressMovable: true,
        lockPinned: true,
        lockVisible: true,
        filter: null,
        suppressColumnsToolPanel: true,
        colId: 'selection',
        cellRendererParams: { page: 'application', user },
        cellRenderer: GridSelectionRenderer,
      },
    );

    const filteredColumns = columns.filter(function (x) {
      return x !== undefined;
    });

    return of(filteredColumns);
  };

  hostingProgramApplicantGrid = (user: any): any[] => {
    return [
      {
        ...this.defineTextColumn('User.name', 'Trainee Name'),
      },
      {
        ...this.defineTextColumn('states', 'Status'),
        cellClass: this.statusStyleChange,
      },
      {
        ...this.defineTextColumn('type', 'Type'),
      },
      {
        ...this.defineTextColumn('User.email', 'Trainee Email'),
      },
      {
        ...this.defineTextColumn('month', 'Month'),
      },
      {
        ...this.defineTextColumn('startyear', 'Start Year'),
      },
      {
        headerName: 'Start Date',
        field: 'startdate',
        filter: 'agDateColumnFilter',
        enablePivot: true,
        enableRowGroup: true,
        sortable: true,
        valueFormatter: this.dateFormatter,
        filterParams: {
          filterOptions: [
            {
              displayKey: 'equals',
              displayName: 'On',
              predicate: this.dateOn,
            },
            {
              displayKey: 'lessThanOrEqual',
              displayName: 'Before',
              predicate: this.dateLessThan,
            },
            {
              displayKey: 'greaterThanOrEqual',
              displayName: 'After',
              predicate: this.dateGreaterThan,
            },
            {
              displayKey: 'inRange',
              displayName: 'Between',
              predicate: this.dateRange,
            },
          ],
          inRangeInclusive: true,
          suppressAndOrCondition: true,
          buttons: ['reset', 'apply'],
          closeOnApply: true,
          // provide comparator function
          comparator: this.dateFilter,
        },
      },
      {
        headerName: 'End Date',
        field: 'startdate',
        filter: 'agDateColumnFilter',
        sortable: true,
        enablePivot: true,
        enableRowGroup: true,
        valueFormatter: this.dateFormatter,
        filterParams: {
          filterOptions: [
            {
              displayKey: 'equals',
              displayName: 'On',
              predicate: this.dateOn,
            },
            {
              displayKey: 'lessThanOrEqual',
              displayName: 'Before',
              predicate: this.dateLessThan,
            },
            {
              displayKey: 'greaterThanOrEqual',
              displayName: 'After',
              predicate: this.dateGreaterThan,
            },
            {
              displayKey: 'inRange',
              displayName: 'Between',
              predicate: this.dateRange,
            },
          ],
          inRangeInclusive: true,
          suppressAndOrCondition: true,
          buttons: ['reset', 'apply'],
          closeOnApply: true,
          // provide comparator function
          comparator: this.dateFilter,
        },
      },
      {
        headerName: 'Expires',
        field: 'expires',
        sortable: true,
        filter: 'agDateColumnFilter',
        valueFormatter: this.dateFormatter,
        enablePivot: true,
        enableRowGroup: true,
        filterParams: {
          filterOptions: [
            {
              displayKey: 'equals',
              displayName: 'On',
              predicate: this.dateOn,
            },
            {
              displayKey: 'lessThanOrEqual',
              displayName: 'Before',
              predicate: this.dateLessThan,
            },
            {
              displayKey: 'greaterThanOrEqual',
              displayName: 'After',
              predicate: this.dateGreaterThan,
            },
            {
              displayKey: 'inRange',
              displayName: 'Between',
              predicate: this.dateRange,
            },
          ],
          inRangeInclusive: true,
          suppressAndOrCondition: true,
          buttons: ['reset', 'apply'],
          closeOnApply: true,
          // provide comparator function
          comparator: this.dateFilter,
        },
      },
      {
        headerName: 'Program Location',
        field: 'Program.programlocation',
        filter: 'agTextColumnFilter',
        enablePivot: true,
        enableRowGroup: true,
        sortable: true,
      },
      {
        headerName: '',
        resizable: false,
        minWidth: 47,
        maxWidth: 47,
        pinned: 'left',
        editable: false,
        suppressMenu: true,
        hide: false,
        filter: null,
        suppressColumnsToolPanel: true,
        colId: 'selection',
        sortable: false,
        suppressMovable: true,
        lockPinned: true,
        lockVisible: true,
        cellRendererParams: {
          page: user.type !== 'AMOEMPLOYEE' ? 'application' : 'amoApplication',
          user,
        },
        cellRenderer: GridSelectionRenderer,
      },
    ];
  };

  // *****************************************************************************************************
  // ********************   NEW GRID SERVICE FUNCTIONS WITH OBJECT PARAMS   ******************************
  // *****************************************************************************************************

  // Programs Page
  programGridColumns(dataObj: any, user: any, selectionMode?: boolean) {
    const columns = dataObj
      .filter((col: any) => {
        if (this.generalProgramHide.indexOf(col) !== -1) {
          return false;
        } else if (user.rules && user.rules.permissions.includes('program:hideprices') && this.priceHide.indexOf(col) !== -1) {
          return false;
        } else if (user.type === 'Visitor-Recruitment' && this.vrProgramHide.indexOf(col) !== -1) {
          return false;
        } else if (user.type !== 'AMOEMPLOYEE' && this.notEmployeeProgramHide.indexOf(col) !== -1) {
          // hide for all who are not amoemployee, overrides rules.owner permission
          return false;
        } else {
          return true;
        }
      })
      .map((p: any) => {
        if (p === 'Host') {
          return {
            ...this.defineTextColumn('ProgramHostInstitution.account', 'Host Institution'),
            valueGetter: params => {
              if (params.data && params.data.Host && params.data.Host.Department && params.data.Host.Department.account) {
                return params.data.Host.Department.account;
              } else {
                return '';
              }
            },
          };
        }
        if (p === 'name') {
          return {
            ...this.defineTextColumn(p, 'Program Name'),
            minWidth: 300,
          };
        }
        // if (p === "enddate") {
        //   return this.defineDateColumn(p, "End Date");
        // }
        if (p === 'livedate') {
          return this.defineDateColumn(p, 'Turned Live Date');
        }
        if (p === 'createdAt') {
          return this.defineDateColumn(p, 'Program Created Date');
        }
        if (p === 'updatedAt') {
          return this.defineDateColumn(p, 'Program Updated Date');
        }
        if (p === 'closeddate') {
          return this.defineDateColumn(p, 'Program Closed Date');
        }
        if (p === 'auditdate') {
          return this.defineDateColumn(p, 'Program Audit Date');
        }
        if (p === 'pricedropstartdate') {
          return this.defineDateColumn(p, 'Price Drop Start Date');
        }
        if (p === 'pricedropenddate') {
          return this.defineDateColumn(p, 'Price Drop End Date');
        }
        if (this.dateColumns.includes(p)) {
          return this.defineDateColumn(p, p);
        }
        if (p === 'id') {
          return this.defineTextColumn(p, 'Program ID');
        }
        if (p === 'city') {
          return this.defineTextColumn(p, 'Program Primary City');
        }
        if (p === 'state') {
          return this.defineTextColumn(p, 'Program Primary State');
        }
        if (p === 'programlocation') {
          return this.defineTextColumn(p, 'Program Primary Site Name');
        }
        if (p === 'geoloc') {
          return this.defineTextColumn(p, 'Coordinates for Map');
        }
        if (p === 'description') {
          return this.defineTextColumn(p, 'Program Description');
        }
        if (p === 'hubspotproductid') {
          return this.defineTextColumn(p, 'Program Hubspot Product ID');
        }
        if (p === 'hubspotdealid') {
          return this.defineTextColumn(p, 'Program Hubspot Deal ID');
        }
        if (p === 'hourlyprice') {
          return this.defineTextColumn(p, 'Program Hourly Price');
        }
        if (p === 'weekly_hours') {
          return this.defineTextColumn(p, 'Program Weekly Hours');
        }
        // if (p === "affiliatedsites") {
        //   return this.defineTextColumn(p, "Program Affiliated Sites");
        // }
        if (p === 'affiliatedsites') {
          return {
            ...this.defineTextColumn('affiliatedsites', 'Program Affiliated Sites'),
            valueGetter: params => {
              if (params.data && params.data.affiliatedsites && params.data.affiliatedsites.length > 0) {
                try {
                  return JSON.parse(params.data.affiliatedsites)[0].name || '';
                } catch (ex) {
                  console.log(ex);
                  return '';
                }
              } else {
                return '';
              }
            },
          };
        }
        if (p === 'schedule') {
          return this.defineTextColumn(p, 'Weekly Schedule');
        }
        if (p === 'address') {
          return this.defineTextColumn(p, 'Program Primary Site Address');
        }
        if (p === 'postalcode') {
          return this.defineTextColumn(p, 'Program Primary Postal Code');
        }
        if (p === 'months') {
          return this.defineTextColumn(p, 'Default Months Available');
        }
        if (p === 'filled') {
          return this.defineTextColumn(p, 'Block Filled');
        }
        if (p === 'offered') {
          return this.defineTextColumn(p, 'Slots Per Block');
        }
        if (p === 'unavailable') {
          return this.defineTextColumn(p, 'Month, Year Unavailable');
        }
        if (p === 'live') {
          return this.defineTextColumn(p, 'Program Live');
        }
        if (p === 'type') {
          return this.defineTextColumn(p, 'Program Rotation Type(s)');
        }
        if (p === 'documents') {
          return this.defineTextColumn(p, 'Documents');
        }
        if (p === 'printabledocuments') {
          return this.defineTextColumn(p, 'Printable Documents');
        }
        if (p === 'specialty') {
          return this.defineTextColumn(p, 'Program Primary Specialty');
        }
        if (p === 'specialties') {
          return {
            ...this.defineTextColumn('ManagerAssociationMapProgram.' + p, 'Program Additional Specialties'),
            valueGetter: params => {
              if (params.data && params.data.specialties) {
                return params.data.specialties
                  .split(',')
                  .filter(entry => entry.trim() != '')
                  .sort()
                  .join(',');
              } else {
                return '';
              }
            },
          };
        }
        if (p === 'featured') {
          return this.defineTextColumn(p, 'Featured');
        }
        if (p === 'programtags') {
          return this.defineTextColumn(p, 'Program Displayed Tags');
        }
        if (p === 'notes') {
          if (user.type === 'AMOEMPLOYEE') {
            return this.defineTextColumn(p, 'Program Notes');
          }
        }
        if (p === 'activities') {
          return this.defineTextColumn(p, 'Activities');
        }
        if (p === 'permitted') {
          return this.defineTextColumn(p, 'Permitted Trainee Types');
        }
        if (p === 'hostid') {
          return this.defineTextColumn(p, 'Host ID');
        }
        if (p === 'lastminute') {
          return this.defineTextColumn(p, 'Available Last Minute');
        }
        if (p === 'stipendcost') {
          return this.defineTextColumn(p, 'Stipend Amount');
        }
        if (p === 'pricedropamount') {
          return this.defineNumberColumn(p, 'Price Drop Amount');
        }
        if (p === 'pricechange') {
          return this.defineNumberColumn(p, 'Late Fee Amount');
        }
        if (p === 'price') {
          return this.defineNumberColumn(p, 'Program Price');
        }
        if (p === 'reportingtime') {
          return this.defineTextColumn(p, 'Reporting Information');
        }
        if (p === 'additionalrequirements') {
          return this.defineTextColumn(p, 'Additional Requirements');
        }
        if (p === 'internaltags') {
          if (user.type === 'AMOEMPLOYEE') {
            return this.defineTextColumn(p, 'Program Internal Tags');
          }
        }
        if (p === 'inpatientexposure') {
          return this.defineTextColumn(p, 'Inpatient Exposure');
        }
        if (p === 'inpatientexposurenotes') {
          return this.defineTextColumn(p, 'Inpatient Exposure Notes');
        }
        if (p === 'faxnumber') {
          return this.defineTextColumn(p, 'Program Fax');
        }
        if (p === 'preapprovalrequired') {
          return this.defineTextColumn(p, 'Pre-Approval Required');
        }
        if (p === 'website') {
          return this.defineTextColumn(p, 'Program Website');
        }
        if (p === 'exposuretype') {
          return this.defineTextColumn(p, 'Program Exposure Type');
        }
        if (p === 'deadlinedays') {
          return this.defineNumberColumn(p, 'Program Deadline Days');
        }
        if (p === 'hidden') {
          return this.defineTextColumn(p, 'Program Hidden');
        }
        if (p === 'customavailability') {
          return this.defineTextColumn(p, 'Custom Availability Availability');
        }
        if (p === 'preapprovalnotes') {
          return this.defineTextColumn(p, 'Program Pre-Approval Notes');
        }
        if (p === 'content') {
          return this.defineTextColumn(p, 'Structured Content');
        }
        if (p === 'coach') {
          return this.defineTextColumn(p, 'Default Student Coordinator');
        }
        if (p === 'coordinator') {
          return this.defineTextColumn(p, 'Default Program Coordinator');
        }
        if (p === 'deposit') {
          // return this.defineTextColumn(p, "Deposit");
          return this.defineNumberColumn(p, 'Program Deposit Amount');
        }
        if (p === 'exposuretype') {
          return this.defineTextColumn(p, 'Program Exposure Type');
        }
        if (p === 'programinternalname') {
          return this.defineTextColumn(p, 'Program Internal Name');
        }

        return this.defineTextColumn(p, p);
      })
      .map((last: any) => {
        if (user.type === 'Hosting' && this.hostProgramColumns.indexOf(last.field) === -1) {
          last.hide = true;
          return last;
        }
        return last;
      });

    if (['AMOEMPLOYEE', 'Visitor-Recruitment'].includes(user.type)) {
      columns.push({
        ...this.defineTextColumn('ProgramHost.boardcertifications', 'Host Board Certifications'),
        valueGetter: params => (params.data && params.data.Host ? amoJsonToString(params.data.Host.boardcertifications) : ''),
      });
    }

    if (user.type !== 'Visitor-Recruitment') {
      columns.push(
        {
          ...this.defineTextColumn('ProgramHost.name', 'Host Name'),
          valueGetter: params => (params.data && params.data.Host ? params.data.Host.name : ''),
        },
        {
          headerName: 'Host Institution ID',
          field: 'ProgramHostInstitution.id',
          valueGetter: params => {
            if (params.data && params.data.Host && params.data.Host.Department && params.data.Host.Department.id) {
              return params.data.Host.Department.id;
            } else {
              return '';
            }
          },
          filter: 'agTextColumnFilter',
          sortable: true,
        },
        {
          ...this.defineTextColumn('ProgramHost.phone', 'Host Phone'),
          valueGetter: params => (params.data && params.data.Host ? params.data.Host.phone : ''),
        },
        {
          ...this.defineTextColumn('ProgramHost.cellphone', 'Host Mobile Phone'),
          valueGetter: params => (params.data && params.data.Host ? params.data.Host.cellphone : ''),
        },
        {
          ...this.defineTextColumn('ProgramHost.clinicphone', 'Host Clinic Phone'),
          valueGetter: params => (params.data && params.data.Host ? params.data.Host.clinicphone : ''),
        },
        {
          ...this.defineTextColumn('ProgramHost.clinicphoneext', 'Host Clinic Phone Ext'),
          valueGetter: params => (params.data && params.data.Host ? params.data.Host.clinicphoneext : ''),
        },
        {
          ...this.defineTextColumn('ProgramHost.email', 'Host Email'),
          valueGetter: params => (params.data && params.data.Host ? params.data.Host.email : ''),
        },
        {
          ...this.defineTextColumn('ProgramHost.contactphone', 'Host Admin Phone'),
          valueGetter: params => (params.data && params.data.Host.contactphone ? params.data.Host.contactphone : ''),
        },
        {
          ...this.defineTextColumn('ProgramHost.contactphoneext', 'Host Admin Phone Ext'),
          valueGetter: params => (params.data && params.data.Host.contactphoneext ? params.data.Host.contactphoneext : ''),
        },
        {
          ...this.defineTextColumn('ProgramHost.contactemail', 'Host Admin Email'),
          valueGetter: params => (params.data && params.data.Host ? params.data.Host.contactemail : ''),
        },
        {
          ...this.defineTextColumn('ProgramHost.contactname', 'Host Admin Name'),
          valueGetter: params => (params.data && params.data.Host.contactname ? params.data.Host.contactname : ''),
        },
        {
          ...this.defineNumberColumn('ProgramHost.offered', 'Host Slots Per Block'),
          valueGetter: params => (params.data && params.data.Host.offered ? params.data.Host.offered : ''),
        },
        {
          ...this.defineTextColumn('ProgramHost.usehostavailability', 'Enable Host Capacity'),
          valueGetter: params => {
            //params.data && params.data.Host.usehostavailability ? params.data.Host.usehostavailability : false),
            if (params.data && params.data.Host.usehostavailability) {
              return params.data.Host.usehostavailability;
            } else {
              return false;
            }
          },
        },
      );
    }

    if (selectionMode) {
      columns.push({
        headerName: '',
        resizable: false,
        minWidth: 47,
        maxWidth: 47,
        pinned: 'left',
        checkboxSelection: true,
        editable: false,
        suppressMenu: true,
        filter: null,
        hide: false,
        suppressColumnsToolPanel: true,
        colId: 'collection',
        sortable: false,
        suppressMovable: true,
        lockPinned: true,
        lockVisible: true,
      });
    }

    columns.push({
      headerName: '',
      resizable: false,
      minWidth: 47,
      maxWidth: 47,
      pinned: 'left',
      editable: false,
      suppressMenu: true,
      hide: false,
      filter: null,
      suppressColumnsToolPanel: true,
      colId: 'selection',
      sortable: false,
      suppressMovable: true,
      lockPinned: true,
      lockVisible: true,
      cellRendererParams: { page: 'program', user },
      cellRenderer: GridSelectionRenderer,
    });

    return columns;
  }

  // Programs Page
  programGridColumnsAssociatedHost(dataObj: any, user: any) {
    const columns = dataObj
      .filter((col: any) => {
        if (this.generalProgramHide.indexOf(col) !== -1) {
          return false;
        } else if (this.hostingManagerHide.indexOf(col) !== -1) {
          return false;
        } else if (user.rules && user.rules.permissions.includes('program:hideprices') && this.priceHide.indexOf(col) !== -1) {
          return false;
        } else if (user.type === 'Visitor-Recruitment' && this.vrProgramHide.indexOf(col) !== -1) {
          return false;
        } else {
          return true;
        }
      })
      .map((p: any) => {
        if (p === 'Host') {
          return {
            ...this.defineTextColumn('ProgramHostInstitution.account', 'Host Institution'),
            valueGetter: params => {
              if (params.data && params.data.Host && params.data.Host.Department && params.data.Host.Department.account) {
                return params.data.Host.Department.account;
              } else {
                return '';
              }
            },
          };
        }
        if (p === 'name') {
          return {
            headerName: 'Program Name',
            field: 'ManagerAssociationMapProgram.' + p,
            filter: 'agTextColumnFilter',
            minWidth: 300,
            valueGetter: params => (params.data && params.data.name ? params.data.name : ''),
          };
        }
        // if (p === "enddate") {
        //   return this.defineDateColumn(p, "End Date");
        // }
        if (p === 'livedate') {
          return {
            ...this.defineDateColumn('ManagerAssociationMapProgram.' + p, 'Turned Live Date'),
            valueGetter: params => (params.data && params.data.livedate ? params.data.livedate : ''),
          };
        }
        if (p === 'createdAt') {
          return {
            ...this.defineDateColumn('ManagerAssociationMapProgram.' + p, 'Program Created Date'),
            valueGetter: params => (params.data && params.data.createdAt ? params.data.createdAt : ''),
          };
        }
        if (p === 'updatedAt') {
          return {
            ...this.defineDateColumn('ManagerAssociationMapProgram.' + p, 'Program Updated Date'),
            valueGetter: params => (params.data && params.data.updatedAt ? params.data.updatedAt : ''),
          };
        }
        if (p === 'auditdate') {
          return {
            ...this.defineDateColumn('ManagerAssociationMapProgram.' + p, 'Program Audit Date'),
            valueGetter: params => (params.data && params.data.auditdate ? params.data.auditdate : ''),
          };
        }
        if (p === 'pricedropstartdate') {
          return {
            ...this.defineDateColumn('ManagerAssociationMapProgram.' + p, 'Price Drop Start Date'),
            valueGetter: params => (params.data && params.data.pricedropstartdate ? params.data.pricedropstartdate : ''),
          };
        }
        if (p === 'pricedropenddate') {
          return {
            ...this.defineDateColumn('ManagerAssociationMapProgram.' + p, 'Price Drop End Date'),
            valueGetter: params => (params.data && params.data.pricedropenddate ? params.data.pricedropenddate : ''),
          };
        }
        if (this.dateColumns.includes(p)) {
          return this.defineDateColumn('ManagerAssociationMapProgram.' + p, p);
        }
        if (p === 'id') {
          return {
            ...this.defineTextColumn('ManagerAssociationMapProgram.' + p, 'Program ID'),
            valueGetter: params => (params.data && params.data.id ? params.data.id : ''),
          };
        }
        if (p === 'city') {
          return {
            ...this.defineTextColumn('ManagerAssociationMapProgram.' + p, 'Program Primary City'),
            valueGetter: params => (params.data && params.data.city ? params.data.city : ''),
          };
        }
        if (p === 'state') {
          return {
            ...this.defineTextColumn('ManagerAssociationMapProgram.' + p, 'Program Primary State'),
            valueGetter: params => (params.data && params.data.state ? params.data.state : ''),
          };
        }
        if (p === 'programlocation') {
          return {
            ...this.defineTextColumn('ManagerAssociationMapProgram.' + p, 'Program Primary Site Name'),
            valueGetter: params => (params.data && params.data.programlocation ? params.data.programlocation : ''),
          };
        }
        if (p === 'geoloc') {
          return {
            ...this.defineTextColumn('ManagerAssociationMapProgram.' + p, 'Coordinates for Map'),
            valueGetter: params => (params.data && params.data.geoloc ? params.data.geoloc : ''),
          };
        }
        if (p === 'description') {
          return {
            ...this.defineTextColumn('ManagerAssociationMapProgram.' + p, 'Program Description'),
            valueGetter: params => (params.data && params.data.description ? params.data.description : ''),
          };
        }
        if (p === 'affiliatedsites') {
          return {
            ...this.defineTextColumn('ManagerAssociationMapProgram.' + p, 'Program Affiliated Sites'),
            valueGetter: params => (params.data && params.data.affiliatedsites ? params.data.affiliatedsites : ''),
          };
        }
        if (p === 'schedule') {
          return {
            ...this.defineTextColumn('ManagerAssociationMapProgram.' + p, 'Weekly Schedule'),
            valueGetter: params => (params.data && params.data.schedule ? params.data.schedule : ''),
          };
        }
        if (p === 'address') {
          return {
            ...this.defineTextColumn('ManagerAssociationMapProgram.' + p, 'Program Primary Site Address'),
            valueGetter: params => (params.data && params.data.address ? params.data.address : ''),
          };
        }
        if (p === 'postalcode') {
          return {
            ...this.defineTextColumn('ManagerAssociationMapProgram.' + p, 'Program Primary Postal Code'),
            valueGetter: params => (params.data && params.data.postalcode ? params.data.postalcode : ''),
          };
        }
        if (p === 'months') {
          return {
            ...this.defineTextColumn('ManagerAssociationMapProgram.' + p, 'Default Months Available'),
            valueGetter: params => (params.data && params.data.months ? params.data.months : ''),
          };
        }
        if (p === 'filled') {
          return {
            ...this.defineTextColumn('ManagerAssociationMapProgram.' + p, 'Block Filled'),
            valueGetter: params => (params.data && params.data.filled ? params.data.filled : ''),
          };
        }
        if (p === 'offered') {
          return {
            ...this.defineTextColumn('ManagerAssociationMapProgram.' + p, 'Slots Per Block'),
            valueGetter: params => (params.data && params.data.offered ? params.data.offered : ''),
          };
        }
        if (p === 'unavailable') {
          return {
            ...this.defineTextColumn('ManagerAssociationMapProgram.' + p, 'Month, Year Unavailable'),
            valueGetter: params => (params.data && params.data.unavailable ? params.data.unavailable : ''),
          };
        }
        if (p === 'live') {
          return {
            ...this.defineTextColumn('ManagerAssociationMapProgram.' + p, 'Program Live'),
            valueGetter: params => (params.data && params.data.live ? params.data.live : ''),
          };
        }
        if (p === 'type') {
          return {
            ...this.defineTextColumn('ManagerAssociationMapProgram.' + p, 'Program Rotation Type(s)'),
            valueGetter: params => (params.data && params.data.type ? params.data.type : ''),
          };
        }
        if (p === 'specialty') {
          return {
            ...this.defineTextColumn('ManagerAssociationMapProgram.' + p, 'Program Primary Specialty'),
            valueGetter: params => (params.data && params.data.specialty ? params.data.specialty : ''),
          };
        }
        if (p === 'specialties') {
          return {
            ...this.defineTextColumn('ManagerAssociationMapProgram.' + p, 'Program Additional Specialties'),
            valueGetter: params => (params.data && params.data.specialties ? params.data.specialties : ''),
          };
        }
        if (p === 'featured') {
          return {
            ...this.defineTextColumn('ManagerAssociationMapProgram.' + p, 'Featured'),
            valueGetter: params => (params.data && params.data.featured ? params.data.featured : ''),
          };
        }
        if (p === 'programtags') {
          return {
            ...this.defineTextColumn('ManagerAssociationMapProgram.' + p, 'Program Displayed Tags'),
            valueGetter: params => (params.data && params.data.programtags ? params.data.programtags : ''),
          };
        }
        if (p === 'notes') {
          if (user.type === 'AMOEMPLOYEE') {
            return {
              ...this.defineTextColumn(p, 'Program Notes'),
              valueGetter: params => (params.data && params.data.programtags ? params.data.programtags : ''),
            };
          }
        }
        if (p === 'activities') {
          return {
            ...this.defineTextColumn('ManagerAssociationMapProgram.' + p, 'Activities'),
            valueGetter: params => (params.data && params.data.activities ? params.data.activities : ''),
          };
        }
        if (p === 'permitted') {
          return {
            ...this.defineTextColumn('Program.' + p, 'Permitted Trainee Types'),
            valueGetter: params => (params.data && params.data.permitted ? params.data.permitted : ''),
          };
        }
        if (p === 'hostid') {
          return {
            ...this.defineTextColumn('ManagerAssociationMapProgram.' + p, 'Host ID'),
            valueGetter: params => (params.data && params.data.hostid ? params.data.hostid : ''),
          };
        }
        if (p === 'lastminute') {
          return {
            ...this.defineTextColumn('ManagerAssociationMapProgram.' + p, 'Available Last Minute'),
            valueGetter: params => (params.data && params.data.lastminute ? params.data.lastminute : ''),
          };
        }
        if (p === 'pricedropamount') {
          return {
            ...this.defineNumberColumn('ManagerAssociationMapProgram.' + p, 'Price Drop Amount'),
            valueGetter: params => (params.data && params.data.pricedropamount ? params.data.pricedropamount : ''),
          };
        }
        if (p === 'pricechange') {
          return {
            ...this.defineNumberColumn('ManagerAssociationMapProgram.' + p, 'Late Fee Amount'),
            valueGetter: params => (params.data && params.data.pricechange ? params.data.pricechange : ''),
          };
        }
        if (p === 'price') {
          return {
            ...this.defineNumberColumn('ManagerAssociationMapProgram.' + p, 'Program Price'),
            valueGetter: params => (params.data && params.data.price ? params.data.price : ''),
          };
        }
        if (p === 'reportingtime') {
          return {
            ...this.defineTextColumn('ManagerAssociationMapProgram.' + p, 'Reporting Information'),
            valueGetter: params => (params.data && params.data.reportingtime ? params.data.reportingtime : ''),
          };
        }
        if (p === 'internaltags') {
          if (user.type === 'AMOEMPLOYEE') {
            return this.defineTextColumn(p, 'Program Internal Tags');
          }
        }
        if (p === 'inpatientexposure') {
          return {
            ...this.defineTextColumn('ManagerAssociationMapProgram.' + p, 'Inpatient Exposure'),
            valueGetter: params => (params.data && params.data.inpatientexposure ? params.data.inpatientexposure : ''),
          };
        }
        if (p === 'inpatientexposurenotes') {
          return {
            ...this.defineTextColumn('ManagerAssociationMapProgram.' + p, 'Inpatient Exposure Notes'),
            valueGetter: params => (params.data && params.data.inpatientexposurenotes ? params.data.inpatientexposurenotes : ''),
          };
        }
        if (p === 'faxnumber') {
          return {
            ...this.defineTextColumn('ManagerAssociationMapProgram.' + p, 'Program Fax'),
            valueGetter: params => (params.data && params.data.faxnumber ? params.data.faxnumber : ''),
          };
        }
        if (p === 'preapprovalrequired') {
          return {
            ...this.defineTextColumn('ManagerAssociationMapProgram.' + p, 'Pre-Approval Required'),
            valueGetter: params => (params.data && params.data.preapprovalrequired ? params.data.preapprovalrequired : ''),
          };
        }
        if (p === 'website') {
          return {
            ...this.defineTextColumn('ManagerAssociationMapProgram.' + p, 'Program Website'),
            valueGetter: params => (params.data && params.data.website ? params.data.website : ''),
          };
        }
        if (p === 'exposuretype') {
          return {
            ...this.defineTextColumn('ManagerAssociationMapProgram.' + p, 'Program Exposure Type'),
            valueGetter: params => (params.data && params.data.exposuretype ? params.data.exposuretype : ''),
          };
        }
        if (p === 'deadlinedays') {
          return {
            ...this.defineTextColumn('ManagerAssociationMapProgram.' + p, 'Program Deadline Days'),
            valueGetter: params => (params.data && params.data.deadlinedays ? params.data.deadlinedays : ''),
          };
        }
        if (p === 'hidden') {
          return {
            ...this.defineTextColumn('ManagerAssociationMapProgram.' + p, 'Program Hidden'),
            valueGetter: params => (params.data && params.data.hidden ? params.data.hidden : ''),
          };
        }
        if (p === 'customavailability') {
          return {
            ...this.defineTextColumn('ManagerAssociationMapProgram.' + p, 'Custom Availability'),
            valueGetter: params => (params.data && params.data.customavailability ? params.data.customavailability : ''),
          };
        }
        if (p === 'preapprovalnotes') {
          return {
            ...this.defineTextColumn('ManagerAssociationMapProgram.' + p, 'Program Pre-Approval Notes'),
            valueGetter: params => (params.data && params.data.preapprovalnotes ? params.data.preapprovalnotes : ''),
          };
        }
        if (p === 'content') {
          return {
            ...this.defineTextColumn('ManagerAssociationMapProgram.' + p, 'Structured Content'),
            valueGetter: params => (params.data && params.data.content ? params.data.content : ''),
          };
        }
        if (p === 'coach') {
          return {
            ...this.defineTextColumn('ManagerAssociationMapProgram.' + p, 'Default Student Coordinator'),
            valueGetter: params => (params.data && params.data.coach ? params.data.coach : ''),
          };
        }
        if (p === 'coordinator') {
          return {
            ...this.defineTextColumn('ManagerAssociationMapProgram.' + p, 'Default Program Coordinator'),
            valueGetter: params => (params.data && params.data.coordinator ? params.data.coordinator : ''),
          };
        }
        if (p === 'deposit') {
          // return this.defineTextColumn(p, "Deposit");
          return {
            ...this.defineNumberColumn('ManagerAssociationMapProgram.' + p, 'Program Deposit Amount'),
            valueGetter: params => (params.data && params.data.deposit ? params.data.deposit : ''),
          };
        }
        if (p === 'exposuretype') {
          return {
            ...this.defineTextColumn('ManagerAssociationMapProgram.' + p, 'Program Exposure Type'),
            valueGetter: params => (params.data && params.data.exposuretype ? params.data.exposuretype : ''),
          };
        }

        return this.defineTextColumn('ManagerAssociationMapProgram.' + p, p);
      })
      .map((last: any) => {
        if (user.type === 'Hosting' && this.hostProgramColumns.includes(last.field)) {
          last.hide = true;
          return last;
        }
        return last;
      });

    if (['AMOEMPLOYEE', 'Visitor-Recruitment'].includes(user.type)) {
      columns.push({
        ...this.defineTextColumn('ProgramHost.boardcertifications', 'Host Board Certifications'),
        valueGetter: params => (params.data && params.data.Host ? amoJsonToString(params.data.Host.boardcertifications) : ''),
      });
    }
    // if (user.type !== "Visitor-Recruitment") {
    //   columns.push(
    //     {
    //       ...this.defineTextColumn("ManagerAssociationMapProgramHost.name", "Host Name"),
    //       valueGetter: params =>
    //         params.data && params.data.Host ? params.data.Host.name : ""
    //     },
    //     {
    //       ...this.defineTextColumn("ProgramHost.phone", "Host Phone"),
    //       valueGetter: params =>
    //         params.data && params.data.Host ? params.data.Host.phone : ""
    //     },
    //     {
    //       ...this.defineTextColumn(
    //         "ProgramHost.cellphone",
    //         "Host Mobile Phone"
    //       ),
    //       valueGetter: params =>
    //         params.data && params.data.Host ? params.data.Host.cellphone : ""
    //     },
    //     {
    //       ...this.defineTextColumn(
    //         "ProgramHost.clinicphone",
    //         "Host Clinic Phone"
    //       ),
    //       valueGetter: params =>
    //         params.data && params.data.Host ? params.data.Host.clinicphone : ""
    //     },
    //     {
    //       ...this.defineTextColumn(
    //         "ProgramHost.clinicphoneext",
    //         "Host Clinic Phone Ext"
    //       ),
    //       valueGetter: params =>
    //         params.data && params.data.Host
    //           ? params.data.Host.clinicphoneext
    //           : ""
    //     },
    //     {
    //       ...this.defineTextColumn("ProgramHost.email", "Host Email"),
    //       valueGetter: params =>
    //         params.data && params.data.Host ? params.data.Host.email : ""
    //     },
    //     {
    //       ...this.defineTextColumn(
    //         "ProgramHost.contactphone",
    //         "Host Admin Phone"
    //       ),
    //       valueGetter: params =>
    //         params.data && params.data.Host.contactphone
    //           ? params.data.Host.contactphone
    //           : ""
    //     },
    //     {
    //       ...this.defineTextColumn(
    //         "ProgramHost.contactphoneext",
    //         "Host Admin Phone Ext"
    //       ),
    //       valueGetter: params =>
    //         params.data && params.data.Host.contactphoneext
    //           ? params.data.Host.contactphoneext
    //           : ""
    //     },
    //     {
    //       ...this.defineTextColumn(
    //         "ProgramHost.contactemail",
    //         "Host Admin Email"
    //       ),
    //       valueGetter: params =>
    //         params.data && params.data.Host ? params.data.Host.contactemail : ""
    //     },
    //     {
    //       ...this.defineTextColumn(
    //         "ProgramHost.contactname",
    //         "Host Admin Name"
    //       ),
    //       valueGetter: params =>
    //         params.data && params.data.Host.contactname
    //           ? params.data.Host.contactname
    //           : ""
    //     }
    //   );
    // }

    columns.push({
      headerName: '',
      resizable: false,
      minWidth: 47,
      maxWidth: 47,
      pinned: 'left',
      editable: false,
      suppressMenu: true,
      hide: false,
      filter: null,
      suppressColumnsToolPanel: true,
      colId: 'selection',
      sortable: false,
      suppressMovable: true,
      lockPinned: true,
      lockVisible: true,
      cellRendererParams: { page: 'program', user },
      cellRenderer: GridSelectionRenderer,
    });
    return columns;
  }

  programAvailabilityGridColumns(dataObj: any, user: any, selectionMode?: boolean) {
    const columns = dataObj
      .filter((col: string) => {
        if (['sortId', 'tieBreakerId', 'pgm_geoloc', 'timestamp'].includes(col)) {
          return false;
        } else {
          return true;
        }
      })

      // .fiålter((col: any) => {
      //   if (this.generalProgramHide.indexOf(col) !== -1) {
      //     return false;
      //   } else if (user.rules && user.rules.permissions.includes('program:hideprices') && this.priceHide.indexOf(col) !== -1) {
      //     return false;
      //   } else if (user.type === 'Visitor-Recruitment' && this.vrProgramHide.indexOf(col) !== -1) {
      //     return false;
      //   } else {
      //     return true;
      //   }
      // })

      .map((data: any) => {
        if (data === 'pgm_id') {
          return this.defineNumberColumn(data, 'Program ID');
        }
        // if (data === 'pgm_avail_startdate') {
        //   console.log('timdata ', data);
        //   return this.defineDateColumn(data, 'Program Availability Start Date');
        // }
        // if (data === 'pgm_avail_enddate') {
        //   return this.defineDateColumn(data, 'Program Availability End Date');
        // }
        if (data === 'pgm_hubspotdealid') {
          return this.defineNumberColumn(data, 'Program HubSpot Deal ID');
        }
        if (data === 'pgm_name') {
          return this.defineTextColumn(data, 'Program Name');
        }
        if (data === 'pgm_hidden') {
          return this.booleanColumnFilter(data, 'Program Hidden');
        }
        if (data === 'pgm_type') {
          return this.defineTextColumn(data, 'Program Type');
        }
        if (data === 'pgm_coordinator') {
          return this.defineTextColumn(data, 'Program Coordinator');
        }
        if (data === 'pgm_city') {
          return this.defineTextColumn(data, 'Program City');
        }
        if (data === 'pgm_state') {
          return this.defineTextColumn(data, 'Program State');
        }
        if (data === 'pgm_postalcode') {
          return this.defineTextColumn(data, 'Program Postal Code');
        }
        if (data === 'pgm_specialty') {
          return this.defineTextColumn(data, 'Program Specialty');
        }
        if (data === 'pgm_specialties') {
          return this.defineTextColumn(data, 'Program Specialties');
        }
        if (data === 'pgm_tags') {
          return this.defineTextColumn(data, 'Program Tags');
        }
        if (data === 'pgm_internaltags') {
          return this.defineTextColumn(data, 'Program Internal Tags');
        }
        if (data === 'pgm_schedule') {
          return this.defineTextColumn(data, 'Program Schedule');
        }
        if (data === 'pgm_price') {
          return this.defineNumberColumn(data, 'Program Price');
        }
        if (data === 'pgm_pricechange') {
          return this.defineNumberColumn(data, 'Program Price Change');
        }
        if (data === 'pgm_deposit') {
          return this.defineNumberColumn(data, 'Program Deposit');
        }
        if (data === 'pgm_stipendcost') {
          return this.defineNumberColumn(data, 'Program Stipend Cost');
        }
        if (data === 'pgm_margin') {
          return this.defineNumberColumn(data, 'Program Margin');
        }
        if (data === 'pgm_created_at') {
          return this.defineDateColumn(data, 'Program Created Date');
        }
        if (data === 'pgm_updated_at') {
          return this.defineDateColumn(data, 'Program Updated Date');
        }
        if (data === 'pgm_live') {
          return this.booleanColumnFilter(data, 'Program Live');
        }
        if (data === 'pgm_live_date') {
          return this.defineDateColumn(data, 'Program Live Date');
        }
        if (data === 'pgm_customavailability') {
          return this.defineTextColumn(data, 'Program Custom Availability');
        }
        if (data === 'pgm_avg_rating') {
          return this.defineNumberColumn(data, 'Program Average Rating');
        }
        if (data === 'pgm_dept_id') {
          return this.defineTextColumn(data, 'Department ID');
        }
        if (data === 'pgm_dept_exclusive_market') {
          return this.booleanColumnFilter(data, 'Department Exclusive Market');
        }
        if (data === 'pgm_host_id') {
          return this.defineTextColumn(data, 'Host ID');
        }
        if (data === 'pgm_host_name') {
          return this.defineTextColumn(data, 'Host Name');
        }
        if (data === 'pgm_host_role') {
          return this.defineTextColumn(data, 'Host Role');
        }
        if (data === 'pgm_host_gender') {
          return this.defineTextColumn(data, 'Host Gender');
        }
        if (data === 'pgm_host_clinic_name') {
          return this.defineTextColumn(data, 'Host Clinic Name');
        }
        if (data === 'pgm_host_clinic_city') {
          return this.defineTextColumn(data, 'Host Clinic City');
        }
        if (data === 'pgm_host_clinic_state') {
          return this.defineTextColumn(data, 'Host Clinic State');
        }
        if (data === 'pgm_host_clinic_postal_code') {
          return this.defineTextColumn(data, 'Host Clinic Postal Code');
        }
        if (data === 'pgm_host_specialties') {
          return this.defineTextColumn(data, 'Host Board Certified Specialties');
        }
        if (data === 'pgm_host_subspecialties') {
          return this.defineTextColumn(data, 'Host Sub-specialties');
        }
        if (data === 'pgm_host_experience_types') {
          return this.defineTextColumn(data, 'Host Experience Types');
        }
        if (data === 'pgm_host_degree_type') {
          return this.defineTextColumn(data, 'Host Degree Type');
        }
        if (data === 'pgm_host_board_certifications') {
          return this.defineTextColumn(data, 'Host Certifying Board Name');
        }
        if (data === 'pgm_host_licensed_states') {
          return this.defineTextColumn(data, 'Host Licensed States');
        }
        if (data === 'pgm_host_license_expiration') {
          return this.defineDateColumn(data, 'Host License Expiration Date');
        }
        if (data === 'pgm_host_stipend_cost') {
          return this.defineNumberColumn(data, 'Host Stipend Cost');
        }
        if (data === 'pgm_host_slots_offered') {
          return this.defineNumberColumn(data, 'Host Slots Offered');
        }
        if (data === 'pgm_host_use_host_availability') {
          return this.booleanColumnFilter(data, 'Host Availability');
        }
        if (data === 'pgm_host_coordinator') {
          return this.defineTextColumn(data, 'Host Coordinator');
        }
        if (data === 'pgm_host_hubspot_contact_id') {
          return this.defineNumberColumn(data, 'Host HubSpot Contact ID');
        }
        if (data === 'pgm_host_created_at') {
          return this.defineDateColumn(data, 'Host Created At');
        }
        if (data === 'pgm_host_updated_at') {
          return this.defineDateColumn(data, 'Host Updated At');
        }
        if (data === 'pgm_host_npi') {
          return this.defineTextColumn(data, 'Host NPI');
        }
        if (data === 'pgm_avail_session') {
          return this.defineTextColumn(data, 'Program Availability Session');
        }
        if (data === 'pgm_avail_selection') {
          return this.defineTextColumn(data, 'Program Availability Selection');
        }
        // if (data === 'pgm_avail_offered') {
        //   return this.defineNumberColumn(data, 'Program Availability Offered');
        // }
        // if (data === 'pgm_avail_placed') {
        //   return this.defineNumberColumn(data, 'Program Availability Placed');
        // }
        // if (data === 'pgm_avail_placed_leading') {
        //   return this.defineNumberColumn(data, 'Leading Program Availability Placed');
        // }
        // if (data === 'pgm_avail_placed_lagging') {
        //   return this.defineNumberColumn(data, 'Lagging Program Availability Placed');
        // }
        // if (data === 'pgm_avail_available') {
        //   return this.defineNumberColumn(data, 'Program Availability Available');
        // }
        // if (data === 'pgm_avail_deadline_date') {
        //   return this.defineDateColumn(data, 'Program Availability Deadline Date');
        // }
        if (data === 'pgm_avail_price') {
          return this.defineNumberColumn(data, 'Program Availability Price');
        }
        if (data === 'pgm_deadline_days') {
          return this.defineNumberColumn(data, 'Program Deadline Days');
        }
        if (data === 'pgm_dept_account') {
          return this.defineTextColumn(data, 'Department Name');
        }
        if (data === 'pgm_dept_type') {
          return this.defineTextColumn(data, 'Department Type');
        }
        if (data === 'pgm_exposure_type') {
          return this.defineTextColumn(data, 'Program Exposure Type');
        }
        if (data === 'pgm_internal_tags') {
          return this.defineTextColumn(data, 'Program Internal Tags');
        }
        if (data === 'pgm_lastminute') {
          return this.booleanColumnFilter(data, 'Program Last Minute');
        }
        if (data === 'pgm_preapproval') {
          return this.booleanColumnFilter(data, 'Program Pre-Approval Required');
        }
        if (data === 'pgm_preapproval_notes') {
          return this.defineTextColumn(data, 'Program Pre-Approval Notes');
        }
        if (data === 'pgm_hours_per_week') {
          return this.defineNumberColumn(data, 'Program Hours Per Week');
        }
        if (data === 'pgm_avail_capacity') {
          return this.defineNumberColumn(data, 'Program Capacity');
        }
        if (data === 'overlapping_programs') {
          return this.defineTextColumn(data, 'Overlapping Programs');
        }
        if (data === 'pgm_permalink') {
          return this.defineTextColumn(data, 'Permalink');
        } else {
          return null;
        }
        // catch all for non-defined columns, do not need for now, don't want to show mapping explosion
        // return this.defineTextColumn(data, data);
      })
      .filter(col => col !== null);

    // push these avail columns to ensure they always show up
    // these are in vector database, other avail columns isolated
    columns.push(
      this.defineDateColumn('pgm_avail_startdate', 'Program Availability Start Date'),
      this.defineDateColumn('pgm_avail_enddate', 'Program Availability End Date'),
      this.defineDateColumn('pgm_avail_deadline_date', 'Program Availability Deadline Date'),
      this.defineNumberColumn('pgm_avail_offered', 'Program Availability Offered'),
      this.defineNumberColumn('pgm_avail_placed', 'Program Availability Placed'),
      this.defineNumberColumn('pgm_avail_available', 'Program Availability Available'),
      this.defineNumberColumn('pgm_avail_placed_leading', 'Leading Program Availability Placed'),
      this.defineNumberColumn('pgm_avail_placed_lagging', 'Lagging Program Availability Placed'),
    );

    columns.push({
      headerName: '',
      resizable: false,
      minWidth: 47,
      maxWidth: 47,
      pinned: 'left',
      checkboxSelection: true,
      editable: false,
      suppressMenu: true,
      filter: null,
      suppressColumnsToolPanel: true,
      colId: 'selection',
      sortable: false,
      suppressMovable: true,
      lockPinned: true,
      lockVisible: true,
      hide: true,
    });

    return columns;
  }

  // Program Bundles Page
  programBundlesGridColumns(dataObj: any, user: any) {
    const columns = dataObj
      .filter((col: any) => {
        if (this.generalProgramBundlesHide.indexOf(col) !== -1) {
          return false;
        } else if (user.rules && user.rules.permissions.includes('program:hideprices') && this.priceHide.indexOf(col) !== -1) {
          return false;
        } else if (user.type === 'Visitor-Recruitment' && this.vrProgramHide.indexOf(col) !== -1) {
          return false;
        } else {
          return true;
        }
      })
      .map((p: any) => {
        if (p === 'title') {
          return this.defineTextColumn(p, 'Bundle Title');
        }
        if (p === 'bundlediscount') {
          return {
            headerName: 'Bundle Discount',
            field: p,
            filter: 'agTextColumnFilter',
            sortable: true,
            valueGetter: params => {
              if (params.data && params.data.bundlediscount) {
                let valueString: string = '';
                params.data.bundlediscount.forEach(a => {
                  valueString = valueString.concat(`$${a.discount} for ${a.count},`);
                });
                return valueString;
              } else {
                return '';
              }
            },
          };
        }
        if (p === 'Programs') {
          return {
            headerName: 'Program Names',
            field: p,
            filter: 'agTextColumnFilter',
            sortable: true,
            valueGetter: params => {
              if (params.data && params.data.Programs) {
                let valueString: string = '';
                params.data.Programs.forEach(a => {
                  valueString = valueString.concat(`${a.name},`);
                });
                return valueString;
              } else {
                return '';
              }
            },
          };
        }
        if (p === 'createdAt') {
          return this.defineDateColumn(p, 'Bundle Created Date');
        }
        if (p === 'updatedAt') {
          return this.defineDateColumn(p, 'Bundle Updated Date');
        }
        if (p === 'pricedropstartdate') {
          return this.defineDateColumn(p, 'Bundle Price Drop Start Date');
        }
        if (p === 'pricedropenddate') {
          return this.defineDateColumn(p, 'Bundle Price Drop End Date');
        }
        if (this.dateColumns.includes(p)) {
          return this.defineDateColumn(p, p);
        }
        if (p === 'id') {
          return this.defineTextColumn(p, 'Bundle ID');
        }
        if (p === 'description') {
          return this.defineTextColumn(p, 'Bundle Description');
        }
        if (p === 'live') {
          return this.defineTextColumn(p, 'Bundle Live');
        }

        if (p === 'hidden') {
          return this.defineTextColumn(p, 'Bundle Hidden');
        }

        return this.defineTextColumn(p, p);
      })
      .map((last: any) => {
        if (user.type === 'Hosting' && this.hostProgramColumns.indexOf(last.field) === -1) {
          last.hide = true;
          return last;
        }
        return last;
      });

    columns.push({
      headerName: '',
      resizable: false,
      minWidth: 47,
      maxWidth: 47,
      pinned: 'left',
      editable: false,
      suppressMenu: true,
      hide: false,
      filter: null,
      suppressColumnsToolPanel: true,
      colId: 'selection',
      sortable: false,
      suppressMovable: true,
      lockPinned: true,
      lockVisible: true,
      cellRendererParams: { page: 'programbundles', user },
      cellRenderer: GridSelectionRenderer,
    });
    return of(columns);
  }

  departmentGridColumns(objectkey: any, user: any, selectionMode?: boolean) {
    let columns = objectkey
      .filter((col: any) => {
        if (col === 'stipendcost') {
          return false;
        }
        return col;
      })
      .map((data: any) => {
        if (data === 'stipendcost') {
          return this.defineNumberColumn(data, 'Stipend Cost');
        }
        if (data === 'slugurl') {
          //   if (params.data && params.data.invitecode) {
          //       return "https://app.amopportunities.org/signup?invite=" + params.data.invitecode
          //       } else {
          //         return "";
          //       }
          //   }
          return this.defineTextColumn(data, 'Institution Invite URL');
        }
        if (GridFields.department[data]) {
          return {
            headerName: GridFields.department[data].header,
            field: data,
            filter: 'agTextColumnFilter',
          };
        }
        if (data === 'createdAt') {
          return this.defineDateColumn(data, 'Instituion Created Date');
        }
        if (data === 'stripe_connect_status') {
          return {
            headerName: 'Stripe connect status',
            field: data,
            filter: 'agTextColumnFilter',
          };
        }

        if (data === 'stripeconnectid') {
          return {
            headerName: 'Stripe Connect ID',
            field: data,
            filter: 'agTextColumnFilter',
          };
        }
        if (data === 'updatedAt') {
          return this.defineDateColumn(data, 'Institution Updated Date');
        } else {
          return {
            ...this.defineTextColumn(data, data),
          };
        }
      });

    columns.push({
      ...this.defineTextColumn('inviteurl', 'Institution Invite URL'),
      valueGetter: params => {
        if (params.data && params.data.urlslug) {
          return (
            'https://app.amopportunities.org/signup?invite=' + params.data.urlslug //invitecode
          );
        } else {
          return '';
        }
      },
    });

    if (selectionMode) {
      columns.push({
        headerName: '',
        resizable: false,
        minWidth: 47,
        maxWidth: 47,
        pinned: 'left',
        checkboxSelection: true,
        editable: false,
        suppressMenu: true,
        filter: null,
        hide: false,
        suppressColumnsToolPanel: true,
        colId: 'collection',
        sortable: false,
        suppressMovable: true,
        lockPinned: true,
        lockVisible: true,
      });
    }

    columns.push({
      headerName: '',
      resizable: false,
      minWidth: 47,
      maxWidth: 47,
      pinned: 'left',
      editable: false,
      suppressMenu: true,
      hide: false,
      filter: null,
      suppressColumnsToolPanel: true,
      colId: 'selection',
      sortable: false,
      suppressMovable: true,
      lockPinned: true,
      lockVisible: true,
      cellRendererParams: { page: 'departments', user },
      cellRenderer: GridSelectionRenderer,
    });

    return of(columns);
  }

  managerGridColumns(objectkey: any, user: any) {
    const columns = objectkey
      .filter((col: any) => {
        if (
          col === 'role' ||
          col === 'Department' ||
          col === 'provider' ||
          col === 'settings' ||
          col === 'rules' ||
          col === 'permissions' ||
          col === 'departmentName' ||
          col === 'ManagementAssociationMap'
        ) {
          return;
        } else {
          return col;
        }
      })
      .map((data: any) => {
        if (data === 'stripe_connect_status') {
          return {
            headerName: 'Manager Stripe connect status',
            field: data,
            filter: 'agTextColumnFilter',
          };
        }

        if (data === 'stripeconnectid') {
          return {
            headerName: 'Manager Stripe Connect ID',
            field: data,
            filter: 'agTextColumnFilter',
          };
        }

        if (data === 'emaildigestsetting') {
          return {
            headerName: 'Manager Email Digest Setting',
            field: data,
            filter: 'agTextColumnFilter',
          };
        }

        if (GridFields.manager[data]) {
          return this.defineTextColumn(data, GridFields.manager[data].header);
        }

        if (data === 'createdAt') {
          return this.defineDateColumn(data, 'Created At');
        }
        if (data === 'updatedAt') {
          return this.defineDateColumn(data, 'Updated At');
        }

        return this.defineTextColumn(data, data);
      });

    columns.push(
      {
        ...this.defineTextColumn('rules.owner', 'Institution Owner'),
        valueGetter: params => {
          if (params.data && params.data.rules && params.data.rules.owner) {
            return String(params.data.rules.owner);
          } else {
            return 'false';
          }
        },
        sortable: true,
      },
      {
        ...this.defineTextColumn('ManagerDepartment.account', 'Institution Name'),
        valueGetter: params => {
          if (params.data && params.data.Department && params.data.Department.account) {
            return params.data.Department.account;
          } else {
            return '';
          }
        },
        sortable: true,
      },
      {
        ...this.defineTextColumn('ManagementAssociationMap.hostid', 'Manager Association Map Host ID(s)'),
        valueGetter: params => {
          if (params.data && params.data.ManagementAssociationMap && params.data.ManagementAssociationMap.length > 0) {
            const hostid = [...new Set(params.data.ManagementAssociationMap)]
              .filter((entry: any) => {
                if (entry.hostid) {
                  return entry;
                }
              })
              .map((id: any) => id.hostid)
              .join();
            return hostid;
          } else {
            return '';
          }
        },
        sortable: true,
      },
      {
        ...this.defineTextColumn('ManagementAssociationMap.programid', 'Manager Association Map Program ID(s)'),
        valueGetter: params => {
          if (params.data && params.data.ManagementAssociationMap && params.data.ManagementAssociationMap.length > 0) {
            const programid = [...new Set(params.data.ManagementAssociationMap)]
              .filter((entry: any) => {
                if (entry.programid) {
                  return entry;
                }
              })
              .map((id: any) => id.programid)
              .join();

            return programid;
          } else {
            return '';
          }
        },
        sortable: true,
      },
      {
        ...this.defineTextColumn('Manager.custom', 'Manager Permissions Settings'),
        valueGetter: params => {
          if (params.data && params.data.rules) {
            const managerType: string = params.data.type;
            const permissions: string = params.data.rules.permissions;

            if (managerType === 'Hosting') {
              if (
                permissions ===
                  'hospitaladministrator application:read applications:list user:read program:read program:list program:hideprices hosts:list view:paidapplicants grid:hospitaladministrator hospitaladministrator application:document:view' ||
                permissions ===
                  'application:read applications:list user:read program:read program:list program:hideprices hosts:list view:paidapplicants application:document:view grid:hospitaladministrator hospitaladministrator'
              ) {
                return 'Default';
              }
              return 'Custom';
            } else if (managerType === 'Visitor-Recruitment') {
              if (
                permissions ===
                  'visitorrecruitment application:read applications:list user:write user:read users:list management:visitorinvites program:list document:upload application:document:view' ||
                permissions ===
                  'application:read applications:list users:list user:read user:write program:list document:upload application:document:view management:visitorinvites visitorrecruitment'
              ) {
                return 'Default';
              }
              return 'Custom';
            }
            return '';
          }
          return '';
        },
        sortable: true,
      },
    );

    columns.push({
      headerName: '',
      resizable: false,
      minWidth: 47,
      maxWidth: 47,
      pinned: 'left',
      editable: false,
      suppressMenu: true,
      hide: false,
      filter: null,
      suppressColumnsToolPanel: true,
      colId: 'selection',
      sortable: false,
      suppressMovable: true,
      lockPinned: true,
      lockVisible: true,
      cellRendererParams: { page: 'managers', user },
      cellRenderer: GridSelectionRenderer,
    });
    return of(columns);
  }

  paymentsGridColumns(objectkey: any, user: any) {
    const columns = objectkey
      .filter((col: any) => {
        if (this.paymentsGridHide.indexOf(col) !== -1) {
          return false;
        } else return col;
      })

      .filter((col: any) => {
        if (this.generalProgramHide.indexOf(col) !== -1) {
          return false;
        } else if (user.rules && user.rules.permissions.includes('program:hideprices') && this.priceHide.indexOf(col) !== -1) {
          return false;
        } else if (user.type === 'Visitor-Recruitment' && this.vrProgramHide.indexOf(col) !== -1) {
          return false;
        } else {
          return true;
        }
      })

      .map((data: any) => {
        if (data === 'applicationprice') {
          return {
            headerName: 'Application Price',
            field: 'Application.price',
            valueGetter: params => {
              if (params.data && params.data.applicationprice) {
                return params.data.applicationprice;
              } else {
                return '';
              }
            },
            filter: 'agNumberColumnFilter',
            sortable: true,
          };
        }
        if (data === 'paymentstatus') {
          return this.paymentStatusColumnFilter(data, 'Payment Status');
        }
        if (data === 'paymentreceiveddate') {
          return this.defineDateColumn(data, 'Payment Received Date');
        }
        if (data === 'paymentcreateddate') {
          return this.defineDateColumn(data, 'Payment Created Date');
        }
        if (data === 'paymentupdateddate') {
          return this.defineDateColumn(data, 'Payment Updated Date');
        }
        if (data === 'paymentrecordid') {
          return this.defineTextColumn(data, 'Payment Record ID');
        }
        if (data === 'traineeid') {
          return this.defineTextColumn(data, 'Trainee ID');
        }
        if (data === 'applicationid') {
          return this.defineTextColumn(data, 'Application ID');
        }
        // Applicant Name need input
        if (data === 'payername') {
          return this.defineTextColumn(data, 'Payer Name');
        }
        if (data === 'payeraddress') {
          return this.defineTextColumn(data, 'Payer Address');
        }
        if (data === 'paymenttype') {
          return this.defineTextColumn(data, 'Payment Type');
        }
        if (data === 'payeremail') {
          return this.defineTextColumn(data, 'Payer Email');
        }
        if (data === 'payerid') {
          return this.defineTextColumn(data, 'Payer ID');
        }
        if (data === 'coupons') {
          return this.defineTextColumn(data, 'Coupons');
        }
        if (data === 'wire') {
          return this.defineTextColumn(data, 'Notes');
        }
        if (data === 'notes') {
          return this.defineTextColumn(data, 'Notes');
        }
        if (data === 'ispayment') {
          return this.booleanColumnFilter(data, 'Is Payment');
        }
        if (data === 'isdeposit') {
          return this.booleanColumnFilter(data, 'Is Deposit');
        }
        if (data === 'paymentid') {
          return this.defineTextColumn(data, 'Payment ID');
        }
        if (data === 'depositid') {
          return this.defineTextColumn(data, 'Deposit ID');
        }
        if (data === 'immigrationservice') {
          return this.booleanColumnFilter(data, 'Is Immigration Service');
        }
        if (data === 'isswitchprotection') {
          return this.booleanColumnFilter(data, 'Is Switch Protection');
        }
        if (data === 'isvoucher') {
          return this.booleanColumnFilter(data, 'Is Voucher');
        }

        if (data === 'advisors') {
          return {
            // headerName: data,
            headerName: 'Advisors',
            field: 'advisors',
            filter: 'agTextColumnFilter',
            // filterParams: {
            //   filterOptions: [
            //     "equals",
            //     {
            //       displayKey: "equals",
            //       displayName: "equals",
            //       predicate: ([filterValue], cellValue) =>
            //         cellValue == null || cellValue == filterValue
            //     }
            //   ]
            // },
            sortable: true,
            valueGetter: params => {
              if (params.data && params.data.advisors) {
                let name = params.data.advisors;
                return name;
              }
              return '';
            },
          };
        }
        if (data === 'isfullpayment') {
          return this.booleanColumnFilter(data, 'Is Full Payment');
        }
        if (data === 'studentmalpractice') {
          return this.booleanColumnFilter(data, 'Is Malpractice');
        }
        if (data === 'ismalpractice') {
          return this.booleanColumnFilter(data, 'Is Malpractice');
        }
        if (data === 'phone') {
          return this.defineTextColumn(data, 'Payer Phone');
        }
        if (data === 'paymentamount') {
          return {
            // headerName: data,
            headerName: 'Payment Amount',
            field: data,
            filter: 'agNumberColumnFilter',
            filterParams: {
              filterOptions: [
                'equals',
                {
                  displayKey: 'equals',
                  displayName: 'equals',
                  predicate: ([filterValue], cellValue) => cellValue == null || cellValue == filterValue,
                },
              ],
            },
            sortable: true,
          };
        }
        if (data === 'lineitems') {
          return {
            headerName: 'Line Items',
            field: data,
            filter: 'agTextColumnFilter',
            sortable: true,
            valueGetter: params => {
              if (params.data && params.data.lineitems) {
                let parsedLineItems = params.data.lineitems;
                if (typeof parsedLineItems === 'string') {
                  parsedLineItems = JSON.parse(parsedLineItems);
                }
                if (parsedLineItems.length > 0) {
                  let lineList = parsedLineItems.map(text => {
                    return `${text.name}: $${text.price}, `;
                  });
                  const lineText = lineList.join(',');
                  return lineText;
                }
                return '';
              }
              return '';
            },
          };
        }
        return {
          ...this.defineTextColumn(data, data), //GridFields.manager[data].header)
        };
      });

    // newly added for columns name
    columns.push(
      {
        // adding coupons here because it is an uncommon field, it is not in the first page of payments
        // we put coupons here so it always will show up in the AG Grid
        ...this.defineTextColumn('coupons', 'Coupons'),
        valueGetter: params => {
          if (params.data) {
            return params.data.coupons;
          } else {
            return '';
          }
        },
      },
      {
        ...this.defineNumberColumn('Program.stipendamount', 'Stipend Amount'),
        valueGetter: params => {
          if (params.data && params.data && params.data.Program && params.data.Program.stipendamount) {
            return params.data.Program.stipendamount;
          } else {
            return '';
          }
        },
      },
      {
        ...this.defineTextColumn('Program.Host.hostname', 'Host Name'),

        valueGetter: params => {
          if (params.data && params.data.Program && params.data.Program.Host) {
            return params.data.Program.Host.hostname;
          } else {
            return '';
          }
        },
      },
      {
        ...this.defineTextColumn('Application.applicationordertype', 'Application Order Type'),
        valueGetter: params => {
          if (params.data && params.data.Application) {
            return params.data.Application.applicationordertype;
          } else {
            return '';
          }
        },
      },
      {
        ...this.defineDateColumn('Application.startdate', 'Start Date'),
        valueGetter: params => {
          if (params.data && params.data.Application) {
            return params.data.Application.startdate;
          } else {
            return '';
          }
        },
      },
      {
        ...this.defineDateColumn('Application.enddate', 'End Date'),
        valueGetter: params => {
          if (params.data && params.data.Application) {
            return params.data.Application.enddate;
          } else {
            return '';
          }
        },
      },
      {
        ...this.defineDateColumn('Application.reserveddate', 'Reserved Date'),
        valueGetter: params => {
          if (params.data && params.data.Application) {
            return params.data.Application.reserveddate;
          } else {
            return '';
          }
        },
      },
      {
        ...this.booleanColumnFilter('Application.stipendpaid', 'Stipend Paid'),
        valueGetter: params => {
          if (params.data && params.data.Application) {
            return params.data.Application.stipendpaid;
          } else {
            return '';
          }
        },
      },
      {
        ...this.defineNumberColumn('Application.applicationprice', 'Application Price'),
        valueGetter: params => {
          if (params.data && params.data.Application) {
            return params.data.Application.applicationprice;
          } else {
            return '';
          }
        },
      },

      {
        ...this.applicationStatusColumnFilter('Application.applicationstatus', 'Application Status'),
        valueGetter: params => {
          if (params.data && params.data.Application) {
            return params.data.Application.applicationstatus;
          } else {
            return '';
          }
        },
      },
      {
        ...this.defineTextColumn('User.Department.traineeinstitution', 'Trainee Institution'),
        valueGetter: params => {
          if (params.data && params.data && params.data.User && params.data.User.Department) {
            return params.data.User.Department.traineeinstitution;
          } else {
            return '';
          }
        },
      },

      {
        ...this.defineTextColumn('Program.Host.Department.hostinstitution', 'Host Institution'),
        valueGetter: params => {
          if (params.data && params.data && params.data.Program && params.data.Program.Host && params.data.Program.Host.Department) {
            return params.data.Program.Host.Department.hostinstitution;
          } else {
            return '';
          }
        },
      },
      {
        ...this.applicationYearFilterColumnFilter('Application.startyear', 'Start Year'),
        valueGetter: params => {
          if (params.data && params.data.Application) {
            return params.data.Application.startyear;
          } else {
            return '2099';
          }
        },
      },

      {
        ...this.monthColumnFilter('Application.month', 'Month'),
        valueGetter: params => {
          if (params.data && params.data.Application) {
            return params.data.Application.month;
          } else {
            return '';
          }
        },
      },
      {
        ...this.defineTextColumn('User.traineename', 'Trainee Name'),
        valueGetter: params => {
          if (params.data && params.data && params.data.User) {
            return params.data.User.traineename;
          } else {
            return '';
          }
        },
      },
      {
        ...this.defineTextColumn('User.university', 'Trainee University'),
        valueGetter: params => {
          if (params.data && params.data && params.data.User) {
            return params.data.User.university;
          } else {
            return '';
          }
        },
      },
      {
        ...this.defineTextColumn('Application.applicationprogramtype', 'Application Program Type'),
        valueGetter: params => {
          if (params.data && params.data.Application) {
            return params.data.Application.applicationprogramtype;
          } else {
            return '';
          }
        },
      },

      {
        ...this.defineTextColumn('Program.programprimaryspecialty', 'Program Primary Specialty'),
        valueGetter: params => {
          if (params.data && params.data && params.data.Program) {
            return params.data.Program.programprimaryspecialty;
          } else {
            return '';
          }
        },
      },

      {
        ...this.defineNumberColumn('Program.programid', 'Program ID'),
        valueGetter: params => {
          if (params.data && params.data && params.data.Program) {
            return params.data.Program.programid;
          } else {
            return '';
          }
        },
      },
      {
        ...this.defineTextColumn('User.stripecustomerid', 'Stripe Customer ID'),
        valueGetter: params => {
          if (params.data && params.data && params.data.User) {
            return params.data.User.stripecustomerid;
          } else {
            return '';
          }
        },
      },
      {
        ...this.defineTextColumn('OrderProgramRotation.invoiceid', 'Invoice ID'),
        valueGetter: params => {
          if (params.data && params.data.OrderProgramRotation) {
            return params.data.OrderProgramRotation.invoiceid;
          } else {
            return '';
          }
        },
      },
    );

    columns.push({
      headerName: '',
      resizable: false,
      minWidth: 47,
      maxWidth: 47,
      pinned: 'left',
      editable: false,
      suppressMenu: true,
      hide: false,
      filter: null,
      suppressColumnsToolPanel: true,
      colId: 'link',
      sortable: false,
      suppressMovable: true,
      lockPinned: true,
      lockVisible: true,
      cellRendererParams: { page: 'payment', user },
      cellRenderer: GridSelectionRenderer,
    });

    return columns;
  }

  reviewsGridColumns(objectkey: any, user: any) {
    const columns = objectkey
      .filter((col: any) => {
        if (this.generalReviewHide.indexOf(col) !== -1) {
          return false;
        } else {
          return col;
        }
      })
      .map((data: any) => {
        if (data === 'id') {
          return this.defineTextColumn(data, 'Review ID');
        } else if (data === 'content') {
          return this.defineTextColumn(data, 'Review Content');
        } else if (data === 'rating') {
          return this.defineNumberColumn(data, 'Review Rating');
        } else if (data === 'date') {
          return this.defineDateColumn(data, 'Review Date');
        } else if (data === 'createdAt') {
          return this.defineDateColumn(data, 'Created Date');
        } else if (data === 'updatedAt') {
          return this.defineDateColumn(data, 'Updated Date');
        } else if (data === 'satisfaction') {
          return this.defineNumberColumn(data, 'Review Satisfaction');
        } else if (data === 'live') {
          return this.defineTextColumn(data, 'Review Live');
        } else if (data === 'name') {
          return this.defineTextColumn(data, 'Trainee First Name');
        } else if (data === 'programid') {
          return this.defineTextColumn(data, 'Review Program ID');
        } else if (data === 'applicationid') {
          return this.defineTextColumn(data, 'Review Application ID');
        } else if (data === 'programlocation') {
          return this.defineTextColumn(data, 'Program Location');
        } else if (data === 'type') {
          return this.defineTextColumn(data, 'Review Type');
        } else if (data === 'userid') {
          return this.defineTextColumn(data, 'Trainee ID');
        } else {
          return this.defineTextColumn(data, data);
        }
      });

    columns.push(
      {
        ...this.defineTextColumn('Application.month', 'Application Month'),
        valueGetter: params => (params.data && params.data.Application && params.data.Application.month ? params.data.Application.month : ''),
      },
      {
        ...this.defineTextColumn('Application.startyear', 'Application Start Year'),
        valueGetter: params => (params.data && params.data.Application && params.data.Application.startyear ? params.data.Application.startyear : ''),
      },
      {
        ...this.defineTextColumn('User.university', 'Trainee University'),
        valueGetter: params => {
          if (params.data && params.data.User && params.data.User.university) {
            let uni = params.data.User.university;
            let parsedUni;
            if (uni && typeof uni === 'string') {
              try {
                parsedUni = JSON.parse(uni);
              } catch (error) {
                parsedUni = uni;
              }
            }
            if (typeof parsedUni === 'string') {
              return uni;
            } else {
              if (parsedUni[0]) {
                return `${parsedUni[0].custom || parsedUni[0].schoolname}`;
              } else {
                return '';
              }
            }
          } else {
            return '';
          }
        },
      },
      {
        ...this.defineTextColumn('User.status', 'Trainee Academic Status'),
        valueGetter: params => (params.data && params.data.User && params.data.User.status ? params.data.User.status : ''),
      },
      {
        ...this.defineTextColumn('User.countryofbirth', 'Trainee Country of Birth'),
        valueGetter: params => (params.data && params.data.User && params.data.User.countryofbirth ? params.data.User.countryofbirth : ''),
      },
      {
        ...this.defineTextColumn('User.departmentid', 'Trainee Institution ID'),
        valueGetter: params => (params.data && params.data.User && params.data.User.departmentid ? params.data.User.departmentid : ''),
      },
      {
        ...this.defineTextColumn('ReviewUserDepartment.account', 'Trainee Institution Name'),
        valueGetter: params =>
          params.data && params.data.User && params.data.User.Department && params.data.User.Department.account ? params.data.User.Department.account : '',
      },
      {
        ...this.defineTextColumn('Application.coach', 'Coach'),
        valueGetter: params => (params.data && params.data.Application && params.data.Application.coach ? params.data.Application.coach : ''),
      },
      {
        ...this.defineTextColumn('Application.coordinator', 'Coordinator'),
        valueGetter: params => (params.data && params.data.Application && params.data.Application.coordinator ? params.data.Application.coordinator : ''),
      },
      {
        ...this.defineTextColumn('Application.housingaddress', 'Application Full Housing Address'),
        valueGetter: params => (params.data && params.data.Application && params.data.Application.housingaddress ? params.data.Application.housingaddress : ''),
      },
      {
        ...this.defineTextColumn('Application.housingtype', 'Application Type of Housing'),
        valueGetter: params => (params.data && params.data.Application && params.data.Application.housingtype ? params.data.Application.housingtype : ''),
      },
      {
        ...this.defineTextColumn('User.email', 'Trainee Email'),
        valueGetter: params => (params.data && params.data.User.email ? params.data.User.email : ''),
      },
      {
        ...this.defineTextColumn('ReviewProgramHost.name', 'Host Name'),
        valueGetter: params =>
          params.data && params.data.Program && params.data.Program.Host && params.data.Program.Host.name ? params.data.Program.Host.name : '',
      },
      {
        ...this.defineTextColumn('feedback', 'Review Feedback'),
        valueGetter: params => {
          if (params.data && params.data.feedback) {
            let feedback = params.data.feedback;
            if (typeof feedback === 'object') {
              // feedback = JSON.stringify(params.data.feedback);
              var string = '';
              var resp = params.data.feedback.map(item => {
                if (item.question && item.answer) {
                  return item.question + ': ' + item.answer + ' ';
                }
              });

              return resp;
            }
            return `${feedback}`;
          } else {
            return '';
          }
        },
      },
      {
        ...this.defineTextColumn('ReviewProgramHost.departmentid', 'Host Institution ID'),
        valueGetter: params => (params.data && params.data.Program && params.data.Program.Host ? params.data.Program.Host.departmentid : ''),
      },
    );

    columns.push({
      headerName: '',
      resizable: false,
      minWidth: 47,
      maxWidth: 47,
      pinned: 'left',
      editable: false,
      suppressMenu: true,
      filter: null,
      hide: false,
      suppressColumnsToolPanel: true,
      colId: 'link',
      sortable: false,
      suppressMovable: true,
      lockPinned: true,
      lockVisible: true,
      cellRendererParams: { page: 'reviews', user },
      cellRenderer: GridSelectionRenderer,
    });

    return columns;
  }

  fieldofStudiesGridColumns(objectkey: any, user: any) {
    const columns = objectkey
      .filter((col: any) => {
        if (this.generalReviewHide.indexOf(col) !== -1) {
          return false;
        } else {
          return col;
        }
      })
      .map((data: any) => {
        if (data === 'id') {
          return this.defineTextColumn(data, 'Fields Of Study ID');
        } else if (data === 'name') {
          return this.defineTextColumn(data, 'Name');
        } else if (data === 'description') {
          return this.defineTextColumn(data, 'Description');
        } else if (data === 'hasdepartments') {
          return this.defineNumberColumn(data, 'Has Institutions');
        } else if (data === 'allowprofileblock') {
          return this.defineNumberColumn(data, 'Show on Profile Block');
        } else if (data === 'addusonly') {
          return this.defineNumberColumn(data, 'Add US Only');
        } else if (data === 'studenttype') {
          return this.defineTextColumn(data, 'Student Program Type');
        } else if (data === 'graduatetype') {
          return this.defineTextColumn(data, 'Graduate Program Type');
        } else if (data === 'institutionid') {
          return this.defineDateColumn(data, 'Institutionid');
        } else if (data === 'createdAt') {
          return this.defineDateColumn(data, 'Created Date');
        } else if (data === 'updatedAt') {
          return this.defineDateColumn(data, 'Updated Date');
        } else {
          return this.defineTextColumn(data, data);
        }
      });

    columns.push({
      headerName: '',
      resizable: false,
      minWidth: 47,
      maxWidth: 47,
      pinned: 'left',
      editable: false,
      suppressMenu: true,
      filter: null,
      hide: false,
      suppressColumnsToolPanel: true,
      colId: 'link',
      sortable: false,
      suppressMovable: true,
      lockPinned: true,
      lockVisible: true,
      cellRendererParams: { page: 'fieldOfStudies', user },
      cellRenderer: GridSelectionRenderer,
    });

    return columns;
  }

  reviewTemplateGridColumns(objectkey: any, user: any) {
    const columns: any[] = [...objectkey].map(colTitle => {
      // Some column names will be subject to stakeholders' approval.
      // Sometimes they want a different name for the frontend to make sense to non-employee users.
      // This is why we cascade though all colTitle values rather than have a pure function build our column names
      // to have more control over naming
      if (colTitle === 'id') {
        return this.defineTextColumn(colTitle, 'Review Template ID');
      }

      if (colTitle === 'title') {
        return this.defineTextColumn(colTitle, 'Title');
      }

      if (colTitle === 'programid') {
        return this.defineTextColumn(colTitle, 'Program ID');
      }

      if (colTitle === 'programtype') {
        return this.defineTextColumn(colTitle, 'Program Type');
      }

      if (colTitle === 'type') {
        return this.defineTextColumn(colTitle, 'Rotation Type');
      }

      if (colTitle === 'level') {
        return this.defineTextColumn(colTitle, 'Level');
      }

      if (colTitle === 'departmentid') {
        return this.defineTextColumn(colTitle, 'Institution ID');
      }

      if (colTitle === 'hostid') {
        return this.defineTextColumn(colTitle, 'Host ID');
      }

      if (colTitle === 'includedefault') {
        return this.defineTextColumn(colTitle, 'Include Default Questions');
      }

      if (colTitle === 'includedepartment') {
        return this.defineTextColumn(colTitle, 'Include Institution Questions');
      }

      if (colTitle === 'includehost') {
        return this.defineTextColumn(colTitle, 'Include Host Questions');
      }

      if (colTitle === 'questions') {
        return {
          ...this.defineTextColumn(colTitle, 'Questions'),
          valueGetter: params => {
            if (params.data && params.data.questions && params.data.questions.length > 0) {
              var parsedList;
              try {
                if (typeof params.data.questions === 'string') {
                  parsedList = JSON.parse(params.data.questions);
                } else {
                  parsedList = params.data.questions;
                }
                let list: string[] = [...parsedList].map(question => {
                  return this.regexHtmlToString(question.question);
                });
                return list.join('; ');
              } catch (error) {
                return params.data.questions;
              }
            } else {
              return '';
            }
          },
        };
      }
      if (colTitle === 'createdAt') {
        return this.defineDateColumn(colTitle, 'Created Date');
      }
      if (colTitle === 'updatedAt') {
        return this.defineDateColumn(colTitle, 'Updated Date');
      }

      // catch all if no matches
      return this.defineTextColumn(colTitle, colTitle);
    });

    columns.push({
      headerName: '',
      resizable: false,
      minWidth: 47,
      maxWidth: 47,
      pinned: 'left',
      editable: false,
      suppressMenu: true,
      filter: null,
      hide: false,
      suppressColumnsToolPanel: true,
      colId: 'link',
      sortable: false,
      suppressMovable: true,
      lockPinned: true,
      lockVisible: true,
      cellRendererParams: { page: 'reviewTemplates', user },
      cellRenderer: GridSelectionRenderer,
    });

    return columns;
  }

  regexHtmlToString(str) {
    const regex = /(<([^>]+)>)/gi;
    try {
      return str.replace(regex, '');
    } catch (ex) {
      return str;
    }
  }

  applicationRequirementsMapGridColumns(objectkey: any, user: any) {
    const columns = objectkey
      .filter((col: any) => {
        if (
          col === 'Application' ||
          col === 'Department' ||
          col === 'Host' ||
          col === 'Program' ||
          col === 'Documents' ||
          col === 'metadata' ||
          col === 'deletedAt' ||
          col === 'ApplicationRequirements'
        ) {
          return false;
        } else {
          return true;
        }
      })
      .map((data: any) => {
        if (data === 'id') {
          return this.defineTextColumn(data, 'Application Requirements Map ID');
        }
        if (data === 'applicationid') {
          return this.defineTextColumn(data, 'Application ID');
        }
        if (data === 'ApplicationRequirementId') {
          return this.defineTextColumn(data, 'Application Requirement ID');
        }
        if (data === 'departmentid') {
          return this.defineTextColumn(data, 'Institution ID');
        }
        if (data === 'documentid') {
          return this.defineTextColumn(data, 'Document ID');
        }
        if (data === 'enabled') {
          return this.defineTextColumn(data, 'Enabled');
        }
        if (data === 'hostid') {
          return this.defineTextColumn(data, 'Host ID');
        }
        if (data === 'level') {
          return this.defineTextColumn(data, 'Level');
        }
        if (data === 'programid') {
          return this.defineTextColumn(data, 'Program ID');
        }
        if (data === 'createdAt') {
          return this.defineDateColumn(data, 'Host Created Date');
        }
        if (data === 'updatedAt') {
          return this.defineDateColumn(data, 'Host Updated Date');
        }

        return this.defineTextColumn(data, data);
      });

    columns.push(
      ...[
        {
          ...this.defineTextColumn('ApplicationRequirements.jsondata.name', 'Requirement Name'),
          valueGetter: params => (params.data && params.data.ApplicationRequirements ? params.data.ApplicationRequirements.jsondata.name : ''),
        },
        {
          ...this.defineTextColumn('ApplicationRequirements.jsondata.type', 'Requirement Type'),
          valueGetter: params => (params.data && params.data.ApplicationRequirements ? params.data.ApplicationRequirements.jsondata.type : ''),
        },
        {
          ...this.defineTextColumn('ApplicationRequirements.jsondata.datatype', 'Requirement Data Type'),
          valueGetter: params => (params.data && params.data.ApplicationRequirements ? params.data.ApplicationRequirements.jsondata.datatype : ''),
        },
        {
          ...this.defineTextColumn('ApplicationRequirements.jsondata.programtype', 'Requirement Program Type'),
          valueGetter: params => (params.data && params.data.ApplicationRequirements ? params.data.ApplicationRequirements.jsondata.programtype : ''),
        },
        {
          ...this.defineTextColumn('ApplicationRequirements.jsondata.academicstatus', 'Requirement Academic Status'),
          valueGetter: params => (params.data && params.data.ApplicationRequirements ? params.data.ApplicationRequirements.jsondata.academicstatus : ''),
        },
        {
          ...this.defineTextColumn('ApplicationRequirements.jsondata.postreservation', 'Requirement Post-Reservation'),
          valueGetter: params => (params.data && params.data.ApplicationRequirements ? params.data.ApplicationRequirements.jsondata.postreservation : ''),
        },
        {
          ...this.defineTextColumn('ApplicationRequirements.jsondata.usersubmission', 'Requires File Submission'),
          valueGetter: params => (params.data && params.data.ApplicationRequirements ? params.data.ApplicationRequirements.jsondata.usersubmission : ''),
        },
        {
          ...this.defineTextColumn('ApplicationRequirements.jsondata.internalnotes', 'Requirement Internal Notes'),
          valueGetter: params => (params.data && params.data.ApplicationRequirements ? params.data.ApplicationRequirements.jsondata.internalnotes : ''),
        },
        {
          ...this.defineTextColumn('ApplicationRequirements.jsondata.preapproval', 'Requirement Pre-Approval'),
          valueGetter: params => (params.data && params.data.ApplicationRequirements ? params.data.ApplicationRequirements.jsondata.preapproval : ''),
        },
        {
          ...this.defineTextColumn('Document.filename', 'File Name'),
          valueGetter: params => (params.data && params.data.Documents ? params.data.Documents.filename : ''),
        },
        {
          ...this.defineTextColumn('Program.name', 'Program Name'),
          valueGetter: params => (params.data && params.data.Program ? params.data.Program.name : ''),
        },
        {
          ...this.defineTextColumn('Host.name', 'Host Name'),
          valueGetter: params => (params.data && params.data.Host ? params.data.Host.name : ''),
        },
        {
          ...this.defineTextColumn('Department.name', 'Institution Name'),
          valueGetter: params => (params.data && params.data.Department ? params.data.Department.name : ''),
        },
      ],
    );

    columns.push({
      headerName: '',
      resizable: false,
      minWidth: 47,
      maxWidth: 47,
      pinned: 'left',
      editable: false,
      suppressMenu: true,
      filter: null,
      hide: false,
      suppressColumnsToolPanel: true,
      colId: 'link',
      sortable: false,
      suppressMovable: true,
      lockPinned: true,
      lockVisible: true,
      cellRendererParams: { page: 'applicationrequirementsmap', user },
      cellRenderer: GridSelectionRenderer,
    });

    return columns;
  }

  // Hosts Page
  hostsGridColumns(dataObj: any, user: any, selectionMode?: boolean) {
    const columns = dataObj
      .filter((col: any) => {
        if (
          col === 'User' ||
          col === 'Program' ||
          col === 'Licenses' ||
          col === 'hashedPassword' ||
          col === 'password' ||
          col === 'salt' ||
          col === 'hashid' ||
          col === 'recentip' ||
          col === 'role' ||
          col === 'address' ||
          col === 'city' ||
          col === 'state' ||
          col === 'postalcode' ||
          col === 'specialty' ||
          col === 'workphone' ||
          col === 'workphoneext' ||
          col === 'unavailable' ||
          col === 'weeklyhours' ||
          col === 'permissions' ||
          col === 'optout' ||
          col === 'statement' ||
          col === 'subspecialties' ||
          col === 'subscribed' ||
          col === 'subcribed' ||
          col === 'aquisition' ||
          col === 'locked' ||
          col === 'provider'
        ) {
          return false;
        } else {
          return true;
        }
      })
      .map((data: any) => {
        if (data === 'stipendcost') {
          return this.defineNumberColumn(data, 'Host Stipend Cost');
        }

        if (data === 'birthday') {
          return this.defineDateColumn(data, 'Host Date of Birth');
        }
        if (data === 'createdAt') {
          return this.defineDateColumn(data, 'Host Created Date');
        }
        if (data === 'updatedAt') {
          return this.defineDateColumn(data, 'Host Updated Date');
        }
        if (data === 'Department') {
          return {
            ...this.defineTextColumn('Department.account', 'Host Institution'),
            valueGetter: params => {
              if (params.data && params.data.Department && params.data.Department.account) {
                return params.data.Department.account;
              } else {
                return '';
              }
            },
          };
        }
        if (data === 'id') {
          return this.defineTextColumn(data, 'Host ID');
        }
        if (data === 'name') {
          return this.defineTextColumn(data, 'Host Name');
        }
        if (data === 'email') {
          return this.defineTextColumn(data, 'Host Email');
        }
        if (data === 'activated') {
          return this.defineTextColumn(data, 'Host Activated');
        }
        if (data === 'offered') {
          return this.defineNumberColumn(data, 'Slots Per Block'); //"Host Offered");
        }
        if (data === 'usehostavailability') {
          return this.defineTextColumn(data, 'Enable Host Capacity'); //"Enable Host Availability");
        }
        if (data === 'specialties') {
          return {
            ...this.defineTextColumn(data, 'Host Board Certified Specialties'),
            valueGetter: params => {
              if (params.data && params.data.specialties && params.data.specialties.length > 0) {
                var parsedList;
                try {
                  if (typeof params.data.specialties === 'string') {
                    parsedList = JSON.parse(params.data.specialties);
                  } else {
                    parsedList = params.data.specialties;
                  }
                  let list = [...parsedList].map(specicalty => {
                    return specicalty.text;
                  });
                  return list.join(',');
                } catch (error) {
                  return params.data.specialties;
                }
              } else {
                return '';
              }
            },
          };
        }
        if (data === 'phone') {
          return this.defineTextColumn(data, 'Host Primary Phone');
        }
        if (data === 'cellphone') {
          return this.defineTextColumn(data, 'Host Mobile Phone');
        }
        if (data === 'isprofessor') {
          return this.defineTextColumn(data, 'Host Academic Affiliated');
        }
        if (data === 'offersinpatient') {
          return this.defineTextColumn(data, 'Host Inpatient');
        }
        if (data === 'altphone') {
          return this.defineTextColumn(data, 'Host Alternate Phone');
        }
        if (data === 'faxnumber') {
          return this.defineTextColumn(data, 'Host Fax Number');
        }
        if (data === 'gender') {
          return this.defineTextColumn(data, 'Host Gender');
        }
        if (data === 'departmentid') {
          return this.defineTextColumn(data, 'Host Institution ID');
        }
        if (data === 'contactname') {
          return this.defineTextColumn(data, 'Host Admin Name');
        }
        if (data === 'contactphone') {
          return this.defineTextColumn(data, 'Host Admin Phone');
        }
        if (data === 'contactemail') {
          return this.defineTextColumn(data, 'Host Admin Email');
        }
        if (data === 'contactphoneext') {
          return this.defineTextColumn(data, 'Host Admin Phone Ext');
        }
        if (data === 'clinicname') {
          return this.defineTextColumn(data, 'Host Clinic Name');
        }
        if (data === 'clinicaddress') {
          return this.defineTextColumn(data, 'Host Clinic Address');
        }
        if (data === 'cliniccity') {
          return this.defineTextColumn(data, 'Host Clinic City');
        }
        if (data === 'clinicstate') {
          return this.defineTextColumn(data, 'Host Clinic State');
        }
        if (data === 'clinicpostalcode') {
          return this.defineTextColumn(data, 'Host Clinic Postal Code');
        }
        if (data === 'experiencetypes') {
          return {
            ...this.defineTextColumn(data, 'Host Clinic Experience Types'),
            valueGetter: params => {
              if (params.data && params.data.experiencetypes && params.data.experiencetypes.length > 0) {
                var parsedList;
                try {
                  if (typeof params.data.experiencetypes === 'string') {
                    parsedList = JSON.parse(params.data.experiencetypes);
                  } else {
                    parsedList = params.data.experiencetypes;
                  }
                  let list = [...parsedList].map(type => {
                    return type.text;
                  });
                  return list.join(',');
                } catch (error) {
                  return params.data.experiencetypes;
                }
              } else {
                return '';
              }
            },
          };
        }
        if (data === 'clinicphone') {
          return this.defineTextColumn(data, 'Host Clinic Phone');
        }
        if (data === 'clinicphoneext') {
          return this.defineTextColumn(data, 'Host Clinic Phone Ext');
        }
        if (data === 'clinicfaxnumber') {
          return this.defineTextColumn(data, 'Host Clinic Fax');
        }
        if (data === 'clinicwebsite') {
          return this.defineTextColumn(data, 'Host Clinic Website');
        }
        if (data === 'clinicinfo') {
          return this.defineTextColumn(data, 'Host Clinic Info');
        }
        if (data === 'internalnotes') {
          if (user.type === 'AMOEMPLOYEE') {
            return this.defineTextColumn(data, 'Host Internal Notes');
          }
        }

        if (data === 'emaildigestsetting') {
          return {
            headerName: 'Host Email Digest Setting',
            field: data,
            filter: 'agTextColumnFilter',
          };
        }

        if (data === 'internaltags') {
          return this.defineTextColumn(data, 'Host Internal Tags');
        }
        if (data === 'coach') {
          return this.defineTextColumn(data, 'Host Student Coordinator');
        }
        if (data === 'coordinator') {
          return this.defineTextColumn(data, 'Host Program Coordintor');
        }
        if (data === 'resumecv') {
          return this.defineTextColumn(data, 'Host CV');
        }
        if (data === 'npi') {
          return this.defineTextColumn(data, 'Host NPI');
        }
        if (data === 'degreetype') {
          return this.defineTextColumn(data, 'Host Degree Type');
        }
        if (data === 'proofoflicensure') {
          return this.defineTextColumn(data, 'Host Proof of Licensure');
        }
        if (data === 'licensedstates') {
          return this.defineTextColumn(data, 'Old Host Licensed States');
        }
        if (data === 'licenseexpiration') {
          return this.defineTextColumn(data, 'Old Host License Expiration Date');
        }
        if (data === 'boardcertifications') {
          return this.defineTextColumn(data, 'Host Certifiying Board Name');
        }
        if (data === 'hubspotcontactid') {
          return this.defineTextColumn(data, 'Host Hubspot Contact ID');
        }
        if (data === 'referredhostid') {
          return this.defineTextColumn(data, 'Referred Host ID');
        }

        return this.defineTextColumn(data, data);
      })
      .map((last: any) => {
        if (user.type === 'Hosting' && this.hostHostColumns.indexOf(last.field) === -1) {
          last.hide = true;
          return last;
        }
        return last;
      });

    columns.push({
      ...this.defineTextColumn('Licenses', 'New Host Licensed State and Expiration Date'),
      valueGetter: params => {
        if (params.data && params.data.Licenses) {
          var parsedList;
          try {
            if (typeof params.data.Licenses === 'string') {
              parsedList = JSON.parse(params.data.Licenses);
            } else {
              parsedList = params.data.Licenses;
            }

            let expiration;
            let formatted_str;
            let LicenseList = [];
            parsedList.forEach(element => {
              if (element.state && element.licenseexpiration) {
                expiration = new Date(element.licenseexpiration);
                formatted_str =
                  '(' +
                  element.state +
                  ' - ' +
                  expiration.toLocaleString('default', { month: 'short', day: 'numeric', year: 'numeric' }).replace(',', '') +
                  ')';
                LicenseList.push(formatted_str);
              } else if (element.state && !element.licenseexpiration) {
                formatted_str = '(' + element.state + ')';
                LicenseList.push(formatted_str);
              } else if (!element.state && element.licenseexpiration) {
                expiration = new Date(element.licenseexpiration);
                let formatted_str = '(' + expiration.toLocaleString('default', { month: 'short', day: 'numeric', year: 'numeric' }).replace(',', '') + ')';
                LicenseList.push(formatted_str);
              } else {
                LicenseList.push('');
              }
            });
            return LicenseList.join(', ');
          } catch (err) {
            return params.data.Licenses;
          }
        } else {
          return '';
        }
      },
    });

    if (selectionMode) {
      columns.push({
        headerName: '',
        resizable: false,
        minWidth: 47,
        maxWidth: 47,
        pinned: 'left',
        checkboxSelection: true,
        editable: false,
        suppressMenu: true,
        filter: null,
        hide: false,
        suppressColumnsToolPanel: true,
        colId: 'collection',
        sortable: false,
        suppressMovable: true,
        lockPinned: true,
        lockVisible: true,
      });
    }

    columns.push({
      headerName: '',
      resizable: false,
      minWidth: 47,
      maxWidth: 47,
      pinned: 'left',
      editable: false,
      suppressMenu: true,
      hide: false,
      filter: null,
      suppressColumnsToolPanel: true,
      colId: 'selection',
      sortable: false,
      suppressMovable: true,
      lockPinned: true,
      lockVisible: true,
      cellRendererParams: { page: 'host', user },
      cellRenderer: GridSelectionRenderer,
    });

    return of(columns);
  }

  // Dashboard Page
  dashboardGridColumns(dataObj: any, user: any) {
    const columns = dataObj
      .filter((col: any) => {
        if (
          col === 'User' ||
          col === 'Program' ||
          col === 'color' ||
          col === 'title' ||
          col === 'physicianemail' ||
          col === 'trellourl' ||
          col === 'programtrellourl' ||
          col === 'physician' ||
          col === 'fieldofstudyid'
        ) {
          return false;
        } else if (user.rules && user.rules.permissions.includes('program:hideprices') && this.priceHide.indexOf(col) !== -1) {
          return false;
        } else {
          return true;
        }
      })
      .map((data: any) => {
        if (data === 'resume') {
          return {
            headerName: 'Resume',
            field: data,
            filter: 'agTextColumnFilter',
            cellRendererParams: { name: 'resume' },
            cellRenderer: GridAnchorRenderer,
          };
        }

        if (data === 'states') {
          return {
            headerName: 'Application State',
            field: data,
            filter: true,
            cellClass: this.statusStyleChange,
          };
        }
        if (data === 'name') {
          return {
            ...this.defineTextColumn(data, 'Trainee Name'),
          };
        }
        if (data === 'startsAt') {
          if (this.dateColumns.indexOf(data) !== -1) {
            return {
              headerName: 'Application Start Date',
              field: data,
              sort: 'asc',
              filter: 'agDateColumnFilter',
              valueFormatter: this.dateFormatter,
              filterParams: {
                filterOptions: [
                  {
                    displayKey: 'equals',
                    displayName: 'On',
                    predicate: this.dateOn,
                  },
                  {
                    displayKey: 'lessThanOrEqual',
                    displayName: 'Before',
                    predicate: this.dateLessThan,
                  },
                  {
                    displayKey: 'greaterThanOrEqual',
                    displayName: 'After',
                    predicate: this.dateGreaterThan,
                  },
                  {
                    displayKey: 'inRange',
                    displayName: 'Between',
                    predicate: this.dateRange,
                  },
                ],
                inRangeInclusive: true,
                suppressAndOrCondition: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
                comparator: this.dateFilter,
                browserDatePicker: true,
              },
            };
          }
        }
        if (data === 'createdAt') {
          if (this.dateColumns.indexOf(data) !== -1) {
            let settingsSort;
            if (user.settings && user.settings.ui) {
              settingsSort = user.settings.ui.sortedDate === 'createddate' ? 'desc' : '';
            }
            return {
              headerName: 'Application Created Date',
              field: data,
              filter: 'agDateColumnFilter',
              valueFormatter: this.dateFormatter,
              comparator: this.customComparator,
              sort: settingsSort,
              filterParams: {
                filterOptions: [
                  {
                    displayKey: 'equals',
                    displayName: 'On',
                    predicate: this.dateOn,
                  },
                  {
                    displayKey: 'lessThanOrEqual',
                    displayName: 'Before',
                    predicate: this.dateLessThan,
                  },
                  {
                    displayKey: 'greaterThanOrEqual',
                    displayName: 'After',
                    predicate: this.dateGreaterThan,
                  },
                  {
                    displayKey: 'inRange',
                    displayName: 'Between',
                    predicate: this.dateRange,
                  },
                ],
                inRangeInclusive: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
                comparator: this.dateFilter,
              },
            };
          }
        }
        if (data === 'endsAt') {
          if (this.dateColumns.indexOf(data) !== -1) {
            return {
              headerName: 'Application End Date',
              field: data,
              filter: 'agDateColumnFilter',
              valueFormatter: this.dateFormatter,
              filterParams: {
                filterOptions: [
                  {
                    displayKey: 'equals',
                    displayName: 'On',
                    predicate: this.dateOn,
                  },
                  {
                    displayKey: 'lessThanOrEqual',
                    displayName: 'Before',
                    predicate: this.dateLessThan,
                  },
                  {
                    displayKey: 'greaterThanOrEqual',
                    displayName: 'After',
                    predicate: this.dateGreaterThan,
                  },
                  {
                    displayKey: 'inRange',
                    displayName: 'Between',
                    predicate: this.dateRange,
                  },
                ],
                inRangeInclusive: true,
                suppressAndOrCondition: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
                comparator: this.dateFilter,
              },
            };
          }
        }

        if (data === 'accepteddate') {
          if (this.dateColumns.indexOf(data) !== -1) {
            let settingsSort;
            if (user.settings && user.settings.ui) {
              settingsSort = user.settings.ui.sortedDate === 'accepteddate' ? 'desc' : '';
            }
            return {
              headerName: 'Application Accepted Date',
              field: data,
              filter: 'agDateColumnFilter',
              sort: settingsSort,
              valueFormatter: this.dateFormatter,
              filterParams: {
                filterOptions: [
                  {
                    displayKey: 'equals',
                    displayName: 'On',
                    predicate: this.dateOn,
                  },
                  {
                    displayKey: 'lessThanOrEqual',
                    displayName: 'Before',
                    predicate: this.dateLessThan,
                  },
                  {
                    displayKey: 'greaterThanOrEqual',
                    displayName: 'After',
                    predicate: this.dateGreaterThan,
                  },
                  {
                    displayKey: 'inRange',
                    displayName: 'Between',
                    predicate: this.dateRange,
                  },
                ],
                inRangeInclusive: true,
                suppressAndOrCondition: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
                comparator: this.dateFilter,
              },
            };
          }
        }
        if (data === 'expires') {
          if (this.dateColumns.indexOf(data) !== -1) {
            return {
              headerName: 'Application Expire Date',
              field: data,
              filter: 'agDateColumnFilter',
              valueFormatter: this.dateFormatter,
              filterParams: {
                filterOptions: [
                  {
                    displayKey: 'equals',
                    displayName: 'On',
                    predicate: this.dateOn,
                  },
                  {
                    displayKey: 'lessThanOrEqual',
                    displayName: 'Before',
                    predicate: this.dateLessThan,
                  },
                  {
                    displayKey: 'greaterThanOrEqual',
                    displayName: 'After',
                    predicate: this.dateGreaterThan,
                  },
                  {
                    displayKey: 'inRange',
                    displayName: 'Between',
                    predicate: this.dateRange,
                  },
                ],
                inRangeInclusive: true,
                suppressAndOrCondition: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
                comparator: this.dateFilter,
              },
            };
          }
        }
        if (data === 'signedcontract') {
          if (this.dateColumns.indexOf(data) !== -1) {
            return {
              headerName: 'Signed Contract Date',
              field: data,
              filter: 'agDateColumnFilter',
              valueFormatter: this.dateFormatter,
              filterParams: {
                filterOptions: [
                  {
                    displayKey: 'equals',
                    displayName: 'On',
                    predicate: this.dateOn,
                  },
                  {
                    displayKey: 'lessThanOrEqual',
                    displayName: 'Before',
                    predicate: this.dateLessThan,
                  },
                  {
                    displayKey: 'greaterThanOrEqual',
                    displayName: 'After',
                    predicate: this.dateGreaterThan,
                  },
                  {
                    displayKey: 'inRange',
                    displayName: 'Between',
                    predicate: this.dateRange,
                  },
                ],
                inRangeInclusive: true,
                suppressAndOrCondition: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
                comparator: this.dateFilter,
              },
            };
          }
        }
        if (data === 'accountrcreated') {
          if (this.dateColumns.indexOf(data) !== -1) {
            return {
              headerName: 'Trainee Account Created',
              field: data,
              filter: 'agDateColumnFilter',
              valueFormatter: this.dateFormatter,
              filterParams: {
                filterOptions: [
                  {
                    displayKey: 'equals',
                    displayName: 'On',
                    predicate: this.dateOn,
                  },
                  {
                    displayKey: 'lessThanOrEqual',
                    displayName: 'Before',
                    predicate: this.dateLessThan,
                  },
                  {
                    displayKey: 'greaterThanOrEqual',
                    displayName: 'After',
                    predicate: this.dateGreaterThan,
                  },
                  {
                    displayKey: 'inRange',
                    displayName: 'Between',
                    predicate: this.dateRange,
                  },
                ],
                inRangeInclusive: true,
                suppressAndOrCondition: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
                comparator: this.dateFilter,
              },
            };
          }
        }
        if (data === 'updatedAt') {
          if (this.dateColumns.indexOf(data) !== -1) {
            let settingsSort;
            if (user.settings && user.settings.ui) {
              settingsSort = user.settings.ui.sortedDate === 'updatedate' ? 'desc' : '';
            }
            return {
              headerName: 'Application Last Updated Date',
              field: data,
              sort: settingsSort,
              filter: 'agDateColumnFilter',
              valueFormatter: this.dateFormatter,
              filterParams: {
                filterOptions: [
                  {
                    displayKey: 'equals',
                    displayName: 'On',
                    predicate: this.dateOn,
                  },
                  {
                    displayKey: 'lessThanOrEqual',
                    displayName: 'Before',
                    predicate: this.dateLessThan,
                  },
                  {
                    displayKey: 'greaterThanOrEqual',
                    displayName: 'After',
                    predicate: this.dateGreaterThan,
                  },
                  {
                    displayKey: 'inRange',
                    displayName: 'Between',
                    predicate: this.dateRange,
                  },
                ],
                inRangeInclusive: true,
                suppressAndOrCondition: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
                comparator: this.dateFilter,
              },
            };
          }
        }
        if (data === 'reserveddate') {
          return {
            headerName: 'Application Reserved Date',
            field: 'reserveddate',
            filter: 'agDateColumnFilter',
            enablePivot: true,
            enableRowGroup: true,
            valueFormatter: this.dateFormatter,
            filterParams: {
              filterOptions: [
                {
                  displayKey: 'equals',
                  displayName: 'On',
                  predicate: this.dateOn,
                },
                {
                  displayKey: 'lessThanOrEqual',
                  displayName: 'Before',
                  predicate: this.dateLessThan,
                },
                {
                  displayKey: 'greaterThanOrEqual',
                  displayName: 'After',
                  predicate: this.dateGreaterThan,
                },
                {
                  displayKey: 'inRange',
                  displayName: 'Between',
                  predicate: this.dateRange,
                },
              ],
              inRangeInclusive: true,
              suppressAndOrCondition: true,
              buttons: ['reset', 'apply'],
              closeOnApply: true,
              // provide comparator function
              comparator: this.dateFilter,
            },
          };
        }
        if (this.dateColumns.indexOf(data) !== -1) {
          return {
            headerName: data,
            field: data,
            filter: 'agDateColumnFilter',
            valueFormatter: this.dateFormatter,
            filterParams: {
              filterOptions: [
                {
                  displayKey: 'equals',
                  displayName: 'On',
                  predicate: this.dateOn,
                },
                {
                  displayKey: 'lessThanOrEqual',
                  displayName: 'Before',
                  predicate: this.dateLessThan,
                },
                {
                  displayKey: 'greaterThanOrEqual',
                  displayName: 'After',
                  predicate: this.dateGreaterThan,
                },
                {
                  displayKey: 'inRange',
                  displayName: 'Between',
                  predicate: this.dateRange,
                },
              ],
              inRangeInclusive: true,
              suppressAndOrCondition: true,
              buttons: ['reset', 'apply'],
              closeOnApply: true,
              comparator: this.dateFilter,
            },
          };
        } else {
          let columnName: string;

          switch (data) {
            case 'name':
              columnName = 'Trainee Name';
              break;
            case 'applicationid':
              columnName = 'Application ID';
              break;
            case 'specialty':
              columnName = 'Primary Specialty';
              break;
            case 'specialties':
              columnName = 'Additional and Sub Specialities';
              break;
            case 'city':
              columnName = 'Program City';
              break;
            case 'state':
              columnName = 'Program State';
              break;
            case 'startmonth':
              columnName = 'Application Start Month';
              break;
            case 'programname':
              columnName = 'Program Name';
              break;
            case 'programlocation':
              columnName = 'Program Location';
              break;
            case 'type':
              columnName = 'Type';
              break;
            case 'visitoremail':
              columnName = 'Trainee Email';
              break;
            case 'programaddress':
              columnName = 'Program Address';
              break;
            case 'reportingtime':
              columnName = 'Reporting Time';
              break;
            case 'visitorphone':
              columnName = 'Trainee Phone';
              break;
            case 'userid':
              columnName = 'Trainee ID';
              break;
            case 'programid':
              columnName = 'Program ID';
              break;
            case 'coordinator':
              columnName = 'Coordinator';
              break;
            case 'hasvisa':
              columnName = 'Has Visa';
              break;
            case 'preacceptance':
              columnName = 'Pre-acceptance';
              break;
            case 'coach':
              columnName = 'Coach';
              break;
            case 'madedeposit':
              columnName = 'Made Deposit';
              break;
            case 'fieldofstudy':
              columnName = 'Trainee Field of Study';
              break;
            case 'madepayment':
              columnName = 'Made Payment';
              break;
            case 'housingtype':
              columnName = 'Housing Type';
              break;
            case 'housingaddress':
              columnName = 'Housing Address';
              break;
            case 'enrollable':
              columnName = 'Enrollable';
              break;
            case 'visitorevaluation':
              columnName = 'Trainee Evaluation';
              break;
            case 'hostpaid':
              columnName = 'Program Paid';
              break;
            case 'country':
              columnName = 'Trainee Country';
              break;
            case 'countryofbirth':
              columnName = 'Trainee Country of Birth';
              break;
            case 'hostemail':
              columnName = 'Host Email';
              break;
            case 'hostname':
              columnName = 'Host Name';
              break;
            case 'hostphone':
              columnName = 'Host Phone';
              break;
            case 'hostmobilephone':
              columnName = 'Host Mobile Phone';
              break;
            case 'hostclinicphone':
              columnName = 'Host Clinic Phone';
              break;
            case 'hostclinicphoneext':
              columnName = 'Host Clinic Phone Ext';
              break;
            case 'hostadminname':
              columnName = 'Host Admin Name';
              break;
            case 'hostadminemail':
              columnName = 'Host Admin Email';
              break;
            case 'hostadminphone':
              columnName = 'Host Admin Phone';
              break;
            case 'hostadminphoneext':
              columnName = 'Host Admin Phone Ext';
              break;
            case 'hashousing':
              columnName = 'Has Housing';
              break;
            case 'startyear':
              columnName = 'Application Start Year';
              break;
            default:
              columnName = data;
              break;
          }

          return this.defineTextColumn(data, columnName);
        }
      })
      .map((last: any) => {
        if (user.rules && user.rules.permissions.includes('hospitaladministrator') && this.hospitalAdminDashBoardColumns.indexOf(last.field) === -1) {
          last.hide = true;
          return last;
        } else if (
          user.type === 'Hosting' &&
          user.rules &&
          !user.rules.permissions.includes('hospitaladministrator') &&
          this.hostDashBoardColumns.indexOf(last.field) === -1
        ) {
          last.hide = true;
          return last;
        }
        return last;
      });

    columns.push({
      headerName: '',
      resizable: false,
      minWidth: 47,
      maxWidth: 47,
      pinned: 'left',
      editable: false,
      suppressMenu: true,
      filter: null,
      hide: false,
      suppressColumnsToolPanel: true,
      colId: 'selection',
      sortable: false,
      suppressMovable: true,
      lockPinned: true,
      lockVisible: true,
      cellRendererParams: { page: 'dashboard', user },
      cellRenderer: GridSelectionRenderer,
    });

    return of(columns);
  }
  //*********************************************************************************************** */
  //*********************************************************************************************** */
  //*********************************************************************************************** */

  // AMO Employee Application
  AmoApplicationsGridColumns(dataObj: any, user: any) {
    const columns = [...dataObj]
      .filter((col: string) => {
        if (
          [
            'User',
            'Program',
            'Application',
            'ApplicationChain',
            'physicianemail',
            'physician',
            'Coupons',
            'address',
            'city',
            'hasrequestedbookingreview',
            'image',
            'name',
            'paymenttype',
            'programid',
            'programlocation',
            'specialty',
            'state',
            'useremail',
            'userid',
            'visastatus',
            'visitorstatus',
            'fullname',
            'timestamp',
            'sortId',
            'chainid',
            'hostinstitutionname',
            'applicationprogramname',
            'availabilityid',
            'studentcoordinator',
            'visameetingdate',
            'tieBreakerId',
            'OrderProgramRotation',
          ].includes(col)
        ) {
          return false;
        } else {
          return true;
        }
      })
      .filter(allow => {
        if (user.rules && user.rules.permissions && user.rules.permissions.includes('program:hideprices') && this.priceHide.indexOf(allow) !== -1) {
          return false;
        } else {
          return true;
        }
      })
      .map((data: string) => {
        if (data === 'states' || data === 'applicationstatus') {
          return {
            ...this.applicationStatusColumnFilter(data, 'Application Status'),
            cellClass: this.statusStyleChange,
          };
        }
        if (data === 'applicationpostponementreason') {
          return {
            ...this.defineTextColumn(data, 'Application Postponement Reason'),
          };
        }
        if (data === 'applicationpostponementdate') {
          return {
            ...this.defineDateColumn(data, 'Application Postponement Date'),
          };
        }
        if (data === 'fullname') {
          return {
            ...this.defineTextColumn('ApplicationTrainee.name', 'Trainee Name'),
            valueGetter: params => {
              if (params.data && params.data.User) {
                return params.data.User.name;
              } else {
                return '';
              }
            },
          };
        }

        if (data === 'madedeposit') {
          return { ...this.booleanColumnFilter(data, 'Made Deposit') };
        }

        if (data === 'madepayment') {
          return { ...this.booleanColumnFilter(data, 'Made Payment') };
        }

        if (data === 'visitorevaluation') {
          return { ...this.booleanColumnFilter(data, 'Traine Evaluation') };
        }
        if (data === 'hostevaluation') {
          return { ...this.booleanColumnFilter(data, 'Host Evaluation') };
        }

        if (data === 'malpracticepaid') {
          return { ...this.booleanColumnFilter(data, 'Malpractice Paid') };
        }

        if (data === 'immigrationservicepaid') {
          return {
            ...this.booleanColumnFilter(data, 'Immigration Service Paid'),
          };
        }

        if (data === 'enrollable') {
          return { ...this.booleanColumnFilter(data, 'Enable Enrollment') };
        }

        if (data === 'hashousing') {
          return { ...this.booleanColumnFilter(data, 'Has Housing') };
        }

        if (data === 'enableenrollment') {
          return { ...this.booleanColumnFilter(data, 'Enable Enrollment') };
        }

        if (data === 'stipendpaid') {
          return { ...this.booleanColumnFilter(data, 'Stipend Paid') };
        }

        if (data === 'hasrequestedinvitation') {
          return {
            ...this.booleanColumnFilter(data, 'Has Requested Invitation'),
          };
        }
        if (data === 'nolatefee') {
          return { ...this.booleanColumnFilter(data, 'Waive Late Fee') };
        }
        if (data === 'preacceptancereviewable') {
          return {
            ...this.booleanColumnFilter(data, 'Pre Acceptance Reviewable'),
          };
        }
        if (data === 'switchprotectionpaid') {
          return {
            ...this.booleanColumnFilter(data, 'Switch Protection Paid'),
          };
        }

        if (data === 'price' || data === 'applicationprice') {
          return {
            ...this.defineNumberColumn(data, 'Application Price'),
          };
        }
        if (data === 'stipendcost') {
          return {
            ...this.defineNumberColumn(data, 'Application Stipend Cost'),
          };
        }

        if (data === 'deposit' || data === 'applicationdepositamount') {
          return {
            ...this.defineNumberColumn(data, 'Application Deposit Amount'),
          };
        }
        if (data === 'latefeeamount') {
          return {
            ...this.defineNumberColumn(data, 'Late Fee Amount'),
          };
        }

        if (data === 'checkoutprice') {
          return {
            ...this.defineNumberColumn(data, 'Checkout Price'),
          };
        }

        if (data === 'stripetransfers') {
          return {
            ...this.defineTextColumn(data, 'Stripe Stipend ID'),
            valueGetter: params => {
              if (params.data && params.data.stripetransfers) {
                return JSON.stringify(params.data.stripetransfers);
              } else {
                return '';
              }
            },
          };
        }

        if (data === 'hasvisa') {
          return {
            ...this.booleanColumnFilter('User.hasvisa', 'Trainee Has Visa'),
          };
        }
        if (data === 'predepartureorientation') {
          return {
            ...this.defineTextColumn('predepartureorientation', 'Pre-Departure Orientation'),
            valueGetter: params => {
              if (params.data && params.data.predepartureorientation) {
                let predepart = params.data.predepartureorientation;
                if (typeof predepart === 'string') {
                  predepart = JSON.parse(predepart);
                }
                return `${predepart.url}`;
              } else {
                return '';
              }
            },
          };
        }

        if (data === 'coupons') {
          return {
            headerName: 'Coupon Name',
            field: 'ApplicationCoupon.code',
            sortable: false,
            filter: 'agTextColumnFilter',
            trimInput: true,
            valueGetter: params => {
              if (params.data && params.data.coupons) {
                let checkedCoupons = params.data.coupons;
                if (typeof checkedCoupons === 'string') {
                  checkedCoupons = JSON.parse(checkedCoupons);
                }

                if (checkedCoupons.length > 0) {
                  let couponList = checkedCoupons.map(c => {
                    if (c.code) {
                      return c.code;
                    }
                  });
                  const textcoupon = couponList.join(',');
                  return textcoupon;
                }
                return '';
              }
              return '';
            },
          };
        }
        if (data === 'couponname') {
          return {
            headerName: 'Coupon Name',
            field: 'couponname',
            sortable: true,
            filter: 'agTextColumnFilter',
            trimInput: true,
            valueGetter: params => {
              if (params.data && params.data.couponname) {
                let checkedCoupons = params.data.couponname;
                if (typeof checkedCoupons === 'string') {
                  checkedCoupons = JSON.parse(checkedCoupons);
                }

                if (checkedCoupons.length > 0) {
                  let couponList = checkedCoupons.map(c => {
                    if (c.code) {
                      return c.code;
                    }
                    if (c.id) {
                      return c.id;
                    }
                  });
                  const textcoupon = couponList.join(',');
                  return textcoupon;
                }
                return '';
              }
              return '';
            },
          };
        }

        if (data === 'couponid') {
          return {
            headerName: 'Coupon ID',
            field: 'couponid',
            sortable: true,
            filter: 'agTextColumnFilter',
            trimInput: true,
            valueGetter: params => {
              if (params.data && params.data.couponname) {
                //console.log(params.data);
                let checkedCoupons = params.data.couponname;
                if (typeof checkedCoupons === 'string') {
                  checkedCoupons = JSON.parse(checkedCoupons);
                }
                //console.log("checkedCoupons", checkedCoupons);

                if (checkedCoupons.length > 0) {
                  let couponIdList = checkedCoupons.map(c => {
                    if (c.id) {
                      return c.id;
                    }
                  });
                  const textCouponIds = couponIdList.join(',');
                  return textCouponIds;
                }
                return '';
              }
              return '';
            },
          };
        }

        if (data === 'applicationhubspotdealid') {
          return {
            ...this.defineNumberColumn(data, 'Application Hubspot Deal ID'),
          };
        }

        if (GridFields.application.amoemployee[data]) {
          return {
            ...this.defineTextColumn(data, GridFields.application.amoemployee[data].header),
          };
        }
        if (data === 'startdate') {
          if (this.dateColumns.indexOf(data) !== -1) {
            let settingsSort;
            if (user.settings && user.settings.ui && user.settings.ui.sortedDate) {
              if (isNil(user.settings.ui.sortedDate)) {
                settingsSort = 'desc';
              } else if (user.settings.ui.sortedDate !== 'startdate') {
                settingsSort = null;
              } else {
                settingsSort = 'desc';
              }
            } else {
              settingsSort = 'desc';
            }
            return this.defineDateColumn(data, 'Start Date');
          }
        }
        if (data === 'createdAt' || data === 'applicationcreateddate') {
          if (this.dateColumns.indexOf(data) !== -1) {
            let settingsSort;
            if (user.settings && user.settings.ui && user.settings.ui.sortedDate === 'createddate') {
              settingsSort = user.settings.ui.sortedDate === 'createddate' ? 'desc' : '';
            }
            return this.defineDateColumn(data, 'Application Created Date');
          }
        }
        if (data === 'enddate') {
          if (this.dateColumns.indexOf(data) !== -1) {
            return this.defineDateColumn(data, 'End Date');
          }
        }

        if (data === 'accepteddate') {
          if (this.dateColumns.indexOf(data) !== -1) {
            let settingsSort;
            if (user.settings && user.settings.ui && user.settings.ui.sortedDate === 'accepteddate') {
              settingsSort = user.settings.ui.sortedDate === 'accepteddate' ? 'desc' : null;
            } else {
              settingsSort = null;
            }
            return this.defineDateColumn(data, 'Accepted Date');
          }
        }
        if (data === 'expires' || data === 'expiredate') {
          if (this.dateColumns.indexOf(data) !== -1) {
            return this.defineDateColumn(data, 'Expire Date');
          }
        }
        if (data === 'reserveddate') {
          if (this.dateColumns.indexOf(data) !== -1) {
            return this.defineDateColumn(data, 'Reserved Date');
          }
        }
        if (data === 'enrolleddate') {
          if (this.dateColumns.indexOf(data) !== -1) {
            return this.defineDateColumn(data, 'Enrolled Date');
          }
        }
        if (data === 'postponedtoreserveddate') {
          if (this.dateColumns.indexOf(data) !== -1) {
            return this.defineDateColumn(data, 'Postpone to Reserved Date');
          }
        }
        if (data === 'month') {
          return this.monthColumnFilter(data, 'Month');
        }
        if (data === 'startyear') {
          return this.applicationYearFilterColumnFilter(data, 'Start Year');
        }
        if (data === 'applicationprogramtype') {
          return this.defineTextColumn(data, 'Application Program Type');
        }
        if (data === 'waivelatefee') {
          return {
            ...this.booleanColumnFilter(data, 'Waive Late Fee'),
          };
        }
        if (data === 'daysuntillate') {
          // need a custom valuegetter here using deadline days so it is a virutal field
          return {
            ...this.defineNumberColumn(data, 'Days Until Late'),
          };
        }
        if (data === 'referredhostpaid') {
          return this.booleanColumnFilter(data, 'Referred Host Paid');
        }
        if (data === 'signedcontractdate') {
          if (this.dateColumns.indexOf(data) !== -1) {
            return this.defineDateColumn(data, 'Signed Contract Date');
          }
        }
        if (data === 'updatedAt' || data === 'applicationupdateddate') {
          if (this.dateColumns.indexOf(data) !== -1) {
            let settingsSort;
            if (user.settings && user.settings.ui && user.settings.ui.sortedDate === 'updatedate') {
              settingsSort = user.settings.ui.sortedDate === 'updatedate' ? 'desc' : '';
            }
            return this.defineDateColumn(data, 'Application Updated Date');
          }
        }
        if (data === 'migrationdocumentdate') {
          if (this.dateColumns.indexOf(data) !== -1) {
            let settingsSort;
            if (user.settings && user.settings.ui && user.settings.ui.sortedDate === 'migrationdocumentdate') {
              settingsSort = user.settings.ui.sortedDate === 'updatedate' ? 'desc' : '';
            }
            return this.defineDateColumn(data, 'Migration Document Date');
          }
        }
        if (data === 'applicationordertype') {
          return {
            ...this.defineTextColumn(data, 'Application Order Type'),
          };
        }
        if (data === 'nursing_hours') {
          return {
            ...this.defineNumberColumn(data, 'Application Nursing Hours'),
          };
        }
        if (data === 'hourlyprice') {
          return {
            ...this.defineNumberColumn(data, 'Application Hourly Price'),
          };
        }
        if (this.dateColumns.indexOf(data) !== -1) {
          return this.defineDateColumn(data, data);
        } else {
          return this.defineTextColumn(data, data);
        }
      })
      .map((last: any) => {
        if (user.type === 'Hosting' && this.hostApplicationColumns.indexOf(last.field) === -1) {
          last.hide = true;
          return last;
        }
        return last;
      });

    columns.push(
      ...[
        {
          ...this.defineTextColumn('Program.weekly_hours', 'Program Weekly Hours'),
          valueGetter: params => (params.data && params.data.Program ? params.data.Program.weekly_hours : ''),
        },
        {
          ...this.defineNumberColumn('Program.hourlyprice', 'Program Hourly Price'),
          valueGetter: params => (params.data && params.data.Program ? params.data.Program.hourlyprice : ''),
        },
        {
          ...this.defineNumberColumn('Program.stipendcost', 'Program Stipend Amount'),
          valueGetter: params => (params.data && params.data.Program ? params.data.Program.stipendcost : ''),
        },
        {
          ...this.defineTextColumn('Program.specialties', 'Program Additional Specialties'),
          valueGetter: params => (params.data && params.data.Program ? params.data.Program.specialties : ''),
        },
        // {
        //   ...this.defineTextColumn("Program.type", "Application Program Type"),
        //   valueGetter: params =>
        //     params.data && params.data.Program ? params.data.Program.type : ""
        // },
        {
          ...this.defineTextColumn('User.fieldofstudy', 'Trainee Field of Study'),
          valueGetter: params => (params.data && params.data.User ? params.data.User.fieldofstudy : ''),
        },
        {
          ...this.defineTextColumn('User.passportissuecountry', 'Trainee Passport Country'),
          valueGetter: params => (params.data && params.data.User ? params.data.User.passportissuecountry : ''),
        },
        {
          ...this.defineTextColumn('User.name', 'Trainee Name'),
          valueGetter: params => (params.data && params.data.User ? params.data.User.name : ''),
        },
        {
          ...this.defineTextColumn('User.status', 'Trainee Status'),
          valueGetter: params => (params.data && params.data.User ? params.data.User.status : ''),
        },
        {
          ...this.defineTextColumn('User.email', 'Trainee Email'),
          valueGetter: params => (params.data && params.data.User ? params.data.User.email : ''),
        },
        {
          ...this.defineTextColumn('User.phone', 'Trainee Phone'),
          valueGetter: params => (params.data && params.data.User ? params.data.User.phone : ''),
        },
        {
          ...this.defineTextColumn('User.string', 'Trainee Notes'),
          valueGetter: params => (params.data && params.data.User ? params.data.User.string : ''),
        },
        {
          ...this.defineTextColumn('User.country', 'Trainee Country'),
          valueGetter: params => (params.data && params.data.User ? params.data.User.country : ''),
        },
        {
          ...this.defineTextColumn('User.countryofbirth', 'Trainee Country of Birth'),
          valueGetter: params => (params.data && params.data.User ? params.data.User.countryofbirth : ''),
        },
        {
          ...this.defineTextColumn('User.university', 'Trainee University'),
          valueGetter: params => (params.data && params.data.User ? params.data.User.university : ''),
        },
        {
          ...this.defineDateColumn('User.cvuploadat', 'Trainee CV Upload Date'),
          valueGetter: params => (params.data && params.data.User ? params.data.User.cvuploadat : ''),
        },
        {
          ...this.defineDateColumn('User.createdAt', 'Trainee Created Date'),
          valueGetter: params => (params.data && params.data.User ? params.data.User.createdAt : ''),
        },

        // {
        //   ...this.defineTextColumn("User.hasvisa", "Has Visa"),
        //   valueGetter: params =>
        //     params.data && params.data.User ? params.data.User.hasvisa : ""
        // },
        {
          ...this.defineTextColumn('User.utm_signup_source', 'UTM Signup Source'),
          valueGetter: params => (params.data && params.data.User ? params.data.User.utm_signup_source : ''),
        },
        {
          ...this.defineTextColumn('User.passportnumber', 'Trainee Passport Number'),
          valueGetter: params => (params.data && params.data.User ? params.data.User.passportnumber : ''),
        },
        {
          ...this.defineNumberColumn('User.hubspotcontactid', 'HubSpot Contact ID'),
          valueGetter: params => (params.data && params.data.User ? params.data.User.hubspotcontactid : ''),
        },
        {
          ...this.defineTextColumn('User.id', 'Trainee ID'),
          valueGetter: params => (params.data && params.data.User ? params.data.User.id : ''),
        },
        {
          ...this.defineTextColumn('User.Department.account', 'Trainee Institution Name'),
          valueGetter: params => (params.data && params.data.User && params.data.User.Department ? params.data.User.Department.account : ''),
        },
        {
          ...this.defineDateColumn('User.visameetingdate', 'Visa Meeting Date'),
          valueGetter: params => (params.data && params.data.User ? params.data.User.visameetingdate : ''),
        },
        {
          ...this.booleanColumnFilter('Program.preapprovalrequired', 'Pre-Approval Required'),
          valueGetter: params => (params.data && params.data.Program ? params.data.Program.preapprovalrequired : ''),
        },
        {
          ...this.defineTextColumn('Program.name', 'Program Name'),
          valueGetter: params => (params.data && params.data.Program ? params.data.Program.name : ''),
        },
        {
          ...this.defineTextColumn('Program.city', 'Program City'),
          valueGetter: params => (params.data && params.data.Program ? params.data.Program.city : ''),
        },
        {
          ...this.usStatesColumnFilter('Program.state', 'Program State'),
          valueGetter: params => (params.data && params.data.Program ? params.data.Program.state : ''),
        },
        {
          ...this.defineTextColumn('Program.programlocation', 'Program Location'),
          valueGetter: params => (params.data && params.data.Program ? params.data.Program.programlocation : ''),
        },
        {
          ...this.defineTextColumn('Program.specialty', 'Program Specialty'),
          valueGetter: params => (params.data && params.data.Program ? params.data.Program.specialty : ''),
        },
        {
          ...this.defineTextColumn('Program.address', 'Program Address'),
          valueGetter: params => (params.data && params.data.Program ? params.data.Program.address : ''),
        },
        {
          ...this.defineTextColumn('Program.Host.name', 'Host Name'),
          valueGetter: params => (params.data && params.data.Program && params.data.Program.Host ? params.data.Program.Host.name : ''),
        },
        {
          ...this.defineTextColumn('Program.Host.email', 'Host Email'),
          valueGetter: params => (params.data && params.data.Program && params.data.Program.Host ? params.data.Program.Host.email : ''),
        },
        {
          ...this.defineNumberColumn('Program.id', 'Program ID'),
          valueGetter: params => (params.data && params.data.Program ? params.data.Program.id : ''),
        },
        {
          ...this.defineTextColumn('Program.Host.cellphone', 'Host Mobile Phone'),
          valueGetter: params => (params.data && params.data.Program && params.data.Program.Host ? params.data.Program.Host.cellphone : ''),
        },
        {
          ...this.defineTextColumn('Program.Host.clinicphone', 'Host Clinic Phone'),
          valueGetter: params => (params.data && params.data.Program && params.data.Program.Host ? params.data.Program.Host.clinicphone : ''),
        },
        {
          ...this.defineTextColumn('Program.Host.contactphone', 'Host Admin Phone'),
          valueGetter: params => (params.data && params.data.Program && params.data.Program.Host ? params.data.Program.Host.contactphone : ''),
        },
        {
          ...this.defineTextColumn('Program.Host.contactemail', 'Host Admin Email'),
          valueGetter: params => (params.data && params.data.Program && params.data.Program.Host ? params.data.Program.Host.contactemail : ''),
        },
        {
          ...this.defineTextColumn('Program.Host.Department.account', 'Host Institution Name'),
          valueGetter: params => {
            if (params.data && params.data.Program && params.data.Program.Host && params.data.Program.Host.Department) {
              return params.data.Program.Host.Department.account;
            } else {
              return '';
            }
          },
        },
        {
          ...this.defineTextColumn('Program.Host.Department.stripe_connect_status', 'Stripe Connect Status'),
          valueGetter: params => {
            if (params.data && params.data.Program && params.data.Program.Host && params.data.Program.Host.Department) {
              return params.data.Program.Host.Department.stripe_connect_status;
            } else {
              return '';
            }
          },
        },
        {
          ...this.defineTextColumn('OrderProgramRotation.OrderProgram.Order.id', 'Order ID'),
          valueGetter: params => {
            if (
              params.data &&
              params.data.OrderProgramRotation &&
              params.data.OrderProgramRotation.OrderProgram &&
              params.data.OrderProgramRotation.OrderProgram.Order
            ) {
              return params.data.OrderProgramRotation.OrderProgram.Order.id;
            } else {
              return '';
            }
          },
        },
        {
          ...this.defineTextColumn('OrderProgramRotation.invoiceid', 'Invoice ID'),
          valueGetter: params => {
            if (params.data && params.data.OrderProgramRotation) {
              return params.data.OrderProgramRotation.invoiceid;
            } else {
              return '';
            }
          },
        },
      ],
    );

    columns.push({
      headerName: '',
      resizable: false,
      minWidth: 47,
      maxWidth: 47,
      pinned: 'left',
      editable: false,
      suppressMenu: true,
      filter: null,
      hide: false,
      suppressColumnsToolPanel: true,
      colId: 'link',
      sortable: false,
      suppressMovable: true,
      lockPinned: true,
      lockVisible: true,
      cellRendererParams: { page: 'amoApplication', user },
      cellRenderer: GridSelectionRenderer,
    });
    //
    columns.push({
      headerName: '',
      resizable: false,
      minWidth: 47,
      maxWidth: 47,
      pinned: 'left',
      checkboxSelection: true,
      editable: false,
      suppressMenu: true,
      filter: null,
      hide: true,
      suppressColumnsToolPanel: true,
      colId: 'selection',
      sortable: false,
      suppressMovable: true,
      lockPinned: true,
      lockVisible: true,
    });
    return columns;
  }

  // AMO Employee Application Chains
  AmoApplicationChainsGridColumns(dataObj: any, user: any) {
    const columns = [...dataObj]
      .filter((col: any) => {
        if (['Applications', 'metadata', 'deletedAt'].includes(col)) {
          return false;
        } else {
          return true;
        }
      })
      .flatMap((data: string) => {
        if (data === 'id') {
          return [
            {
              headerName: 'Application Chain Id',
              field: data,
              filter: 'agTextColumnFilter',
            },
          ];
        }
        if (data === 'userid') {
          return [
            {
              headerName: 'Trainee ID',
              field: data,
              filter: 'agTextColumnFilter',
            },
          ];
        }
        if (data === 'User') {
          return [
            {
              headerName: 'Trainee Field of Study',
              field: 'User.fieldofstudy',
              valueGetter: params => {
                if (params.data && params.data.User && params.data.User.Fieldofstudies) {
                  return params.data.User.Fieldofstudies.name;
                } else {
                  return '';
                }
              },
              filter: 'agTextColumnFilter',
            },
            {
              headerName: 'Trainee Academic Status',
              field: 'User.status',
              valueGetter: params => {
                if (params.data && params.data.User) {
                  return params.data.User.status;
                } else {
                  return '';
                }
              },
              filter: 'agTextColumnFilter',
            },
            {
              headerName: 'Trainee Name',
              field: 'User.name',
              valueGetter: params => {
                if (params.data && params.data.User) {
                  return params.data.User.name;
                } else {
                  return '';
                }
              },
              filter: 'agTextColumnFilter',
            },
            {
              headerName: 'Trainee Email',
              field: 'User.email',
              valueGetter: params => {
                if (params.data && params.data.User) {
                  return params.data.User.email;
                } else {
                  return '';
                }
              },
              filter: 'agTextColumnFilter',
            },
            {
              headerName: 'Trainee Phone',
              field: 'User.phone',
              valueGetter: params => {
                if (params.data && params.data.User) {
                  return params.data.User.phone;
                } else {
                  return '';
                }
              },
              filter: 'agTextColumnFilter',
            },
          ];
        }
        if (data === 'createdAt') {
          if (this.dateColumns.indexOf(data) !== -1) {
            let settingsSort;
            if (user.settings && user.settings.ui && user.settings.ui.sortedDate === 'createddate') {
              settingsSort = user.settings.ui.sortedDate === 'createddate' ? 'desc' : '';
            }
            return {
              headerName: 'Chain Created Date',
              field: data,
              filter: 'agDateColumnFilter',
              valueFormatter: this.dateFormatter,
              sort: settingsSort,
              comparator: this.customComparator,
              sortable: true,
              filterParams: {
                filterOptions: [
                  {
                    displayKey: 'equals',
                    displayName: 'On',
                    predicate: this.dateOn,
                  },
                  {
                    displayKey: 'lessThanOrEqual',
                    displayName: 'Before',
                    predicate: this.dateLessThan,
                  },
                  {
                    displayKey: 'greaterThanOrEqual',
                    displayName: 'After',
                    predicate: this.dateGreaterThan,
                  },
                  {
                    displayKey: 'inRange',
                    displayName: 'Between',
                    predicate: this.dateRange,
                  },
                ],
                inRangeInclusive: true,
                suppressAndOrCondition: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
                // provide comparator function
                comparator: this.dateFilter,
              },
            };
          }
        }
        if (data === 'updatedAt') {
          if (this.dateColumns.indexOf(data) !== -1) {
            let settingsSort;
            if (user.settings && user.settings.ui && user.settings.ui.sortedDate === 'updatedate') {
              settingsSort = user.settings.ui.sortedDate === 'updatedate' ? 'desc' : '';
            }
            return {
              headerName: 'Chain Updated Date',
              field: data,
              sortable: true,
              sort: settingsSort,
              filter: 'agDateColumnFilter',
              valueFormatter: this.dateFormatter,
              filterParams: {
                filterOptions: [
                  {
                    displayKey: 'equals',
                    displayName: 'On',
                    predicate: this.dateOn,
                  },
                  {
                    displayKey: 'lessThanOrEqual',
                    displayName: 'Before',
                    predicate: this.dateLessThan,
                  },
                  {
                    displayKey: 'greaterThanOrEqual',
                    displayName: 'After',
                    predicate: this.dateGreaterThan,
                  },
                  {
                    displayKey: 'inRange',
                    displayName: 'Between',
                    predicate: this.dateRange,
                  },
                ],
                inRangeInclusive: true,
                suppressAndOrCondition: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
                // provide comparator function
                comparator: this.dateFilter,
              },
            };
          }
        }
        if (this.dateColumns.indexOf(data) !== -1) {
          return {
            headerName: data,
            field: data,
            filter: 'agDateColumnFilter',
            sortable: true,
            valueFormatter: this.dateFormatter,
            filterParams: {
              filterOptions: [
                {
                  displayKey: 'equals',
                  displayName: 'On',
                  predicate: this.dateOn,
                },
                {
                  displayKey: 'lessThanOrEqual',
                  displayName: 'Before',
                  predicate: this.dateLessThan,
                },
                {
                  displayKey: 'greaterThanOrEqual',
                  displayName: 'After',
                  predicate: this.dateGreaterThan,
                },
                {
                  displayKey: 'inRange',
                  displayName: 'Between',
                  predicate: this.dateRange,
                },
              ],
              inRangeInclusive: true,
              suppressAndOrCondition: true,
              buttons: ['reset', 'apply'],
              closeOnApply: true,
              // provide comparator function
              comparator: this.dateFilter,
            },
          };
        } else {
          return {
            ...this.defineTextColumn(data, data),
          };
        }
      })
      .map((last: any) => {
        if (user.type === 'Hosting' && this.hostApplicationColumns.indexOf(last.field) === -1) {
          last.hide = true;
          return last;
        }
        return last;
      });

    columns.push({
      headerName: '',
      resizable: false,
      minWidth: 47,
      maxWidth: 47,
      pinned: 'left',
      editable: false,
      suppressMenu: true,
      filter: null,
      hide: false,
      suppressColumnsToolPanel: true,
      colId: 'link',
      sortable: false,
      suppressMovable: true,
      lockPinned: true,
      lockVisible: true,
      cellRendererParams: { page: 'applicationChain', user },
      cellRenderer: GridSelectionRenderer,
    });
    //
    columns.push({
      headerName: '',
      resizable: false,
      minWidth: 47,
      maxWidth: 47,
      pinned: 'left',
      checkboxSelection: true,
      editable: false,
      suppressMenu: true,
      filter: null,
      hide: true,
      suppressColumnsToolPanel: true,
      colId: 'selection',
      sortable: false,
      suppressMovable: true,
      lockPinned: true,
      lockVisible: true,

      // headerName: "",
      // resizable: false,
      // minWidth: 47,
      // maxWidth: 47,
      // pinned: "left",
      // checkboxSelection: true,
      // editable: false,
      // hide: false,
      // suppressMenu: true,
      // filter: null,
      // suppressColumnsToolPanel: true,
      // sortable: false,
      // suppressMovable: true,
      // lockPosition: true,
      // colId: "selection",
      // cellRendererParams: { page: "amoApplication", user },
      // cellRenderer: GridSelectionRenderer
    });

    // {
    //   headerName: "",
    //   resizable: false,
    //   minWidth: 47,
    //   maxWidth: 47,
    //   pinned: "left",
    //   checkboxSelection: true,
    //   editable: false,
    //   suppressMenu: true,
    //   filter: null,
    //   hide: true,
    //   suppressColumnsToolPanel: true,
    //   colId: "selection",
    //   sortable: false,
    //   suppressMovable: true,
    //   lockPosition: true
    // },
    // {
    //   headerName: "",
    //   resizable: false,
    //   minWidth: 47,
    //   maxWidth: 47,
    //   pinned: "left",
    //   editable: false,
    //   suppressMenu: true,
    //   filter: null,
    //   hide: false,
    //   suppressColumnsToolPanel: true,
    //   colId: "link",
    //   sortable: false,
    //   suppressMovable: true,
    //   lockPosition: true,
    //   cellRendererParams: { page: "trainee" },
    //   cellRenderer: GridSelectionRenderer
    // },

    return of(columns);
  }

  // AMO Employee Application
  programApplicantsGridColumns(dataObj: any, user: any) {
    const columns = [...dataObj]
      .filter((col: string) => {
        if (
          ['User', 'Program', 'Application', 'ApplicationChain', 'physicianemail', 'physician', 'Coupons', 'specialty', 'userid', 'programid'].includes(col)
        ) {
          return false;
        } else {
          return true;
        }
      })
      .map((data: string) => {
        if (data === 'states') {
          return {
            headerName: 'Application Status',
            field: data,
            filter: true,
            comparator: this.customComparator,
            cellClass: this.statusStyleChange,
          };
        }

        if (data === 'stripetransfers') {
          return {
            ...this.defineTextColumn(data, 'Stripe Stipend ID'),
            valueGetter: params => {
              if (params.data && params.data.stripetransfers) {
                return JSON.stringify(params.data.stripetransfers);
              } else {
                return '';
              }
            },
          };
        }

        if (data === 'hasvisa') {
          return {
            ...this.defineTextColumn('User.hasvisa', 'Trainee Has Visa'),
          };
        }

        if (data === 'predepartureorientation') {
          return {
            ...this.defineTextColumn('predepartureorientation', 'Pre Departure Orientation'),
            valueGetter: params => {
              if (params.data && params.data.predepartureorientation) {
                let predepart = params.data.predepartureorientation;
                if (typeof predepart === 'string') {
                  predepart = JSON.parse(predepart);
                }
                return `${predepart.url}`;
              } else {
                return '';
              }
            },
          };
        }

        if (data === 'coupons') {
          return {
            headerName: 'Coupon Name',
            field: 'coupons',
            sortable: false,
            filter: 'agTextColumnFilter',
            valueGetter: params => {
              if (params.data && params.data.coupons) {
                let checkedCoupons = params.data.coupons;
                if (typeof checkedCoupons === 'string') {
                  checkedCoupons = JSON.parse(checkedCoupons);
                }

                if (checkedCoupons.length > 0) {
                  let couponList = checkedCoupons.map(c => {
                    if (c.code) {
                      return c.code;
                    }
                  });
                  const textcoupon = couponList.join(',');
                  return textcoupon;
                }
                return '';
              }
              return '';
            },
          };
        }
        if (data === 'month') {
          return this.defineTextColumn(data, 'Month');
        }
        if (data === 'type') {
          return this.defineTextColumn(data, 'Application Program Type');
        }
        if (data === 'referredhostpaid') {
          return this.defineTextColumn(data, 'Referred Host Paid');
        }
        if (data === 'signedcontract') {
          if (this.dateColumns.indexOf(data) !== -1) {
            return this.defineDateColumn(data, 'Signed Contract Date');
          }
        }

        if (GridFields.application.amoemployee[data]) {
          return this.defineTextColumn(data, GridFields.application.amoemployee[data].header);
        }
        if (data === 'startdate') {
          if (this.dateColumns.indexOf(data) !== -1) {
            let settingsSort;
            if (user.settings && user.settings.ui && user.settings.ui.sortedDate) {
              if (isNil(user.settings.ui.sortedDate)) {
                settingsSort = 'desc';
              } else if (user.settings.ui.sortedDate !== 'startdate') {
                settingsSort = null;
              } else {
                settingsSort = 'desc';
              }
            } else {
              settingsSort = 'desc';
            }
            return this.defineDateColumn(data, 'Start Date');
          }
        }
        if (data === 'createdAt') {
          if (this.dateColumns.indexOf(data) !== -1) {
            let settingsSort;
            if (user.settings && user.settings.ui && user.settings.ui.sortedDate === 'createddate') {
              settingsSort = user.settings.ui.sortedDate === 'createddate' ? 'desc' : '';
            }
            return this.defineDateColumn(data, 'Application Created Date');
          }
        }
        if (data === 'enddate') {
          if (this.dateColumns.indexOf(data) !== -1) {
            return this.defineDateColumn(data, 'End Date');
          }
        }
        if (data === 'accepteddate') {
          if (this.dateColumns.indexOf(data) !== -1) {
            let settingsSort;
            if (user.settings && user.settings.ui && user.settings.ui.sortedDate === 'accepteddate') {
              settingsSort = user.settings.ui.sortedDate === 'accepteddate' ? 'desc' : null;
            } else {
              settingsSort = null;
            }
            return this.defineDateColumn(data, 'Accepted Date');
          }
        }
        if (data === 'expires') {
          if (this.dateColumns.indexOf(data) !== -1) {
            return this.defineDateColumn(data, 'Expire Date');
          }
        }
        if (data === 'reserveddate') {
          if (this.dateColumns.indexOf(data) !== -1) {
            return this.defineDateColumn(data, 'Reserved Date');
          }
        }
        if (data === 'enrolleddate') {
          if (this.dateColumns.indexOf(data) !== -1) {
            return this.defineDateColumn(data, 'Enrolled Date');
          }
        }
        if (data === 'signedcontract') {
          if (this.dateColumns.indexOf(data) !== -1) {
            return this.defineDateColumn(data, 'Signed Contract Date');
          }
        }
        if (data === 'updatedAt') {
          if (this.dateColumns.indexOf(data) !== -1) {
            let settingsSort;
            if (user.settings && user.settings.ui && user.settings.ui.sortedDate === 'updatedate') {
              settingsSort = user.settings.ui.sortedDate === 'updatedate' ? 'desc' : '';
            }
            return this.defineDateColumn(data, 'Updated Date');
          }
        }
        if (data === 'migrationdocumentdate') {
          if (this.dateColumns.indexOf(data) !== -1) {
            let settingsSort;
            if (user.settings && user.settings.ui && user.settings.ui.sortedDate === 'migrationdocumentdate') {
              settingsSort = user.settings.ui.sortedDate === 'updatedate' ? 'desc' : '';
            }
            return this.defineDateColumn(data, 'Migration Document Date');
          }
        }
        if (this.dateColumns.indexOf(data) !== -1) {
          return this.defineDateColumn(data, data);
        } else {
          return this.defineTextColumn(data, data);
        }
      })
      .map((last: any) => {
        if (user.type === 'Hosting' && this.hostApplicationColumns.indexOf(last.field) === -1) {
          last.hide = true;
          return last;
        }
        return last;
      });

    columns.push(
      ...[
        {
          ...this.defineTextColumn('User.fieldofstudy', 'Trainee Field of Study'),
          valueGetter: params => (params.data && params.data.User ? params.data.User.fieldofstudy : ''),
        },
        {
          ...this.defineTextColumn('User.email', 'Trainee Email'),
          valueGetter: params => (params.data && params.data.User ? params.data.User.email : ''),
        },
        {
          ...this.defineTextColumn('User.id', 'Trainee ID'),
          valueGetter: params => (params.data && params.data.User ? params.data.User.id : ''),
        },
        {
          ...this.defineTextColumn('User.name', 'Trainee Name'),
          valueGetter: params => (params.data && params.data.User ? params.data.User.name : ''),
        },
        {
          ...this.defineTextColumn('User.phone', 'Trainee Phone'),
          valueGetter: params => (params.data && params.data.User ? params.data.User.phone : ''),
        },
        {
          ...this.defineTextColumn('User.string', 'Trainee Notes'),
          valueGetter: params => (params.data && params.data.User ? params.data.User.string : ''),
        },
        {
          ...this.defineTextColumn('User.country', 'Trainee Country'),
          valueGetter: params => (params.data && params.data.User ? params.data.User.country : ''),
        },
        {
          ...this.defineTextColumn('ApplicationProgram.programlocation', 'Program Primary Site Location'),
          valueGetter: params => (params.data && params.data.Program ? params.data.Program.programlocation : ''),
        },
        {
          ...this.defineTextColumn('ApplicationProgram.id', 'Program ID'),
          valueGetter: params => (params.data && params.data.Program ? params.data.Program.id : ''),
        },
        {
          ...this.defineTextColumn('ApplicationProgram.specialty', 'Application Program Speciality'),
          valueGetter: params => (params.data && params.data.Program ? params.data.Program.specialty : ''),
        },
        {
          ...this.defineTextColumn('User.countryofbirth', 'Trainee Country of Birth'),
          valueGetter: params => (params.data && params.data.User ? params.data.User.countryofbirth : ''),
        },
        {
          ...this.defineTextColumn('User.university', 'Trainee University'),
          valueGetter: params => {
            if (params.data && params.data.User.university) {
              let uni = params.data.User.university;
              let parsedUni;
              if (uni && typeof uni === 'string') {
                try {
                  parsedUni = JSON.parse(uni);
                } catch (error) {
                  parsedUni = uni;
                }
              }
              if (typeof parsedUni === 'string') {
                return uni;
              } else {
                return `${parsedUni[0].custom || parsedUni[0].schoolname}`;
              }
            } else {
              return '';
            }
          },
        },
        {
          ...this.defineTextColumn('User.passportnumber', 'Trainee Passport Number'),
          valueGetter: params => (params.data && params.data.User ? params.data.User.passportnumber : ''),
        },

        {
          ...this.defineTextColumn('ApplicationTraineeInstitution.account', 'Trainee Institution Name'),
          valueGetter: params => (params.data && params.data.User && params.data.User.Department ? params.data.User.Department.account : ''),
        },
        {
          ...this.defineTextColumn('ApplicationProgramHost.name', 'Host Name'),
          valueGetter: params => (params.data && params.data.Program && params.data.Program.Host ? params.data.Program.Host.name : ''),
        },

        {
          ...this.defineTextColumn('Program.Host.Department.account', 'Host Institution Name'),
          valueGetter: params => {
            if (params.data && params.data.Program && params.data.Program.Host && params.data.Program.Host.Department) {
              return params.data.Program.Host.Department.account;
            } else {
              return '';
            }
          },
        },
      ],
    );

    columns.push({
      headerName: '',
      resizable: false,
      minWidth: 47,
      maxWidth: 47,
      pinned: 'left',
      editable: false,
      suppressMenu: true,
      filter: null,
      hide: false,
      suppressColumnsToolPanel: true,
      colId: 'link',
      sortable: false,
      suppressMovable: true,
      lockPinned: true,
      lockVisible: true,
      cellRendererParams: {
        page: user.type !== 'AMOEMPLOYEE' ? 'application' : 'amoApplication',
        user,
      },
      cellRenderer: GridSelectionRenderer,
    });

    return columns;
  }

  // Active Application
  activeApplicationsGridColumns(dataObj: any, user: any) {
    let columns = dataObj
      .filter((col: any) => {
        if (this.hostApplicationHide.indexOf(col) !== -1) {
          return false;
          // hide program prices if manager doesn't have permissions
        } else if (user.rules.permissions.includes('program:hideprices') && this.priceHide.indexOf(col) !== -1) {
          return false;
        } else if (user.type === 'Visitor-Recruitment' && this.vrApplicationHide.indexOf(col) !== -1) {
          return false;
        } else if (
          [
            'User',
            'Program',
            'Application',
            'ApplicationChain',
            'image',
            'paymenttype',
            'contractapitoken',
            'coupons',
            'migrationdocumentdate',
            'stripetransfers',
            'submittedrequirements',
            'availabilityid',
            'fullname',
            'hubspotdealid',
            'limbo',
            'userdocuments',
            'name',
            'useremail',
          ].includes(col)
        ) {
          return false;
        } else {
          return true;
        }
      })
      .map((data: any) => {
        if (data === 'states') {
          return {
            ...this.defineTextColumn(data, 'Application Status'),
            cellClass: this.statusStyleChange,
          };
        }
        if (data === 'id') {
          return {
            ...this.defineTextColumn(data, 'Application ID'),
          };
        }
        if (data === 'userid') {
          return {
            ...this.defineTextColumn(data, 'Trainee ID'),
          };
        }
        if (data === 'programid') {
          return {
            ...this.defineTextColumn(data, 'Program ID'),
          };
        }
        if (data === 'type') {
          return {
            ...this.defineTextColumn(data, 'Application Program Type'),
          };
        }
        if (data === 'address') {
          return {
            ...this.defineTextColumn(data, 'Program Primary Address'),
          };
        }
        if (data === 'specialty') {
          return {
            ...this.defineTextColumn(data, 'Program Primary Specialty'),
          };
        }
        if (data === 'visitorstatus') {
          return {
            ...this.defineTextColumn(data, 'Trainee Status'),
          };
        }
        if (data === 'city') {
          return {
            ...this.defineTextColumn(data, 'Application Program City'),
          };
        }
        if (data === 'hasrequestedbookingreview') {
          return {
            ...this.defineTextColumn(data, 'Has Requested Booking Review'),
          };
        }
        if (data === 'state') {
          return {
            ...this.defineTextColumn(data, 'Application Program State'),
          };
        }
        if (data === 'programlocation') {
          return {
            ...this.defineTextColumn(data, 'Application Program Location'),
          };
        }
        if (data === 'visameeting') {
          return {
            ...this.defineDateColumn(data, 'Visa Meeting'),
          };
        }
        if (data === 'month') {
          return {
            ...this.defineTextColumn(data, 'Application Month'),
          };
        }
        if (data === 'startyear') {
          return {
            ...this.defineTextColumn(data, 'Application Start Year'),
          };
        }
        if (data === 'madedeposit') {
          return {
            ...this.defineTextColumn(data, 'Made Deposit'),
          };
        }
        if (data === 'madepayment') {
          return {
            ...this.defineTextColumn(data, 'Made Payment'),
          };
        }
        if (data === 'hostpaid') {
          return {
            ...this.defineTextColumn(data, 'Stipend Paid'),
          };
        }
        if (data === 'visitorevaluation') {
          return {
            ...this.defineTextColumn(data, 'Trainee Evaluation'),
          };
        }
        if (data === 'hostevaluation') {
          return {
            ...this.defineTextColumn(data, 'Host Evaluation'),
          };
        }
        if (data === 'malpracticepaid') {
          return {
            ...this.defineTextColumn(data, 'Malpractice Paid'),
          };
        }
        if (data === 'immigrationservice') {
          return {
            ...this.defineTextColumn(data, 'Immigration Service Paid'),
          };
        }
        if (data === 'immigrationid') {
          return {
            ...this.defineTextColumn(data, 'Immigration ID'),
          };
        }
        if (data === 'malpracticeid') {
          return {
            ...this.defineTextColumn(data, 'Malpractice ID'),
          };
        }
        if (data === 'contracturl') {
          return {
            ...this.defineTextColumn(data, 'Contract URL'),
          };
        }
        if (data === 'visastatus') {
          return {
            ...this.defineTextColumn(data, 'Trainee Visa Status'),
          };
        }
        if (data === 'hasvisa') {
          return {
            ...this.defineTextColumn('User.hasvisa', 'Trainee Has Visa'),
          };
        }
        if (data === 'widgetid') {
          return {
            ...this.defineTextColumn(data, 'Widget ID'),
          };
        }
        if (data === 'deposit') {
          return {
            ...this.defineNumberColumn(data, 'Program Deposit Amount'),
          };
        }
        if (data === 'depositid') {
          return {
            ...this.defineTextColumn(data, 'Deposit ID'),
          };
        }
        if (data === 'price') {
          return {
            ...this.defineTextColumn(data, 'Program Price'),
          };
        }
        if (data === 'pricechange') {
          return {
            ...this.defineNumberColumn(data, 'Late Fee Amount'),
          };
        }
        if (data === 'untilchange') {
          return {
            ...this.defineTextColumn(data, 'Days Until Late'),
          };
        }
        if (data === 'checkoutprice') {
          return {
            ...this.defineNumberColumn(data, 'Checkout Price'),
          };
        }
        if (data === 'paymentid') {
          return {
            ...this.defineTextColumn(data, 'Payment ID'),
          };
        }
        if (data === 'applicationtags') {
          return {
            ...this.defineTextColumn(data, 'Application Notes'),
          };
        }
        if (data === 'enrollable') {
          return {
            ...this.defineTextColumn(data, 'Enable Enrollment'),
          };
        }
        if (data === 'housingtype') {
          return {
            ...this.defineTextColumn(data, 'Trainee Housing Type'),
          };
        }
        if (data === 'housingaddress') {
          return {
            ...this.defineTextColumn(data, 'Trainee Housing Address'),
          };
        }
        if (data === 'airbnblink') {
          return {
            ...this.defineTextColumn(data, 'Airbnb Link'),
          };
        }
        if (data === 'hashousing') {
          return {
            ...this.defineTextColumn(data, 'Has Housing'),
          };
        }
        if (data === 'paymenttype') {
          return {
            ...this.defineTextColumn(data, 'Payment Type'),
          };
        }
        if (data === 'hasrequestedinvitation') {
          return {
            ...this.defineTextColumn(data, 'Has Requested Invitation'),
          };
        }
        if (data === 'coach') {
          return {
            ...this.defineTextColumn(data, 'Student Coordinator'),
          };
        }
        if (data === 'coordinator') {
          return {
            ...this.defineTextColumn(data, 'Program Coordinator'),
          };
        }
        if (data === 'nolatefee') {
          return {
            ...this.defineNumberColumn(data, 'Waive Late Fee'),
          };
        }
        if (data === 'preenrollmentorientation') {
          return {
            ...this.defineTextColumn(data, 'Virtual Orientation'),
          };
        }
        if (data === 'predepartureorientation') {
          return {
            ...this.defineTextColumn(data, 'Pre Departure Orientation'),
          };
        }
        if (data === 'preacceptancereviewable') {
          return {
            ...this.defineTextColumn(data, 'Pre Accpetance Reviewable'),
          };
        }

        if (data === 'switchprotectionid') {
          return {
            ...this.defineTextColumn(data, 'Switch Protection ID'),
          };
        }
        if (data === 'switchprotectionpaid') {
          return {
            ...this.defineTextColumn(data, 'Switch Protection Paid'),
          };
        }
        if (data === 'startdate') {
          if (this.dateColumns.indexOf(data) !== -1) {
            let settingsSort;
            if (user.settings && user.settings.ui) {
              settingsSort = user.settings.ui.sortedDate === 'startdate' ? 'desc' : '';
            }

            return {
              headerName: 'Application Start Date',
              field: data,
              sort: settingsSort,
              filter: 'agDateColumnFilter',
              valueFormatter: this.dateFormatter,
              filterParams: {
                filterOptions: [
                  {
                    displayKey: 'equals',
                    displayName: 'On',
                    predicate: this.dateOn,
                  },
                  {
                    displayKey: 'lessThanOrEqual',
                    displayName: 'Before',
                    predicate: this.dateLessThan,
                  },
                  {
                    displayKey: 'greaterThanOrEqual',
                    displayName: 'After',
                    predicate: this.dateGreaterThan,
                  },
                  {
                    displayKey: 'inRange',
                    displayName: 'Between',
                    predicate: this.dateRange,
                  },
                ],
                inRangeInclusive: true,
                suppressAndOrCondition: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
                // provide comparator function
                comparator: this.dateFilter,
              },
            };
          }
        }
        if (data === 'createdAt') {
          if (this.dateColumns.indexOf(data) !== -1) {
            let settingsSort;
            if (user.settings && user.settings.ui) {
              settingsSort = user.settings.ui.sortedDate === 'createddate' ? 'desc' : '';
            }
            return {
              headerName: 'Application Created Date',
              field: data,
              filter: 'agDateColumnFilter',
              valueFormatter: this.dateFormatter,
              comparator: this.customComparator,
              sort: settingsSort ? settingsSort : 'desc',
              filterParams: {
                filterOptions: [
                  {
                    displayKey: 'equals',
                    displayName: 'On',
                    predicate: this.dateOn,
                  },
                  {
                    displayKey: 'lessThanOrEqual',
                    displayName: 'Before',
                    predicate: this.dateLessThan,
                  },
                  {
                    displayKey: 'greaterThanOrEqual',
                    displayName: 'After',
                    predicate: this.dateGreaterThan,
                  },
                  {
                    displayKey: 'inRange',
                    displayName: 'Between',
                    predicate: this.dateRange,
                  },
                ],
                inRangeInclusive: true,
                suppressAndOrCondition: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
                // provide comparator function
                comparator: this.dateFilter,
              },
            };
          }
        }
        if (data === 'enddate') {
          if (this.dateColumns.indexOf(data) !== -1) {
            return {
              headerName: 'Application End Date',
              field: data,
              filter: 'agDateColumnFilter',
              valueFormatter: this.dateFormatter,
              filterParams: {
                filterOptions: [
                  {
                    displayKey: 'equals',
                    displayName: 'On',
                    predicate: this.dateOn,
                  },
                  {
                    displayKey: 'lessThanOrEqual',
                    displayName: 'Before',
                    predicate: this.dateLessThan,
                  },
                  {
                    displayKey: 'greaterThanOrEqual',
                    displayName: 'After',
                    predicate: this.dateGreaterThan,
                  },
                  {
                    displayKey: 'inRange',
                    displayName: 'Between',
                    predicate: this.dateRange,
                  },
                ],
                inRangeInclusive: true,
                suppressAndOrCondition: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
                // provide comparator function
                comparator: this.dateFilter,
              },
            };
          }
        }
        if (data === 'accepteddate') {
          if (this.dateColumns.indexOf(data) !== -1) {
            let settingsSort;
            if (user.settings && user.settings.ui) {
              settingsSort = user.settings.ui.sortedDate === 'accepteddate' ? 'desc' : '';
            }
            return {
              headerName: 'Application Accepted Date',
              field: data,
              sort: settingsSort,
              sortable: true,
              filter: 'agDateColumnFilter',
              valueFormatter: this.dateFormatter,
              filterParams: {
                filterOptions: [
                  {
                    displayKey: 'equals',
                    displayName: 'On',
                    predicate: this.dateOn,
                  },
                  {
                    displayKey: 'lessThanOrEqual',
                    displayName: 'Before',
                    predicate: this.dateLessThan,
                  },
                  {
                    displayKey: 'greaterThanOrEqual',
                    displayName: 'After',
                    predicate: this.dateGreaterThan,
                  },
                  {
                    displayKey: 'inRange',
                    displayName: 'Between',
                    predicate: this.dateRange,
                  },
                ],
                inRangeInclusive: true,
                suppressAndOrCondition: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
                // provide comparator function
                comparator: this.dateFilter,
              },
            };
          }
        }
        if (data === 'expires') {
          if (this.dateColumns.indexOf(data) !== -1) {
            return {
              headerName: 'Application Expire Date',
              field: data,
              filter: 'agDateColumnFilter',
              valueFormatter: this.dateFormatter,
              filterParams: {
                filterOptions: [
                  {
                    displayKey: 'equals',
                    displayName: 'On',
                    predicate: this.dateOn,
                  },
                  {
                    displayKey: 'lessThanOrEqual',
                    displayName: 'Before',
                    predicate: this.dateLessThan,
                  },
                  {
                    displayKey: 'greaterThanOrEqual',
                    displayName: 'After',
                    predicate: this.dateGreaterThan,
                  },
                  {
                    displayKey: 'inRange',
                    displayName: 'Between',
                    predicate: this.dateRange,
                  },
                ],
                inRangeInclusive: true,
                suppressAndOrCondition: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
                // provide comparator function
                comparator: this.dateFilter,
              },
            };
          }
        }
        if (data === 'reserveddate') {
          if (this.dateColumns.indexOf(data) !== -1) {
            return {
              headerName: 'Application Reserved Date',
              field: data,
              sortable: true,
              filter: 'agDateColumnFilter',
              valueFormatter: this.dateFormatter,
              filterParams: {
                filterOptions: [
                  {
                    displayKey: 'equals',
                    displayName: 'On',
                    predicate: this.dateOn,
                  },
                  {
                    displayKey: 'lessThanOrEqual',
                    displayName: 'Before',
                    predicate: this.dateLessThan,
                  },
                  {
                    displayKey: 'greaterThanOrEqual',
                    displayName: 'After',
                    predicate: this.dateGreaterThan,
                  },
                  {
                    displayKey: 'inRange',
                    displayName: 'Between',
                    predicate: this.dateRange,
                  },
                ],
                inRangeInclusive: true,
                suppressAndOrCondition: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
                // provide comparator function
                comparator: this.dateFilter,
              },
            };
          }
        }
        if (data === 'enrolleddate') {
          if (this.dateColumns.indexOf(data) !== -1) {
            return {
              headerName: 'Application Enrolled Date',
              field: data,
              sortable: true,
              filter: 'agDateColumnFilter',
              valueFormatter: this.dateFormatter,
              filterParams: {
                filterOptions: [
                  {
                    displayKey: 'equals',
                    displayName: 'On',
                    predicate: this.dateOn,
                  },
                  {
                    displayKey: 'lessThanOrEqual',
                    displayName: 'Before',
                    predicate: this.dateLessThan,
                  },
                  {
                    displayKey: 'greaterThanOrEqual',
                    displayName: 'After',
                    predicate: this.dateGreaterThan,
                  },
                  {
                    displayKey: 'inRange',
                    displayName: 'Between',
                    predicate: this.dateRange,
                  },
                ],
                inRangeInclusive: true,
                suppressAndOrCondition: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
                // provide comparator function
                comparator: this.dateFilter,
              },
            };
          }
        }
        if (data === 'signedcontract') {
          if (this.dateColumns.indexOf(data) !== -1) {
            return {
              headerName: 'Signed Contract Date',
              field: data,
              sortable: true,
              filter: 'agDateColumnFilter',
              valueFormatter: this.dateFormatter,
              filterParams: {
                filterOptions: [
                  {
                    displayKey: 'equals',
                    displayName: 'On',
                    predicate: this.dateOn,
                  },
                  {
                    displayKey: 'lessThanOrEqual',
                    displayName: 'Before',
                    predicate: this.dateLessThan,
                  },
                  {
                    displayKey: 'greaterThanOrEqual',
                    displayName: 'After',
                    predicate: this.dateGreaterThan,
                  },
                  {
                    displayKey: 'inRange',
                    displayName: 'Between',
                    predicate: this.dateRange,
                  },
                ],
                inRangeInclusive: true,
                suppressAndOrCondition: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
                // provide comparator function
                comparator: this.dateFilter,
              },
            };
          }
        }
        if (data === 'updatedAt') {
          if (this.dateColumns.indexOf(data) !== -1) {
            let settingsSort;
            if (user.settings && user.settings.ui) {
              settingsSort = user.settings.ui.sortedDate === 'updatedate' ? 'desc' : '';
            }

            return {
              headerName: 'Application Updated Date',
              field: data,
              sort: settingsSort,
              sortable: true,
              filter: 'agDateColumnFilter',
              valueFormatter: this.dateFormatter,
              filterParams: {
                filterOptions: [
                  {
                    displayKey: 'equals',
                    displayName: 'On',
                    predicate: this.dateOn,
                  },
                  {
                    displayKey: 'lessThanOrEqual',
                    displayName: 'Before',
                    predicate: this.dateLessThan,
                  },
                  {
                    displayKey: 'greaterThanOrEqual',
                    displayName: 'After',
                    predicate: this.dateGreaterThan,
                  },
                  {
                    displayKey: 'inRange',
                    displayName: 'Between',
                    predicate: this.dateRange,
                  },
                ],
                inRangeInclusive: true,
                suppressAndOrCondition: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
                // provide comparator function
                comparator: this.dateFilter,
              },
            };
          }
        }
        if (this.dateColumns.indexOf(data) !== -1) {
          return {
            headerName: data,
            field: data,
            filter: 'agDateColumnFilter',
            valueFormatter: this.dateFormatter,
            filterParams: {
              filterOptions: [
                {
                  displayKey: 'equals',
                  displayName: 'On',
                  predicate: this.dateOn,
                },
                {
                  displayKey: 'lessThanOrEqual',
                  displayName: 'Before',
                  predicate: this.dateLessThan,
                },
                {
                  displayKey: 'greaterThanOrEqual',
                  displayName: 'After',
                  predicate: this.dateGreaterThan,
                },
                {
                  displayKey: 'inRange',
                  displayName: 'Between',
                  predicate: this.dateRange,
                },
              ],
              inRangeInclusive: true,
              suppressAndOrCondition: true,
              buttons: ['reset', 'apply'],
              closeOnApply: true,
              // provide comparator function
              comparator: this.dateFilter,
            },
          };
        } else {
          return this.defineTextColumn(data, data);
        }
      })
      .map((last: any) => {
        if (user.type === 'Hosting' && this.hostApplicationColumns.indexOf(last.field) === -1) {
          last.hide = true;
          return last;
        }
        return last;
      });
    columns.push(
      ...[
        {
          ...this.defineTextColumn('User.country', 'Trainee Country'),
          valueGetter: params => (params.data && params.data.User ? params.data.User.country : ''),
        },
        {
          ...this.defineTextColumn('User.name', 'Trainee Name'),
          valueGetter: params => (params.data && params.data.User ? params.data.User.name : ''),
        },
        {
          ...this.defineTextColumn('Program.name', 'Program Name'),
          valueGetter: params => (params.data && params.data.Program ? params.data.Program.name : ''),
        },
        {
          ...this.defineTextColumn('Program.address', 'Program Primary Address'),
          valueGetter: params => (params.data && params.data.Program ? params.data.Program.address : ''),
        },
        {
          ...this.defineTextColumn('Program.specialty', 'Program Primary Specialty'),
          valueGetter: params => (params.data && params.data.Program ? params.data.Program.specialty : ''),
        },
        {
          ...this.defineTextColumn('Program.city', 'Program Primary City'),
          valueGetter: params => (params.data && params.data.Program ? params.data.Program.city : ''),
        },
        {
          ...this.defineTextColumn('Program.state', 'Program Primary State'),
          valueGetter: params => (params.data && params.data.Program ? params.data.Program.state : ''),
        },
      ],
    );
    if (user.type !== 'Visitor-Recruitment') {
      columns.push(
        {
          ...this.defineTextColumn('User.email', 'Trainee Email'),
          valueGetter: params => (params.data && params.data.User ? params.data.User.email : ''),
        },
        {
          ...this.defineTextColumn('Host.name', 'Host Name'),
          valueGetter: params => (params.data && params.data.Program && params.data.Program.Host ? params.data.Program.Host.name : ''),
        },
        {
          ...this.defineTextColumn('Host.email', 'Host Email'),
          valueGetter: params => (params.data && params.data.Program && params.data.Program.Host ? params.data.Program.Host.email : ''),
        },

        {
          ...this.defineTextColumn('Host.phone', 'Host Phone'),
          valueGetter: params => (params.data && params.data.Program && params.data.Program.Host ? params.data.Program.Host.phone : ''),
        },
        {
          ...this.defineTextColumn('Host.cellphone', 'Host Mobile Phone'),
          valueGetter: params => (params.data && params.data.Program && params.data.Program.Host ? params.data.Program.Host.cellphone : ''),
        },
        {
          ...this.defineTextColumn('Host.clinicphone', 'Host Clinic Phone'),
          valueGetter: params => (params.data && params.data.Program && params.data.Program.Host ? params.data.Program.Host.clinicphone : ''),
        },
        {
          ...this.defineTextColumn('Host.clinicphoneext', 'Host Clinic Phone Ext'),
          valueGetter: params => (params.data && params.data.Program && params.data.Program.Host ? params.data.Program.Host.clinicphoneext : ''),
        },
        {
          ...this.defineTextColumn('Host.contactname', 'Host Admin Name'),
          valueGetter: params => (params.data && params.data.Program && params.data.Program.Host ? params.data.Program.Host.contactname : ''),
        },
        {
          ...this.defineTextColumn('Host.contactphone', 'Host Admin Phone'),
          valueGetter: params => (params.data && params.data.Program && params.data.Program.Host ? params.data.Program.Host.contactphone : ''),
        },
        {
          ...this.defineTextColumn('Host.contactphoneext', 'Host Admin Phone Ext'),
          valueGetter: params => (params.data && params.data.Program && params.data.Program.Host ? params.data.Program.Host.contactphoneext : ''),
        },
        {
          ...this.defineTextColumn('Host.contactemail', 'Host Admin Email'),
          valueGetter: params => (params.data && params.data.Program && params.data.Program.Host ? params.data.Program.Host.contactemail : ''),
        },
      );
    }

    columns.push({
      headerName: '',
      resizable: false,
      minWidth: 47,
      maxWidth: 47,
      pinned: 'left',
      editable: false,
      hide: false,
      suppressMenu: true,
      filter: null,
      suppressColumnsToolPanel: true,
      sortable: false,
      suppressMovable: true,
      lockPinned: true,
      lockVisible: true,
      colId: 'selection',
      cellRendererParams: { page: 'activeApplications', user },
      cellRenderer: GridSelectionRenderer,
    });

    return of(columns);
  }

  // documents Page
  documentsGridColumns(dataObj: any, user: any, selectionMode?: boolean) {
    const documentsListHide = ['deletedAt', 'metadata'];
    const columns = dataObj
      .filter((col: any) => {
        if (documentsListHide.indexOf(col) !== -1) {
          return false;
        } else if (user.rules && user.rules.permissions.includes('program:hideprices') && this.priceHide.indexOf(col) !== -1) {
          return false;
        } else if (user.type === 'Visitor-Recruitment' && this.vrProgramHide.indexOf(col) !== -1) {
          return false;
        } else {
          return true;
        }
      })
      .map((p: any) => {
        if (p === 'ApplicationRequirements') {
          return {
            ...this.defineTextColumn(p, 'Number of Associations'),
            valueGetter: params => {
              if (params.data && Array.isArray(params.data.ApplicationRequirements)) {
                return params.data.ApplicationRequirements.length;
              } else {
                return 0;
              }
            },
          };
        }

        if (p === 'createdAt') {
          return this.defineDateColumn(p, 'Document Created Date');
        }
        if (p === 'updatedAt') {
          return this.defineDateColumn(p, 'Document Updated Date');
        }

        if (this.dateColumns.includes(p)) {
          return this.defineDateColumn(p, p);
        }
        if (p === 'id') {
          return this.defineTextColumn(p, 'Document ID');
        }
        if (p === 'uri') {
          return this.defineTextColumn(p, 'Document URL');
        }
        if (p === 'filename') {
          return this.defineTextColumn(p, 'Filename');
        }
        if (p === 'filetype') {
          return this.defineTextColumn(p, 'Filetype');
        }
        if (p === 'etag') {
          return this.defineTextColumn(p, 'Etag');
        }

        return this.defineTextColumn(p, p);
      })
      .map((last: any) => {
        if (user.type === 'Hosting' && this.hostProgramColumns.indexOf(last.field) === -1) {
          last.hide = true;
          return last;
        }
        return last;
      });

    if (selectionMode) {
      columns.push({
        headerName: '',
        resizable: false,
        minWidth: 47,
        maxWidth: 47,
        pinned: 'left',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        editable: false,
        suppressMenu: true,
        filter: null,
        hide: false,
        suppressColumnsToolPanel: true,
        colId: 'collection',
        sortable: false,
        suppressMovable: true,
        lockPinned: true,
        lockVisible: true,
      });
    }

    columns.push({
      headerName: '',
      resizable: false,
      minWidth: 47,
      maxWidth: 47,
      pinned: 'left',
      editable: false,
      suppressMenu: true,
      hide: false,
      filter: null,
      suppressColumnsToolPanel: true,
      colId: 'selection',
      sortable: false,
      suppressMovable: true,
      lockPinned: true,
      lockVisible: true,
      cellRendererParams: { page: 'documents', user },
      cellRenderer: GridSelectionRenderer,
    });
    return of(columns);
  }

  // coupon edit nested associated Applications
  couponApplicationsGridColumns(dataObj: any, user: any, selectionMode?: boolean) {
    const documentsListHide = ['User', 'Program', 'deletedAt', 'metadata'];
    const columns = dataObj
      .filter((col: any) => {
        if (documentsListHide.indexOf(col) !== -1) {
          return false;
        } else if (user.rules && user.rules.permissions.includes('program:hideprices') && this.priceHide.indexOf(col) !== -1) {
          return false;
        } else if (user.type === 'Visitor-Recruitment' && this.vrProgramHide.indexOf(col) !== -1) {
          return false;
        } else {
          return true;
        }
      })
      .map((p: any) => {
        if (p === 'createdAt') {
          return this.defineDateColumn(p, 'Application Created Date');
        }
        if (p === 'updatedAt') {
          return this.defineDateColumn(p, 'Application Updated Date');
        }
        if (p === 'startdate') {
          return this.defineDateColumn(p, 'Start Date');
        }
        if (p === 'enddate') {
          return this.defineDateColumn(p, 'End date');
        }
        if (p === 'expires') {
          return this.defineDateColumn(p, 'Expire Date');
        }
        if (p === 'reserveddate') {
          return this.defineDateColumn(p, 'Reserved Date');
        }
        if (p === 'enrolleddate') {
          return this.defineDateColumn(p, 'Enrolled Date');
        }
        if (p === 'signedcontract') {
          return this.defineDateColumn(p, 'Signed Contract Date');
        }

        if (this.dateColumns.includes(p)) {
          return this.defineDateColumn(p, p);
        }

        if (p === 'states') {
          return this.defineTextColumn(p, 'Application Status');
        }
        if (p === 'type') {
          return this.defineTextColumn(p, 'Application Program Type');
        }
        if (p === 'month') {
          return this.defineTextColumn(p, 'Month');
        }
        if (p === 'startyear') {
          return this.defineTextColumn(p, 'Start year');
        }
        if (p === 'specialty') {
          return this.defineTextColumn(p, 'Speciality');
        }
        if (p === 'hostpaid') {
          return this.defineTextColumn(p, 'Host paid');
        }
        if (p === 'coach') {
          return this.defineTextColumn(p, 'Coach');
        }
        if (p === 'coordinator') {
          return this.defineTextColumn(p, 'Coordinator');
        }
        if (p === 'id') {
          return this.defineTextColumn(p, 'Application ID');
        }
        if (p === 'userid') {
          return this.defineTextColumn(p, 'Trainee ID');
        }
        if (p === 'programid') {
          return this.defineTextColumn(p, 'Program ID');
        }

        return this.defineTextColumn(p, p);
      })
      .map((last: any) => {
        if (user.type === 'Hosting' && this.hostProgramColumns.indexOf(last.field) === -1) {
          last.hide = true;
          return last;
        }
        return last;
      });

    columns.push(
      {
        ...this.defineTextColumn('User.status', 'Trainee Status'),
        valueGetter: params => (params.data && params.data.User ? params.data.User.status : ''),
      },
      {
        ...this.defineTextColumn('User.email', 'Trainee Email'),
        valueGetter: params => (params.data && params.data.User ? params.data.User.email : ''),
      },
      {
        ...this.defineTextColumn('User.name', 'Trainee Name'),
        valueGetter: params => (params.data && params.data.User ? params.data.User.name : ''),
      },
      {
        ...this.defineTextColumn('User.fieldofstudy', 'Trainee Field of Study'),
        valueGetter: params => (params.data && params.data.User ? params.data.User.Fieldofstudies.name : ''),
      },
      {
        ...this.defineTextColumn('User.country', 'Trainee Country'),
        valueGetter: params => (params.data && params.data.User ? params.data.User.country : ''),
      },
      {
        ...this.defineTextColumn('User.countryofbirth', 'Trainee Country of Birth'),
        valueGetter: params => (params.data && params.data.User ? params.data.User.countryofbirth : ''),
      },
      {
        ...this.defineTextColumn('User.university', 'Trainee University'),
        valueGetter: params => (params.data && params.data.User ? params.data.User.university : ''),
      },
      {
        ...this.defineTextColumn('User.passportnumber', 'Trainee Passport Number'),
        valueGetter: params => (params.data && params.data.User ? params.data.User.passportnumber : ''),
      },
      {
        ...this.defineTextColumn('User.Department.account', 'Trainee Department'),
        valueGetter: params => (params.data && params.data.User && params.data.User.Department ? params.data.User.Department.account : ''),
      },
      {
        ...this.defineTextColumn('Program.programlocation', 'Program Location'),
        valueGetter: params => (params.data && params.data.Program ? params.data.Program.programlocation : ''),
      },

      {
        ...this.defineTextColumn('Program.Host.name', 'Host Name'),
        valueGetter: params => (params.data && params.data.Program && params.data.Program.Host ? params.data.Program.Host.name : ''),
      },
    );

    if (selectionMode) {
      columns.push({
        headerName: '',
        resizable: false,
        minWidth: 47,
        maxWidth: 47,
        pinned: 'left',
        checkboxSelection: true,
        //headerCheckboxSelection: true,
        editable: false,
        suppressMenu: true,
        filter: null,
        hide: false,
        suppressColumnsToolPanel: true,
        colId: 'collection',
        sortable: false,
        suppressMovable: true,
        lockPinned: true,
        lockVisible: true,
      });
    }

    columns.push({
      headerName: '',
      resizable: false,
      minWidth: 47,
      maxWidth: 47,
      pinned: 'left',
      editable: false,
      suppressMenu: true,
      hide: false,
      filter: null,
      suppressColumnsToolPanel: true,
      colId: 'selection',
      sortable: false,
      suppressMovable: true,
      lockPinned: true,
      lockVisible: true,
      cellRendererParams: { page: 'amoApplication', user },
      cellRenderer: GridSelectionRenderer,
    });
    return of(columns);
  }

  // requirements Page
  requirementsGridColumns(dataObj: any, user: any) {
    const documentsListHide = [
      'deletedAt',
      'metadata',
      'jsondata',
      'Documents',
      'ApplicationRequirementMaps',
      'preapproval',
      'postreservation',
      // "preapproval",
      // "postreservation"
    ];
    const columns = dataObj
      .filter((col: any) => {
        if (documentsListHide.indexOf(col) !== -1) {
          return false;
        } else if (user.rules && user.rules.permissions.includes('program:hideprices') && this.priceHide.indexOf(col) !== -1) {
          return false;
        } else if (user.type === 'Visitor-Recruitment' && this.vrProgramHide.indexOf(col) !== -1) {
          return false;
        } else {
          return true;
        }
      })
      .map((p: any) => {
        if (p === 'name') {
          return this.defineTextColumn(p, 'Requirement Name');
        }

        if (p === 'datatype') {
          return {
            ...this.defineTextColumn(p, 'Requirement Data Type'),
            valueGetter: params => {
              if (params.data && Array.isArray(params.data.datatype)) {
                return JSON.stringify(params.data.datatype);
              } else {
                return params.data.datatype;
              }
            },
          };
        }

        if (p === 'type') {
          return this.defineTextColumn(p, 'Requirement Type');
        }

        if (p === 'academicstatus') {
          return this.defineTextColumn(p, 'Requirement Academic Status');
        }
        if (p === 'programtype') {
          return this.defineTextColumn(p, 'Requirement Program Types');
        }
        if (p === 'usersubmission') {
          return this.defineTextColumn(p, 'Require User Submit');
        }
        if (p === 'enabled') {
          return this.defineTextColumn(p, 'Enabled');
        }
        if (p === 'description') {
          return this.defineTextColumn(p, 'Requirement Description');
        }
        if (p === 'internalnotes') {
          return this.defineTextColumn(p, 'Requirement Internal Notes');
        }

        if (p === 'id') {
          return this.defineTextColumn(p, 'Requirement ID');
        }
        if (p === 'programids') {
          return this.defineTextColumn(p, 'Associated Program IDs');
        }
        if (p === 'hostids') {
          return this.defineTextColumn(p, 'Associated Host Names');
        }
        if (p === 'departmentids') {
          return this.defineTextColumn(p, 'Associated Institution Names');
        }

        if (p === 'createdAt') {
          return this.defineDateColumn(p, 'Requirement Created Date');
        }
        if (p === 'updatedAt') {
          return this.defineDateColumn(p, 'Requirement Updated Date');
        }

        if (this.dateColumns.includes(p)) {
          return this.defineDateColumn(p, p);
        }

        return this.defineTextColumn(p, p);
      })
      .map((last: any) => {
        if (user.type === 'Hosting' && this.hostProgramColumns.indexOf(last.field) === -1) {
          last.hide = true;
          return last;
        }
        return last;
      });

    columns.push(
      ...[
        {
          ...this.defineTextColumn('preapproval', 'Requirement Pre-Approval'),
          valueGetter: params => (params.data && params.data.preapproval ? params.data.preapproval : false),
        },
        {
          ...this.defineTextColumn('postreservation', 'Requirement Post-Reservation'),
          valueGetter: params => (params.data && params.data.postreservation ? params.data.postreservation : false),
        },
      ],
    );

    columns.push({
      headerName: '',
      resizable: false,
      minWidth: 47,
      maxWidth: 47,
      pinned: 'left',
      editable: false,
      suppressMenu: true,
      hide: false,
      filter: null,
      suppressColumnsToolPanel: true,
      colId: 'selection',
      sortable: false,
      suppressMovable: true,
      lockPinned: true,
      lockVisible: true,
      cellRendererParams: { page: 'requirements', user },
      cellRenderer: GridSelectionRenderer,
    });
    return of(columns);
  }

  // host List and manager list By DepartmentID GridColumns
  hostAndManagerListByDeptGridColumns(dataObj: any, user: any, type: string) {
    const columns = dataObj
      .filter((col: any) => {
        if (user.type === 'Visitor-Recruitment' && this.vrProgramHide.indexOf(col) !== -1) {
          return false;
        } else {
          return true;
        }
      })
      .map((p: any) => {
        if (p === 'id') {
          return this.defineTextColumn(p, type == 'host' ? 'Host ID' : 'Manager ID');
        }
        if (p === 'email') {
          return this.defineTextColumn(p, type == 'host' ? 'Host Email' : 'Manager email');
        }
        if (p === 'name') {
          return this.defineTextColumn(p, type == 'host' ? 'Host Name' : 'Manager name');
        }

        return this.defineTextColumn(p, p);
      })
      .map((last: any) => {
        if (user.type === 'Hosting' && this.hostProgramColumns.indexOf(last.field) === -1) {
          last.hide = true;
          return last;
        }
        return last;
      });

    columns.push({
      headerName: '',
      resizable: false,
      minWidth: 47,
      maxWidth: 47,
      pinned: 'left',
      editable: false,
      suppressMenu: true,
      hide: false,
      filter: null,
      suppressColumnsToolPanel: true,
      colId: 'selection',
      sortable: false,
      suppressMovable: true,
      lockPinned: true,
      lockVisible: true,
      //cellRendererParams: { page: "hostlistbydeptid", user },
      cellRenderer: GridSelectionRenderer,
    });
    return of(columns);
  }

  itinerariesGridColumns(objectkey: any, user: any) {
    const columns = objectkey
      .filter((col: any) => {
        if (col === 'deletedAt' || col === 'User') {
          return false;
        } else {
          return true;
        }
      })
      .map((colHeader: any) => {
        if (colHeader === 'id') {
          return this.defineTextColumn(colHeader, 'Itinerary ID');
        }
        if (colHeader === 'userid') {
          return this.defineTextColumn(colHeader, 'Trainee ID');
        }
        if (colHeader === 'selections') {
          return {
            ...this.defineTextColumn(colHeader, 'Selections'),
            valueGetter: params => {
              if (params.data && params.data.selections && params.data.selections.length > 0) {
                var parsedList;
                try {
                  if (typeof params.data.selections === 'string') {
                    parsedList = JSON.parse(params.data.selections);
                  } else {
                    parsedList = params.data.selections;
                  }
                  let list = [...parsedList].map(select => {
                    return `${select.programid}, ${select.startdate}`;
                  });
                  return list.join('; ');
                } catch (error) {
                  return params.data.selections;
                }
              } else {
                return '';
              }
            },
          };
        }

        if (colHeader === 'createdAt') {
          return this.defineDateColumn(colHeader, 'Itinerary Created Date');
        }
        if (colHeader === 'updatedAt') {
          return this.defineDateColumn(colHeader, 'Itinerary Updated Date');
        }
        if (colHeader === 'submittedAt') {
          return this.defineDateColumn(colHeader, 'Itinerary Submitted Date');
        }

        return { ...this.defineTextColumn(colHeader, colHeader) };
      });

    columns.push(
      ...[
        {
          ...this.defineTextColumn('User.country', 'Trainee Country'),
          valueGetter: params => (params.data && params.data.User ? params.data.User.country : ''),
        },
        {
          ...this.defineTextColumn('User.name', 'Trainee Name'),
          valueGetter: params => (params.data && params.data.User ? params.data.User.name : ''),
        },
        {
          ...this.defineTextColumn('User.email', 'Trainee Email'),
          valueGetter: params => (params.data && params.data.User ? params.data.User.email : ''),
        },
        {
          ...this.defineTextColumn('User.fieldofstudy', 'Trainee Field of Study'),
          valueGetter: params => (params.data && params.data.User ? params.data.User.Fieldofstudies.name : ''),
        },
        {
          ...this.defineTextColumn('User.status', 'Trainee Academic Status'),
          valueGetter: params => (params.data && params.data.User ? params.data.User.status : ''),
        },
        {
          ...this.defineTextColumn('User.hubspotcontactid', 'Trainee Contact ID'),
          valueGetter: params => (params.data && params.data.User ? params.data.User.hubspotcontactid : ''),
        },
      ],
    );

    columns.push({
      headerName: '',
      resizable: false,
      minWidth: 47,
      maxWidth: 47,
      pinned: 'left',
      editable: false,
      suppressMenu: true,
      hide: false,
      filter: null,
      suppressColumnsToolPanel: true,
      colId: 'link',
      sortable: false,
      suppressMovable: true,
      lockPinned: true,
      lockVisible: true,
      cellRendererParams: { page: 'itineraries', user },
      cellRenderer: GridSelectionRenderer,
    });

    return columns;
  }

  orderProgramGridColumns(type: string, user: any) {
    return of([
      {
        ...this.defineTextColumn('id', 'Availability Block ID'),
      },
      {
        ...this.defineTextColumn('orderid', 'Order ID'),
      },
      {
        ...this.defineTextColumn('programid', 'Program ID'),
      },
      {
        ...this.defineTextColumn('discount', 'Availability Block Discount'),
      },
      {
        ...this.defineTextColumn('fieldofstudy', 'Field of Study'),
      },
      {
        ...this.defineTextColumn('studenttype', 'Trainee Status'),
      },
      {
        ...this.defineTextColumn('programtype', 'Rotation Type'),
      },
      {
        ...this.defineTextColumn('seatsheld', 'Availability Block Seats Held'),
      },
      {
        ...this.defineDateColumn('startdate', 'Availbility Block Start Date'),
      },
      {
        ...this.defineDateColumn('enddate', 'Availbility Block End Date'),
      },
      {
        ...this.defineTextColumn('customdate', 'Availbility Block Custom Duration'),
      },
      {
        ...this.defineTextColumn('duration', 'Availability Block # Days'),
      },
      {
        ...this.defineTextColumn('customseat', 'Availability Block Research Seats'),
      },
      {
        ...this.defineTextColumn('blocktotal', 'Availability Block Total'),
      },
      {
        ...this.orderProgramStatusColumnFilter('status', 'Availability Block Status'),
        cellClass: this.orderProgramCellClass,
      },
      {
        ...this.defineTextColumn('removedreason', 'Availability Block Cancellation Reason'),
      },
      {
        ...this.defineDateColumn('createdAt', 'Created At'),
      },
      {
        ...this.defineDateColumn('updatedAt', 'Updated At'),
      },
      {
        ...this.orderStatusColumnFilter('Order.status', 'Order Status'),
        valueGetter: params => (params.data && params.data.Order ? params.data.Order.status : ''),
        cellClass: this.orderCellClass,
      },
      {
        ...this.defineTextColumn('Order.name', 'Order Name'),
        valueGetter: params => (params.data && params.data.Order ? params.data.Order.name : ''),
      },
      {
        ...this.defineTextColumn('Order.departmentaccount', 'Order Institution Account'),
        valueGetter: params => (params.data && params.data.Order ? params.data.Order.departmentaccount : ''),
      },
      {
        ...this.defineTextColumn('Order.notes', 'Order Notes'),
        valueGetter: params => (params.data && params.data.Order ? params.data.Order.notes : ''),
      },
      {
        ...this.defineTextColumn('Order.departmentid', 'Order Institution ID'),
        valueGetter: params => (params.data && params.data.Order ? params.data.Order.departmentid : ''),
      },
      {
        ...this.defineTextColumn('Program.state', 'Program State'),
        valueGetter: params => (params.data && params.data.Program ? params.data.Program.state : ''),
      },
      {
        ...this.defineTextColumn('Program.stipendcost', 'Program Stipend Amount'),
        valueGetter: params => (params.data && params.data.Program ? params.data.Program.stipendcost : ''),
      },
      {
        ...this.defineTextColumn('Program.city', 'Program City'),
        valueGetter: params => (params.data && params.data.Program ? params.data.Program.city : ''),
      },
      {
        ...this.defineTextColumn('Program.Host.name', 'Program Host Name'),
        valueGetter: params => (params.data && params.data.Program ? params.data.Program.Host.name : ''),
      },

      {
        ...this.defineTextColumn('Program.Host.Department.account', 'Program Host Institution Account'),
        valueGetter: params => (params.data && params.data.Program ? params.data.Program.Host.Department.account : ''),
      },
      {
        ...this.defineTextColumn('OrderProgramRotations.Application.id', 'Application IDs'),
        valueGetter: params => {
          if (params.data && params.data.OrderProgramRotations && params.data.OrderProgramRotations.length > 0) {
            const applicationId = [...new Set(params.data.OrderProgramRotations)]
              .filter((entry: any) => {
                if (entry.Application) {
                  return entry.Application;
                }
              })
              .map((orderprogramrotation: any) => orderprogramrotation.Application.id)
              .join();
            return applicationId;
          } else {
            return '';
          }
        },
      },
      {
        ...this.defineTextColumn('OrderProgramRotations.invoiceid', 'Stripe Invoice IDs'),
        valueGetter: params => {
          if (params.data && params.data.OrderProgramRotations && params.data.OrderProgramRotations.length > 0) {
            const invoiceId = [...new Set(params.data.OrderProgramRotations)]
              .filter((entry: any) => {
                if (entry.invoiceid) {
                  return entry.invoiceid;
                }
              })
              .map((id: any) => id.invoiceid)
              .join();
            return invoiceId;
          } else {
            return '';
          }
        },
      },
      {
        headerName: '',
        resizable: false,
        minWidth: 47,
        maxWidth: 47,
        pinned: 'left',
        editable: false,
        suppressMenu: true,
        hide: false,
        filter: null,
        suppressColumnsToolPanel: true,
        colId: 'selection',
        sortable: false,
        suppressMovable: true,
        lockPinned: true,
        lockVisible: true,
        cellRendererParams: { page: 'orders', user, extra: type },
        cellRenderer: GridSelectionRenderer,
      },
    ]);
  }

  orderGridColumns(type: string, user: any) {
    return of([
      {
        ...this.defineTextColumn('id', 'Order ID'),
      },
      {
        ...this.defineTextColumn('name', 'Order Name'),
      },
      {
        ...this.defineTextColumn('numberofrotations', 'Number of Rotations'),
      },
      {
        ...this.defineTextColumn('numberofrotationsfulfilled', 'Number of Fulfilled Rotations'),
      },
      {
        ...this.defineDateColumn('receiveddate', 'Order Received Date'),
      },
      {
        ...this.defineTextColumn('type', 'Order Program Type'),
      },
      {
        ...this.defineTextColumn('primarycontactemail', 'Order Primary Contact Email'),
      },
      {
        ...this.defineTextColumn('primarycontactname', 'Order Primary Contact Name'),
      },
      {
        ...this.defineTextColumn('primarycontactphone', 'Order Primary Contact Phone'),
      },
      {
        ...this.orderStatusColumnFilter('status', 'Order Status'),
        cellClass: this.orderCellClass,
      },
      {
        ...this.defineTextColumn('studenttype', 'Order Student Acamdemic Status'),
      },
      {
        ...this.defineTextColumn('fieldofstudy', 'Order Field of Study'),
      },
      {
        ...this.defineTextColumn('notes', 'Order Notes'),
      },
      {
        ...this.defineTextColumn('total', 'Order Total'),
      },
      {
        ...this.defineTextColumn('departmentid', 'Order Institution ID'),
      },
      {
        ...this.defineTextColumn('departmentaccount', 'Order Institution Account'),
      },
      {
        ...this.defineTextColumn('hubspotdealid', 'HubSpot Deal ID'),
      },
      {
        ...this.defineDateColumn('createdAt', 'Order Created Date'),
      },
      {
        ...this.defineDateColumn('updatedAt', 'Order Updated Date'),
      },
      {
        ...this.defineTextColumn('OrderPrograms.OrderProgramRotations.invoiceid', 'Stripe Invoice IDs'),
        valueGetter: params => {
          if (
            params.data &&
            params.data.OrderPrograms &&
            params.data.OrderPrograms.OrderProgramRotations &&
            params.data.OrderPrograms.OrderProgramRotations.length > 0
          ) {
            const invoiceId = [...new Set(params.data.OrderPrograms.OrderProgramRotations)]
              .filter((entry: any) => {
                if (entry.invoiceid) {
                  return entry.invoiceid;
                }
              })
              .map((id: any) => id.invoiceid)
              .join();
            return invoiceId;
          } else {
            return '';
          }
        },
      },
      {
        headerName: '',
        resizable: false,
        minWidth: 47,
        maxWidth: 47,
        pinned: 'left',
        editable: false,
        suppressMenu: true,
        hide: false,
        filter: null,
        suppressColumnsToolPanel: true,
        colId: 'selection',
        sortable: false,
        suppressMovable: true,
        lockPinned: true,
        lockVisible: true,
        cellRendererParams: { page: 'orders', user },
        cellRenderer: GridSelectionRenderer,
      },
    ]);
  }

  applicationOrderTypesGridColumns(objectkey: any, user: any) {
    let columns = objectkey
      .filter((col: any) => {
        if (col === 'role' || col === 'departmentid' || col === 'iscustom') {
          return;
        } else {
          return col;
        }
      })
      .map((data: any) => {
        if (data === 'id') {
          return this.defineTextColumn(data, 'Application Business Type ID');
        }
        if (data === 'type') {
          return this.defineTextColumn(data, 'Application Business Type');
        }
        if (data === 'relationship') {
          return this.defineTextColumn(data, 'Application Business Relationship');
        }
        if (data === 'Notes') {
          return this.defineTextColumn(data, 'Application Business Notes');
        }
        if (data === 'createdAt') {
          return this.defineDateColumn(data, 'Created At');
        }
        if (data === 'updatedAt') {
          return this.defineDateColumn(data, 'Updated At');
        }

        return this.defineTextColumn(data, data);
      });

    columns.push({
      headerName: '',
      resizable: false,
      minWidth: 47,
      maxWidth: 47,
      pinned: 'left',
      editable: false,
      suppressMenu: true,
      hide: false,
      filter: null,
      suppressColumnsToolPanel: true,
      colId: 'selection',
      sortable: false,
      suppressMovable: true,
      lockPinned: true,
      lockVisible: true,
      cellRendererParams: { page: 'applicationordertype', user },
      cellRenderer: GridSelectionRenderer,
    });
    return columns;
  }

  orderProgramRotationSeatsGridColumns(objectkey: any, user: any) {
    let columns = objectkey
      .filter((col: any) => {
        if (col === 'ProgramOrder' || col === 'status') {
          return;
        } else {
          return col;
        }
      })
      .map((data: any) => {
        if (data === 'id') {
          return this.defineTextColumn(data, 'Availability Seat ID');
        }
        if (data === 'invoiceid') {
          return this.defineTextColumn(data, 'Availability Seat Invoice ID');
        }
        if (data === 'price') {
          return this.defineTextColumn(data, 'Availability Seat Price');
        }
        if (data === 'discount') {
          return this.defineTextColumn(data, 'Availability Seat Discount');
        }
        if (data === 'createdAt') {
          return this.defineDateColumn(data, 'Availability Seat Created At');
        }
        if (data === 'updatedAt') {
          return this.defineDateColumn(data, 'Availability Seat Updated At');
        }
        if (data === 'orderprogramid') {
          return this.defineTextColumn(data, 'Availability Block ID');
        }
        if (data === 'userid') {
          return this.defineTextColumn(data, 'Trainee ID');
        }

        return { ...this.defineTextColumn(data, data) };
      });

    columns.push(
      {
        ...this.orderProgramStatusColumnFilter('ProgramOrder.status', 'Availability Block Status'),
        cellClass: this.orderProgramCellClass,
      },
      {
        ...this.defineTextColumn('ProgramOrder.programid', 'Availability Block Program ID'),
      },
      {
        ...this.defineTextColumn('ProgramOrder.programtype', 'Availability Block Program Type'),
      },
      {
        ...this.defineTextColumn('ProgramOrder.seatsheld', 'Availability Block Seats Held'),
      },
      {
        ...this.defineTextColumn('ProgramOrder.Order.id', 'Order ID'),
      },
      {
        ...this.defineDateColumn('ProgramOrder.startdate', 'Availability Block Start Date'),
      },
      {
        ...this.defineDateColumn('ProgramOrder.enddate', 'Availability Block End Date'),
      },
      {
        ...this.defineTextColumn('ProgramOrder.Order.departmentaccount', 'Order Institution Account'),
      },
      {
        ...this.defineTextColumn('ProgramOrder.Order.departmentid', 'Order Institution ID'),
      },
    );

    columns.push({
      headerName: '',
      resizable: false,
      minWidth: 47,
      maxWidth: 47,
      pinned: 'left',
      editable: false,
      suppressMenu: true,
      hide: false,
      filter: null,
      suppressColumnsToolPanel: true,
      colId: 'link',
      sortable: false,
      suppressMovable: true,
      lockPinned: true,
      lockVisible: true,
      cellRendererParams: { page: 'orderFromHost', user },
      cellRenderer: GridSelectionRenderer,
    });
    return columns;
  }

  /**
   * @description set columns for Admin Student Types
   *
   */
  adminStudentTypesGridColumns(objectkey: any, user: any) {
    let columns = objectkey
      .filter((col: any) => {
        if (col === 'checked') {
          return;
        } else {
          return col;
        }
      })
      .map((data: any) => {
        if (data === 'id') {
          return this.defineTextColumn(data, 'Admin Student Type ID');
        }
        if (data === 'name') {
          return this.defineTextColumn(data, 'Admin Student Type Name');
        }
        if (data === 'createdAt') {
          return this.defineDateColumn(data, 'Created At');
        }
        if (data === 'updatedAt') {
          return this.defineDateColumn(data, 'Updated At');
        }

        return this.defineTextColumn(data, data);
      });

    columns.push({
      headerName: '',
      resizable: false,
      minWidth: 47,
      maxWidth: 47,
      pinned: 'left',
      editable: false,
      suppressMenu: true,
      hide: false,
      filter: null,
      suppressColumnsToolPanel: true,
      colId: 'selection',
      sortable: false,
      suppressMovable: true,
      lockPinned: true,
      lockVisible: true,
      cellRendererParams: { page: 'adminstudenttype', user },
      cellRenderer: GridSelectionRenderer,
    });
    return columns;
  }

  /**
   * @description Set data source of grid's rows
   * @param user user account
   * @param listType endpoint target (eg users/ programs/)
   * @param query search query from parent component
   * @returns Observable
   */
  getListSource(user: any, listType: string, query?: any) {
    if (user.type && listType) {
      if (user.type === 'AMOEMPLOYEE') {
        if (listType === 'trainees') {
          return this.listService.amomanagervisitorlist(query);
        } else if (listType === 'coupons') {
          return this.listService.amoCouponlist(query);
        } else if (listType === 'payments') {
          return this.listService.paymentslist(query);
        } else if (listType === 'program-select') {
          return this.listService.amoemployeeprogramlist(query);
        } else if (listType === 'programs') {
          return this.listService.amoemployeeprogramlist(query);
        } else if (listType === 'program-availability') {
          return this.listService.amoemployeeprogramavailabilitylist(query);
        } else if (listType === 'hostPrograms') {
          return this.listService.HostsProgramList(query.hostid);
        } else if (listType === 'applications' || listType === 'nestedinstitutionapplication') {
          return this.listService.amomanagerapplicationlist(query);
        } else if (listType === 'nestedrelatedapplication') {
          //return this.listService.traineeApplicationsList(query);
          // return this.listService.traineeApplicationsListWithQuery(query);
          return this.listService.amomanagerapplicationlist(query);
        } else if (listType === 'nestedrelatedapplicationtrainee') {
          //return this.listService.traineeApplicationsList(query);
          return this.listService.traineeApplicationsListWithQuery(query);
        } else if (listType === 'program-applicants') {
          return this.programsService.programapplicationslist(query.programid, query);
        } else if (listType === 'reviewtemplates') {
          return this.listService.amoemployeReviewTemplateslist();
        } else if (listType === 'applicationrequirementsmap') {
          return this.listService.applicationRequirementsMapList({});
        } else if (listType === 'itineraries') {
          return this.listService.itinerariesList({});
        } else if (listType === 'reviews') {
          return this.listService.reviewslist(query);
        } else if (listType === 'fieldOfStudies') {
          return this.fieldOfStudiesService.getFieldOfStudiesList();
        } else if (listType === 'applicationOrderTypes') {
          return this.listService.applicationOrderTypesList();
        } else if (listType === 'nestedinstitutionorder') {
          return this.listService.orderProgramRotationsSeatsList(query);
        } else if (listType === 'adminStudentTypes') {
          return this.listService.getAdminStudentTypesList();
        }
      } else if (user.type === 'Hosting') {
        if (listType === 'trainees') {
          return this.listService.managerhostingvisitorlist(query);
        } else if (listType === 'program-applicants') {
          return this.programsService.hostingprogramapplicationslist(query.programid, query);
        }
      } else if (user.type === 'Visitor-Recruitment') {
        if (listType === 'trainees') {
          // need to reduce the logic of this endpoint in the api to fully commit to limits
          // query.limit = null;
          // query.offset = null;
          return this.listService.managertraineelist(query);
        }
      } else if (user.type === 'Provisional') {
        if (listType === 'program-availability') {
          return this.listService.amoemployeeprogramavailabilitylist(query);
        }
      }
    }
  }

  /**
   *
   * @param params date to be formatted
   */
  dateFormatter(params) {
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    if (params.value) {
      let splitDateValues = params.value.split('T');
      const splitNumberValues = splitDateValues[0].split('-');
      return `${monthNames[Number(splitNumberValues[1]) - 1]} ${splitNumberValues[2]}, ${new Date(params.value).getUTCFullYear()}`;
    } else {
      return '';
    }
  }

  /**
   *
   * @param params date to be formatted, ONLY USE FOR BIRTHDAY COLUMNS
   */
  birthdayDateFormatter(params) {
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    if (params.value) {
      return `${monthNames[new Date(params.value).getMonth()]} ${new Date(params.value).getDate()}, ${new Date(params.value).getFullYear()}`;
    } else {
      return '';
    }
  }

  /**
   *
   * @param params
   * @returns string
   */

  dayFormatter(params) {
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    if (params.value) {
      return days[params.value];
    } else {
      return '';
    }
  }

  /**
   *
   * @param params status of application
   */
  statusStyleChange(params) {
    return params.value === 'Pending' ? 'red-text' : params.value === 'Accepted' ? 'yellow-text' : params.value === 'Reserved' ? 'green-text' : 'black-text';
  }

  customComparator(valueA, valueB, nodeA, nodeB, isInverted) {
    //console.log("valueA ", valueA); console.log( "valueB ", valueB);
    let multiplier = 1;
    if (isInverted) multiplier = -1;

    if (valueA == null && valueB == null) return 0;
    //else if (valueA == null ) ret = 1*multiplier;
    else if (valueB == null) return -1 * multiplier;
    else if (valueA == valueB) return 0;
    return valueA > valueB ? 1 : -1;
  }

  /**
   *
   * @param filterLocalDateAtMidnight ag grid provide param for time setting
   * @param cellValue date value from api
   */
  dateFilter(filterLocalDateAtMidnight, cellValue) {
    const dateAsString = cellValue.split('T')[0];
    const newDate = dateAsString.split('-');
    const year = Number(newDate[0]);
    const month = Number(newDate[1]) - 1;
    const day = Number(newDate[2]) + 1;
    const formatDate = new Date(Date.UTC(year, month, day));

    const midnightDate = format(filterLocalDateAtMidnight, 'MM/dd/yyyy');
    const passingDate = format(formatDate, 'MM/dd/yyyy');

    if (midnightDate === passingDate) {
      return 0;
    }
    if (formatDate < filterLocalDateAtMidnight[0]) {
      return -1;
    }
    if (formatDate > filterLocalDateAtMidnight[0]) {
      return 1;
    }
  }

  /**
   * @deprecated check the defineDateColumn for range logic
   */
  dateRange(filterLocalDateAtMidnight, cellValue) {
    const dateAsString = cellValue.split('T')[0];
    const newDate = dateAsString.split('-');
    const year = Number(newDate[0]);
    const month = Number(newDate[1]) - 1;
    const day = Number(newDate[2]) + 1;
    const formatDate = new Date(Date.UTC(year, month, day));

    const midnightDate = format(filterLocalDateAtMidnight[0], 'MM/dd/yyyy');
    const passingDate = format(formatDate, 'MM/dd/yyyy');

    if (midnightDate === passingDate) {
      return true;
    }
    if (formatDate < filterLocalDateAtMidnight[0]) {
      return false;
    }
    if (formatDate > filterLocalDateAtMidnight[0]) {
      return true;
    }
  }

  dateLessThan(filterLocalDateAtMidnight, cellValue) {
    const dateAsString = cellValue.split('T')[0];
    const newDate = dateAsString.split('-');
    const year = Number(newDate[0]);
    const month = Number(newDate[1]) - 1;
    const day = Number(newDate[2]) + 1;
    const formatDate = new Date(Date.UTC(year, month, day));
    if (formatDate < filterLocalDateAtMidnight[0]) {
      return true;
    }
  }

  dateGreaterThan(filterLocalDateAtMidnight, cellValue) {
    const dateAsString = cellValue.split('T')[0];
    const newDate = dateAsString.split('-');
    const year = Number(newDate[0]);
    const month = Number(newDate[1]) - 1;
    const day = Number(newDate[2]) + 1;
    const formatDate = new Date(Date.UTC(year, month, day));

    if (formatDate > filterLocalDateAtMidnight[0]) {
      return true;
    }
  }

  dateOn(filterLocalDateAtMidnight, cellValue) {
    let cellDate = new Date(cellValue);

    const formatDate = new Date(cellDate.getUTCFullYear(), cellDate.getUTCMonth(), cellDate.getUTCDate());

    const midnightDate = format(filterLocalDateAtMidnight[0], 'MM/dd/yyyy');
    const passingDate = format(formatDate, 'MM/dd/yyyy');

    if (midnightDate === passingDate) {
      return true;
    }
  }
}
