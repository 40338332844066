import { Component, OnInit, Input } from '@angular/core';
import {
  faUser,
  faUniversity,
  faUserCircle,
  faShoppingCart,
  faTicketAlt,
  faFileAlt,
  faTasks,
  faChevronDown,
  faCalendarAlt,
  faUsers,
  faSearch,
  faUserMd,
  faIdCard,
  faClinicMedical,
} from '@fortawesome/pro-solid-svg-icons';

import { ActivityLogsService } from 'src/app/core/services/activity-logs/activity-logs.service';
import { PopoverController } from '@ionic/angular';
import { ActivityLogActionsComponent } from './../activity-log-actions/activity-log-actions.component';
import { userSelectors } from 'src/app/core/ngrx/selectors';
import { select, Store } from '@ngrx/store';
import { State } from 'src/app/core/ngrx/reducers';
import { ApplicationService } from 'src/app/core/services/application/application.service';
import { Router } from '@angular/router';
import { HostService } from '@Services/host/host.service';
import { faFileInvoice } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-activity-log',
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.scss'],
})
export class ActivityLogComponent implements OnInit {
  faUser = faUser;
  faUniversity = faUniversity;
  faUserCircle = faUserCircle;
  // faComputerClassic = faComputerClassic;
  faShoppingCart = faShoppingCart;
  faTicketAlt = faTicketAlt;
  faFileAlt = faFileAlt;
  faTasks = faTasks;
  faChevronDown = faChevronDown;
  faCalendarAlt = faCalendarAlt;
  faUsers = faUsers;
  faSearch = faSearch;
  faUserMd = faUserMd;
  faIdCard = faIdCard;
  faClinicMedical = faClinicMedical;
  faFileInvoice = faFileInvoice;

  logsFromApi: any = '';
  logs: any = '';

  dateSortSelection: string = 'None';
  dateSortRange: any;

  @Input() action: string;
  @Input() actionId: string;
  @Input() showSpinner: boolean = false;

  user$ = this.store.pipe(select(userSelectors.userProfile));
  user: any;
  adminList: any;
  selectedAdmins: any;

  actionTypes: any = {
    // profileAccount: false,
    // upload: true,
    // application: true,
    // payment: true,

    user: false, //Trainee Activity Log
    application: false, //Application Activity Log
    department: false, //Institution Activity Log
    program: false, //Program Activity Log
    payment: false, //Payment activity log
    host: false, //Host Activity Log
    order: false, //Order Activity Log
  };
  trueTypes: any;
  fetchingApi: boolean;
  searchActive: boolean = false;
  searchInput: string;
  searchQuery: string[] = null;

  constructor(
    private activityLogsService: ActivityLogsService,
    private popoverCtrl: PopoverController,
    private store: Store<State>,
    private applicationService: ApplicationService,
    private route: Router,
    private hostService: HostService,
  ) {}

  ngOnInit() {
    //console.log(this.action , this.actionId)

    // need to populate the actionTypes based off action
    if (this.action && this.actionId) {
      // when init, will only ever be all or a specific action, no combination
      if (this.action === 'all') {
        Object.keys(this.actionTypes).forEach(key => {
          this.actionTypes[key] = true;
        });
      } else {
        this.actionTypes[this.action] = true;
      }

      // no more use of 'all', all is none
      const today = new Date();
      const yesterday = new Date().setDate(today.getDate() - 2);
      let query: any = {
        // action: this.action,
        // action_id: ,
        // actor_id: ,
        // start_date: start_date,
        // end_date: end_date,
      };

      let valid_actions = ['user', 'program', 'application', 'payment', 'host', 'all', 'department', 'coupon', 'order'];

      if (valid_actions.includes(this.action)) {
        if (this.action !== 'all') {
          query.action = [this.action];
          query.action_id = this.actionId;
        }
      }
      this.fetchingApi = true;
      this.activityLogsService.getEsActivityLogs(query).subscribe(
        logsObj => {
          this.logsFromApi = logsObj;
          this.applyFilters(logsObj);
          this.fetchingApi = false;
        },
        err => {
          console.log('err', err);
          this.fetchingApi = false;
        },
      );
    }

    this.adminUsers();
  }

  adminUsers() {
    this.user$.subscribe((user: any) => {
      this.user = { ...user };
      if (user) {
        this.applicationService.findadminusers(this.user.id).subscribe((admins: any) => {
          this.adminList = admins;
        });
      }
    });
  }

  applyFilters(logsObj: any) {
    if (logsObj) {
      if (logsObj.length > 0) {
        //apply checkbox filter on data
        let logsTyped;
        if (this.trueTypes && this.trueTypes.length > 0) {
          logsTyped = logsObj.filter((log: any) => this.trueTypes.indexOf(log.action) > -1); //log.action == 'user')
        } else {
          logsTyped = logsObj;
        }
        //apply admin filter on logsTyped
        let adminTyped;
        if (this.selectedAdmins && this.selectedAdmins.length > 0) {
          adminTyped = logsTyped.filter((log: any) => this.selectedAdmins.indexOf(log.actor_id) > -1);
        } else {
          adminTyped = logsTyped;
        }

        let searchTyped;
        if (this.searchQuery && this.searchQuery.length > 0) {
          searchTyped = adminTyped.filter((log: any) => this.searchQuery.includes(log.action_id));
        } else {
          searchTyped = adminTyped;
        }

        //date filter on adminTyped final (logsTyped > )
        const dateFiltered = this.applyDateFilter(searchTyped);
        //most recent sort
        this.logs = this.mostRecent(dateFiltered); //recent first
      } else {
        this.logs = [];
      }
    }
  }

  mostRecent(records: any) {
    if (records) {
      if (records.length > 0) {
        return records.sort((a, b) => Date.parse(b.timestamp) - Date.parse(a.timestamp));
      } else {
        return [];
      }
    }
  }

  pastDateToISO(days) {
    var d = new Date();
    if (days > 0) {
      d.setDate(d.getDate() - days);
    }
    return new Date(d.setHours(0, 0, 0, 0)).toISOString();
  }

  applyDateFilter(logsTyped: any) {
    if (this.dateSortSelection && this.dateSortSelection != 'None') {
      // todays date at midnight (used in greater than)
      let datToday = Date.parse(this.pastDateToISO(0));
      // at midnight
      let yesterday = Date.parse(this.pastDateToISO(1));
      //at midnight
      let lastThirty = Date.parse(this.pastDateToISO(30));

      // rather than base other values off today, we are using custom range

      let filteredDateLog = [];
      if (logsTyped && logsTyped.length > 0) {
        filteredDateLog = logsTyped.filter((rec: any) => {
          let received = Date.parse(rec.timestamp);
          // console.log(rec.updatedAt,
          //   this.pastDateToISO(0),
          //   this.pastDateToISO(1))
          // console.log("-----");
          //Most recent
          if (this.dateSortSelection == 'Today') {
            if (received > datToday) {
              //today
              return rec;
            }
          } else if (this.dateSortSelection == 'Yesterday') {
            if (received < datToday && received > yesterday) {
              //only yesterday
              return rec;
            }
          } else if (this.dateSortSelection == 'Last 30 days') {
            if (received > lastThirty) {
              //last Thirty days
              return rec;
            }
          } else if (this.dateSortSelection == 'Custom Range') {
            if (this.dateSortRange) {
              let rangeStart = this.dateSortRange.start;
              let rangeEnd = this.dateSortRange.end;
              if (received < rangeEnd && received > rangeStart) {
                return rec;
              }
            }
          }
        });
      }
      return filteredDateLog;
    } else {
      return logsTyped;
    }
  }

  async logActions(event: CustomEvent) {
    const popoverLog = await this.popoverCtrl.create({
      component: ActivityLogActionsComponent,
      event: event,
      cssClass: 'popover-setting-log-actions',
      componentProps: { action: this.actionTypes, open: 'logActions' },
      showBackdrop: false,
      side: 'bottom',
      alignment: 'start',
    });
    await popoverLog.present();
    // catch on did dismiss
    const result = await popoverLog.onDidDismiss(); //.finally(()=>popoverLog.remove());
    if (result.data) {
      // console.log(result, result.data);
      this.actionTypes = result.data;
      //console.log("received parent", this.actionTypes);

      this.trueTypes = Object.keys(this.actionTypes).filter(e => this.actionTypes[e] == true);
      //['user','application','department','program','payment']

      let logsObj = this.logsFromApi;
      this.applyFilters(logsObj);
    }
  }

  async dateActions(event: CustomEvent) {
    const popoverDelete = await this.popoverCtrl.create({
      component: ActivityLogActionsComponent,
      event: event,
      cssClass: 'popover-setting-date-actions',
      componentProps: { action: this.dateSortSelection || '', open: 'dateActions', range: this.dateSortRange },
      showBackdrop: false,
      side: 'bottom',
      alignment: 'end',
    });
    await popoverDelete.present();
    // catch on did dismiss
    const result = await popoverDelete.onDidDismiss();
    if (result.data) {
      //if custom range section
      this.dateSortSelection = result.data.type;
      this.dateSortRange = result.data.range;
      //console.log("received parent date action", this.dateSortSelection);

      let logsObj = this.logsFromApi;
      this.applyFilters(logsObj);
    }
  }

  async adminActions(event: CustomEvent) {
    const popoverAdmin = await this.popoverCtrl.create({
      component: ActivityLogActionsComponent,
      event: event,
      cssClass: 'popover-setting-admin-actions',
      componentProps: {
        action: this.selectedAdmins || [],
        open: 'adminActions',
        adminList: this.adminList,
      },
      showBackdrop: false,
      side: 'bottom',
      alignment: 'start',
    });
    await popoverAdmin.present();
    // catch on did dismiss
    const result = await popoverAdmin.onDidDismiss();
    if (result.data) {
      //console.log(result, result.data);
      this.selectedAdmins = result.data;
      //console.log("received parent", this.selectedAdmins);
      let logsObj = this.logsFromApi;
      this.applyFilters(logsObj);
    }
  }

  linkDisplay(actionName) {
    //['user','application','department','program','payment']
    const displayables = ['application', 'program'];
    if (actionName && displayables.indexOf(actionName) > -1) {
      return true;
    }
    return false;
  }

  async openLink(dataObj: any) {
    if (dataObj) {
      const jsonData = dataObj;
      const moduleName = jsonData.action || '';
      //['user','application','department','program','payment']
      if (moduleName == 'application') {
        if (jsonData.action_id) {
          this.route.navigate([`/new-application/details/${jsonData.action_id}/application-quick-summary`]);
        } else {
          console.log('not sure, not found proper id', dataObj);
        }
      } else if (moduleName == 'department') {
        if (jsonData.action_id && jsonData.action_id != 'TEST_VALID_DEPARTMENT_ID') {
          this.route.navigate([`/institutions/details/institutions-information`, jsonData.action_id], {
            queryParams: {
              id: jsonData.action_id,
            },
          });
        } else {
          console.log('not sure, not found proper id', dataObj);
        }
        //object not found
      } else if (moduleName == 'program') {
        if (jsonData.action_id && jsonData.action_id != 'TEST_VALID_PROGRAM_ID') {
          this.route.navigate([`/programs/details/${jsonData.action_id}`]);
        } else {
          console.log('not sure, not found proper id', dataObj);
        }
        //TEST_VALID_PROGRAM_ID
      } else if (moduleName == 'user') {
        if (jsonData.action_id && jsonData.action_id != 'TEST_VALID_USER_ID') {
          this.route.navigate([`/trainees/details/${jsonData.action_id}/trainee-information`]);
        } else {
          console.log('not sure, not found proper id', dataObj);
        }
        //"TEST_VALID_USER_ID"
        //user_id: 7777771
        //actual would be 009f1362-7f5c-49b3-9528-3b1ec4167bec
        //not matched
      } else if (moduleName == 'payment') {
        if (jsonData.action_id && jsonData.action_id != 'TEST_VALID_PAYMENT_ID') {
          this.route.navigate([`/payments/details/${jsonData.action_id}`]);
        } else {
          console.log('not sure, not found proper id', dataObj);
        }
        //payment_id: "TEST_VALID_PAYMENT_ID"
        //100000229
        //actual 000a9efe-fd75-4a93-bd7b-1b6699929eea
      } else if (moduleName == 'host') {
        const hostid: string = jsonData.action_id;
        const host: any = await this.hostService.amohostshow(hostid).toPromise();

        this.route.navigate(['/hosts/details/edit'], {
          queryParams: {
            id: host.Department.id,
            hostid: hostid,
          },
        });
      } else if (moduleName == 'order') {
        this.route.navigate([`/orders/details/${jsonData.action_id}`]);
      }
    }
  }

  searchActions(ev: CustomEvent) {
    // this.searchInput = ev.detail.value
    // if its null don't want to do anything
    if (this.searchInput) {
      this.searchQuery = this.searchInput.split(',').map(str => str.trim());
    } else {
      // this is when the input is none
      this.searchQuery = null;
    }
    let logsObj = this.logsFromApi;
    this.applyFilters(logsObj);
  }

  formatData(changed_data) {
    if (changed_data && changed_data !== '[]' && changed_data !== '{}') {
      const data_messages = [];
      let parsed_data = JSON.parse(changed_data);
      for (const [key, value] of Object.entries(parsed_data)) {
        data_messages.push({ key, value });
      }
      return data_messages;
    }
  }
}
