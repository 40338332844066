<div class="activity-log" [ngClass]="showSpinner === true ? 'show-spinner' : ''">
  <!-- <h5 class="ml-2">Trainee Activity Log :: {{action}} :: {{actionId}}</h5> -->
  <!-- <span>{{action}} :: {{actionId}}</span> -->
  <div class="actions-bar">
    <div class="log-actions" *ngIf="this.action === 'all'">
      <ion-button class="search-button" (click)="searchActive = !searchActive">
        <fa-icon [icon]="faSearch" slot="end"></fa-icon>
      </ion-button>
      <ion-toolbar class="btns-toolbar mt-0">
        <ion-buttons slot="start">
          <ion-button color="secondary" fill="solid" (click)="logActions($event)">
            <fa-icon [icon]="faChevronDown" slot="end"></fa-icon>
            Actions&nbsp;&nbsp;
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </div>
    <div class="admin-actions">
      <ion-toolbar class="btns-toolbar mt-0">
        <ion-buttons slot="start">
          <ion-button color="secondary" fill="outline" (click)="adminActions($event)">
            <fa-icon [icon]="faUsers" slot="start"></fa-icon>
            &nbsp;&nbsp; Admins &nbsp;&nbsp;
            {{ selectedAdmins && selectedAdmins.length > 0 ? selectedAdmins.length : '' }} &nbsp;&nbsp;
            <fa-icon [icon]="faChevronDown" slot="end"></fa-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </div>
    <div class="date-actions">
      <ion-toolbar class="btns-toolbar mt-0">
        <ion-buttons slot="start">
          <ion-button color="secondary" fill="outline" (click)="dateActions($event)">
            <fa-icon [icon]="faCalendarAlt" slot="start"></fa-icon>
            &nbsp;&nbsp; {{ dateSortSelection }} &nbsp;&nbsp;
            <fa-icon [icon]="faChevronDown" slot="end"></fa-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </div>
  </div>
  <div *ngIf="searchActive">
    <ion-input debounce="500" class="search-box" placeholder="Enter Application ID, etc..." (ionChange)="searchActions($event)" [(ngModel)]="searchInput">
      <fa-icon [icon]="faSearch"></fa-icon>
    </ion-input>
  </div>

  <div class="ion-text-center pt-4 pb-4" *ngIf="showSpinner && fetchingApi">
    <ion-spinner name="lines"></ion-spinner>
  </div>
  <div class="wrapper" *ngIf="logs">
    <div class="logs">
      <div class="log-item" *ngFor="let log of logs">
        <div class="log">
          <div class="icon">
            <fa-icon *ngIf="log.action === 'user'" [icon]="faUser"></fa-icon>
            <fa-icon *ngIf="log.action === 'application'" [icon]="faFileAlt"></fa-icon>
            <fa-icon *ngIf="log.action === 'department'" [icon]="faClinicMedical"></fa-icon>
            <fa-icon *ngIf="log.action === 'program'" [icon]="faIdCard"></fa-icon>
            <fa-icon *ngIf="log.action === 'payment'" [icon]="faShoppingCart"></fa-icon>
            <fa-icon *ngIf="log.action === 'host'" [icon]="faUserMd"></fa-icon>
            <fa-icon *ngIf="log.action === 'coupon'" [icon]="faTicketAlt"></fa-icon>
            <fa-icon *ngIf="log.action === 'order'" [icon]="faFileInvoice"></fa-icon>
          </div>
          <div class="info">
            <ion-label class="action"
              >{{ log.action === 'user' ? 'Profile/Account Action' : log.actor_name }}
              <!-- ::: {{log.action}}
              ::: {{log.updatedAt}} -->
            </ion-label>
            <div class="msg-link">
              <ion-label class="txt">{{ log.message }}</ion-label>
              <ion-label class="link" (click)="openLink(log)" *ngIf="this.action === 'all'">Link</ion-label>
              <!-- <ion-label class="txt" *ngIf="log.changed_data">{{log.changed_data | json}}</ion-label> -->
            </div>
          </div>
          <div class="date">
            <ion-label class="txt">
              <!-- {{log.updatedAt}} -->
              <!-- {{ log.updatedAt | date: "longDate" }} -->
              {{ log.timestamp | date : 'EE, MMM dd, y' }}
              <!-- {{ log.updatedAt | date: "mediumTime" }} -->
              {{ log.timestamp | date : 'hh:mm:ss a' }}
            </ion-label>
          </div>
        </div>
        <div class="changed-data" *ngIf="log.changed_data && log.changed_data !== '{}' && log.changed_data !== '[]' && this.action !== 'all'">
          <div class="data" *ngFor="let data of formatData(log.changed_data)">
            <ion-label
              ><span class="key-name">{{ data.key }}</span> updated to {{ data.value || 'null' }}</ion-label
            >
          </div>
        </div>
      </div>
      <div *ngIf="logs && logs.length === 0">
        <h6 class="m-0">No Activity Logs</h6>
      </div>
    </div>
  </div>
</div>
