<div class="action-dropdown">
  <div class="log-actions" *ngIf="open === 'logActions'">
    <!--   
    {{showAll}}
    {{actionTypes |json}} -->
    <amo-form-checkbox
      label="Select all"
      appAccessor
      [ngModelOptions]="{ standalone: true }"
      [(ngModel)]="showAll"
      (ngModelChange)="actionOpts('showAll')"
      [value]="showAll"
      name="showall"
      ngDefaultControl
      [attr.disabled]="readOnly"
    >
    </amo-form-checkbox>

    <hr />
    <!-- user: true, //Trainee Activity Log -->
    <amo-form-checkbox
      label="Trainee Action"
      appAccessor
      [ngModelOptions]="{ standalone: true }"
      [(ngModel)]="actionTypes.user"
      (ngModelChange)="actionOpts('user')"
      [value]="actionTypes.user"
      name="user"
      ngDefaultControl
      [attr.disabled]="readOnly"
    >
    </amo-form-checkbox>

    <!-- application: true, //Application Activity Log -->
    <amo-form-checkbox
      label="Application Action"
      appAccessor
      [ngModelOptions]="{ standalone: true }"
      [(ngModel)]="actionTypes.application"
      (ngModelChange)="actionOpts('application')"
      [value]="actionTypes.application"
      name="application"
      ngDefaultControl
      [attr.disabled]="readOnly"
    >
    </amo-form-checkbox>

    <!-- department: true, //Institution Activity Log -->
    <amo-form-checkbox
      label="Institution Action"
      appAccessor
      [ngModelOptions]="{ standalone: true }"
      [(ngModel)]="actionTypes.department"
      (ngModelChange)="actionOpts('department')"
      [value]="actionTypes.department"
      name="department"
      ngDefaultControl
      [attr.disabled]="readOnly"
    >
    </amo-form-checkbox>

    <!-- program: true, //Program Activity Log -->
    <amo-form-checkbox
      label="Program Action"
      appAccessor
      [ngModelOptions]="{ standalone: true }"
      [(ngModel)]="actionTypes.program"
      (ngModelChange)="actionOpts('program')"
      [value]="actionTypes.program"
      name="program"
      ngDefaultControl
      [attr.disabled]="readOnly"
    >
    </amo-form-checkbox>

    <!-- payment: true, //Payment activity log -->
    <amo-form-checkbox
      label="Payment Action"
      appAccessor
      [ngModelOptions]="{ standalone: true }"
      [(ngModel)]="actionTypes.payment"
      (ngModelChange)="actionOpts('payment')"
      [value]="actionTypes.payment"
      name="payment"
      ngDefaultControl
      [attr.disabled]="readOnly"
    >
    </amo-form-checkbox>

    <!-- host: true, //Host Activity Log -->
    <amo-form-checkbox
      label="Host Action"
      appAccessor
      [ngModelOptions]="{ standalone: true }"
      [(ngModel)]="actionTypes.host"
      (ngModelChange)="actionOpts('host')"
      [value]="actionTypes.host"
      name="host"
      ngDefaultControl
      [attr.disabled]="readOnly"
    >
    </amo-form-checkbox>

    <!-- order: true, //Order Activity Log -->
    <amo-form-checkbox
      label="Order Action"
      appAccessor
      [ngModelOptions]="{ standalone: true }"
      [(ngModel)]="actionTypes.order"
      (ngModelChange)="actionOpts('order')"
      [value]="actionTypes.order"
      name="order"
      ngDefaultControl
      [attr.disabled]="readOnly"
    >
    </amo-form-checkbox>
  </div>

  <div class="date-actions" *ngIf="open === 'dateActions'">
    <app-range-calendar color="#fff" [range]="range" (dateEvent)="dateAction('Custom Range', $event)" class="date-picker"></app-range-calendar>

    <div class="date-options">
      <ion-item button lines="none" (click)="dateAction('None')" [ngClass]="{ activeItem: activeItem === 'None' }">
        <ion-label text-wrap>
          <span>None</span>
        </ion-label>
      </ion-item>
      <ion-item button lines="none" (click)="dateAction('Today')" [ngClass]="{ activeItem: activeItem === 'Today' }">
        <ion-label text-wrap>
          <span>Today</span>
        </ion-label>
      </ion-item>
      <ion-item button lines="none" (click)="dateAction('Yesterday')" [ngClass]="{ activeItem: activeItem === 'Yesterday' }">
        <ion-label text-wrap>
          <span>Yesterday</span>
        </ion-label>
      </ion-item>
      <ion-item button lines="none" (click)="dateAction('Last 30 days')" [ngClass]="{ activeItem: activeItem === 'Last 30 days' }">
        <ion-label text-wrap>
          <span>Last 30 days</span>
        </ion-label>
      </ion-item>
      <ion-item button lines="none" [ngClass]="{ activeItem: activeItem === 'Custom Range' }">
        <ion-label text-wrap>
          <span>Custom Range</span>
        </ion-label>
      </ion-item>
    </div>
  </div>

  <div class="admin-actions" *ngIf="open === 'adminActions'">
    <!-- need to have whole menu already displayed  -->
    <div class="searchbar">
      <fa-icon class="admin-search-icon" [icon]="faSearch"></fa-icon>
      <input
        autocomplete="off"
        data-lpignore="true"
        (ngModelChange)="adminFilter($event)"
        [ngModel]="adminFilterKey"
        type="text"
        placeholder="Start typing to filter..."
      />
    </div>
    <div class="admin-list">
      <ion-list>
        <ion-item (ionChange)="adminSelect($event)" *ngFor="let admin of adminFilteredList">
          <ion-checkbox [checked]="admin.value" [name]="admin.optionText"></ion-checkbox>
          <ion-text>{{ admin.optionText }}</ion-text>
        </ion-item>
      </ion-list>
    </div>
    <!-- <amo-multi-auto-select
      [dropdown]="adminUsers"
      (onInputChange)="adminsEvent($event)"
      (onPanelClose)="adminsMenuClosed($event)"
      label=""
      placeholder="Start typing to filter..."
      writableselectmode="false"
      allownull="false"
      [value]="prefAdmin"
      name="prefAdmin"
    ></amo-multi-auto-select> -->
  </div>
</div>
