import { NgModule } from '@angular/core';
import { PreloadAllModules, Route, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth/auth.guard';
import { waitForAsync } from '@angular/core/testing';
import { DirtyCheckGuard } from 'src/app/core/guards/dirty-check/dirty-check';
import { LogOffGuard } from 'src/app/core/guards/dirty-check/logoff.guard';
import { ReloadComponent } from 'src/app/reload/reload.component';

//better way to set page title
//https://stackoverflow.com/questions/38644314/changing-the-page-title-using-the-angular-2-new-router

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full' as Route['pathMatch'],
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/auth/pages/login/login.page.module').then(m => m.LoginPageModule),
    canActivate: [LogOffGuard],
  },
  {
    path: 'signup',
    loadChildren: () => import('./modules/auth/pages/signup/signup.module').then(m => m.SignupPageModule),
  },
  // {
  //   path: 'dashboard',
  //   loadChildren: () =>
  //     import('./modules/account/pages/account-dashboard/account-dashboard.module')
  //       .then(m => m.AccountDashboardPageModule),
  //   canActivate: [AuthGuard],
  //   runGuardsAndResolvers: 'always',
  // },
  {
    path: '__auth',
    loadChildren: () => import('./modules/auth/pages/central-landing/central-landing.module').then(m => m.CentralLandingPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/dashboard/pages/dashboard/dashboard.module').then(m => m.DashboardPageModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'clinical-rotations',
    loadChildren: () => import('./modules/clinical-rotations/pages/clinical-rotations/clinical-rotations.module').then(m => m.ClinicalRotationsPageModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'settings',
    loadChildren: async () => (await import('./modules/account/pages/account-settings/account-settings.module')).AccountSettingsPageModule,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
  // {
  //   path: 'forgot',
  //   loadChildren: async () =>
  //     import('./modules/auth/pages/auth-reset-password/auth-reset-password.module')
  //       .then(m => m.AuthResetPasswordPageModule)
  // },
  {
    path: 'unsubscribe',
    loadChildren: async () => (await import('./modules/account/pages/account-unsubscribe/account-unsubscribe.module')).AccountUnsubscribePageModule,
  },
  {
    path: 'visitors',
    loadChildren: async () => (await import('./modules/account/pages/account-visitors/account-visitors.module')).AccountVisitorsPageModule,
    canActivate: [AuthGuard],
  },
  {
    path: 'experiences',
    loadChildren: async () => (await import('./modules/account/pages/account-experiences/account-experiences.module')).AccountExperiencesPageModule,
    canActivate: [AuthGuard],
  },
  {
    path: 'applications',
    loadChildren: async () => (await import('./modules/account/pages/account-applications/account-applications.module')).AccountApplicationsPageModule,
    canActivate: [AuthGuard],
  },
  {
    path: 'institutions',
    loadChildren: async () => (await import('./modules/institutions/pages/institutions/institutions.module')).DepartmentsPageModule,
    canActivate: [AuthGuard],
  },
  {
    path: 'invite-visitors',
    loadChildren: async () => (await import('./modules/account/pages/account-invite/account-invite.module')).AccountInvitePageModule,
    canActivate: [AuthGuard],
  },
  {
    path: 'application-host/:applicationid',
    loadChildren: async () =>
      (await import('./modules/application/pages/application-hosting-details/application-hosting-details.module')).ApplicationHostingDetailsPageModule,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'active-applications',
    loadChildren: async () =>
      (await import('./modules/account/pages/account-host-active-applications/account-host-active-applications.module'))
        .AccountHostActiveApplicationsPageModule,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'new-application',
    loadChildren: () => import('./modules/new-application/pages/application/application.module').then(m => m.ApplicationPageModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'complete-applications',
    loadChildren: async () =>
      (await import('./modules/account/pages/account-host-complete-applications/account-host-complete-applications.module'))
        .AccountHostCompleteApplicationsPageModule,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
  // {
  //   path: "profile",
  //   loadChildren: async () =>
  //     import(
  //       "./modules/account/pages/account-management-profile/account-management-profile.module"
  //     ).then(m => m.AccountManagementProfilePageModule),
  //   canActivate: [AuthGuard],
  //   runGuardsAndResolvers: "always"
  // },
  {
    path: 'management/resetrequest/:userid/:hashid',
    loadChildren: async () => (await import('./modules/auth/pages/password-reset-link/password-reset-link.module')).PasswordResetLinkPageModule,
  },
  {
    path: 'signup/:managerid',
    loadChildren: async () => (await import('./modules/auth/pages/activation/activation.module')).ActivationPageModule,
  },
  {
    path: 'hosts',
    loadChildren: async () => (await import('./modules/host/pages/hosts/hosts.module')).HostsPageModule,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'managers',
    loadChildren: async () => (await import('./modules/managers/pages/managers/managers.module')).ManagersPageModule,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'trainees',
    loadChildren: async () => (await import('./modules/trainees/pages/trainee/trainee.module')).TraineePageModule,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./modules/auth/pages/user-set-password/user-set-password.module').then(m => m.UserSetPasswordPageModule),
  },
  {
    path: 'payments',
    loadChildren: async () => (await import('./modules/payments/pages/payments/payments.module')).PaymentsPageModule,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'documents',
    loadChildren: async () => (await import('./modules/documents/pages/documents/documents.module')).DocumentsPageModule,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'reviews',
    loadChildren: async () => (await import('./modules/reviews/pages/reviews/reviews.module')).ReviewsPageModule,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'review-templates',
    loadChildren: async () => (await import('./modules/review-templates/pages/review-templates/review-templates.module')).ReviewTemplatesModule,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'document',
    loadChildren: async () => (await import('./modules/document/pages/view-document/view-document.module')).ViewDocumentPageModule,
  },
  {
    path: 'programs',
    loadChildren: async () => (await import('./modules/programs/pages/program/programs.module')).ProgramsPageModule,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'program-bundles',
    loadChildren: async () => (await import('./modules/program-bundles/pages/program-bundles/program-bundles.module')).ProgramBundlesPageModule,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'program-availability',
    loadChildren: async () =>
      (await import('./modules/program-availability/pages/program-availability/program-availability.module')).ProgramAvailabilityPageModule,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'requirements',
    loadChildren: async () => (await import('./modules/requirements/pages/requirements/requirements.module')).RequirementsPageModule,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'coupons',
    loadChildren: async () => (await import('./modules/coupons/pages/coupons/coupons.module')).CouponsPageModule,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'application-requirements-map',
    loadChildren: async () =>
      (await import('./modules/application-requirement-maps/pages/application-requirement-map/application-requirement-map.module').then())
        .ApplicationRequirementsMapPageModule,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'itineraries',
    loadChildren: async () => (await import('./modules/itineraries/pages/itineraries/itineraries.module')).ItinerariesPageModule,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'confirm-account',
    loadChildren: () => import('./modules/auth/pages/confirm-account/confirm-account.module').then(m => m.ConfirmAccountPageModule),
  },
  {
    path: 'orders',
    loadChildren: async () => (await import('./modules/orders/pages/orders/orders.module')).OrdersPageModule,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: '.',
    component: ReloadComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: '**',
    loadChildren: () => import('./modules/auth/pages/page-not-found/page-not-found.module').then(m => m.PageNotFoundPageModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      // onSameUrlNavigation: 'reload'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
