import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports.js';

// const currentConfig = Auth.configure(awsconfig);

// Amplify.configure();

import { LicenseManager } from 'ag-grid-enterprise';
LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-073715}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Amopportunities,_Inc.}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{AMO_Development_Platform}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{AMO_Development_Platform}_need_to_be_licensed___{AMO_Development_Platform}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{18_February_2026}____[v3]_[01]_MTc3MTM3MjgwMDAwMA==d770f25ebc9babacbbe614012d6f62f3',
);

if (environment.production) {
  enableProdMode();
}

// Used to properly register the SW
//https://stackoverflow.com/questions/50968902/angular-service-worker-swupdate-available-not-triggered

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => {
    // if ("serviceWorker" in navigator && environment.production) {
    //   navigator.serviceWorker.register("ngsw-worker.js");
    // }
    // console.log("SW registered");
  })
  .catch(err => console.log(err));
