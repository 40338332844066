import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UploadService } from 'src/app/core/services/upload/upload.service';
import { State } from '../../ngrx/reducers';
import { Store } from '@ngrx/store';
import { ApplicationActions } from '../../ngrx/actions';
import { LoadingController, ToastController } from '@ionic/angular';
import moment from 'moment';
import { FormGroup } from '@angular/forms';
import Application from '../../interfaces/Application';
import { map } from 'rxjs/operators';
import { RequirementItems, Status } from '@modules/new-application/interfaces';
import { getDeadlineDaysValues } from '@shared/utils';

@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  static application: Partial<Application> = {};

  public enrollmentSubject = new Subject<any>();

  applicationGlobalProgramDeadlinedays: any;

  applicationDeadlineObject: { lateDate: string; countdown: number };

  constructor(
    private http: HttpClient,
    private uploadService: UploadService,
    private toastController: ToastController,
    private loadingController: LoadingController,
    private store: Store<State>, // private warning: Warning
  ) {}

  /**
   *
   * @param list submitted application requirement list from db
   * @description update submitted application requirement list store
   */
  setSubmittedApplicationRequirementListStore(list: any) {
    this.store.dispatch(
      ApplicationActions.setSubmittedApplicationRequirementList({
        submittedRequirementList: [...list],
      }),
    );
  }

  /**
   *
   * @param application submitted application form from db
   * @description update submitted application form store
   */
  setApplicationStore(form: any) {
    this.store.dispatch(
      ApplicationActions.setApplication({
        applicationObject: form,
      }),
    );
  }

  /**
   * @description checks for visitor's application are able to apply to a program
   * @param data applications of visitors
   */
  checkpartnersapplication(data: any): Observable<any> {
    return this.http.post(`${environment.api}/applications/checkpartnersapplication`, data);
  }

  /**
   * @alias Hosting
   * @description aws link to visitors resume *
   */
  managerhostingdownloadapplicationresume(applicationid: string): Observable<any> {
    return this.http.get(`${environment.api}/managements/${applicationid}/managerhostingdownloadapplicationresume`);
  }

  /**
   * @alias Hosting
   * @description  aws link to visitor's application summary doc *
   */
  managerhostingdownloadapplicationpacket(applicationid: string): Observable<any> {
    return this.http.get(`${environment.api}/managements/${applicationid}/managerhostingdownloadapplicationpacket`);
  }

  /**
   * @alias Hosting
   * @description visitor's entire application details *
   */
  managerhostingapplicationshow(applicationid: string): Observable<any> {
    return this.http.get(`${environment.api}/managements/${applicationid}/managerhostingapplicationshow`);
  }

  /**
   * @alias Hosting
   * @description visitor's application payment details *
   */
  managerhostinglistapplicationpayments(applicationid: string): Observable<any> {
    return this.http.get(`${environment.api}/managements/${applicationid}/managerhostinglistapplicationpayments`);
  }

  /**
   * @alias Hosting
   * @description view applicant's submitted documents *
   */
  managerhostingdownloaduserdocument(applicantid: string, documentKey: any): Observable<any> {
    return this.http.get(`${environment.api}/managements/document/?key=${documentKey}&ngsw-bypass=true`);
  }

  managervisiordetails(traineeid: string) {
    return this.http.get(`${environment.api}/managements/${traineeid}/visitor`);
  }

  managerhostvisiordetails(traineeid: string) {
    return this.http.get(`${environment.api}/managements/${traineeid}/managerhostingvisitorshow`);
  }

  /**
   *
   * @param documentKey
   */
  managerhostingViewdocument(documentKey: any): Observable<any> {
    return this.http.get(`${environment.api}/managements/document/?key=${documentKey}&ngsw-bypass=true`);
  }

  objectlist(prefix: any): Observable<any> {
    return this.http.get(`${environment.api}/managements/objectlist?prefix=${prefix.prefix}&ngsw-bypass=true`);
  }

  amomanagerShowApplication(applicationId: string): Observable<any> {
    return this.http.get(`${environment.api}/applications/${applicationId}`);
  }

  newnursingechowidget(application: any): Observable<any> {
    return this.http.post(`${environment.api}/configs/newnursingechowidget`, application);
  }

  newhellosignwidget(application: any): Observable<any> {
    return this.http.post(`${environment.api}/configs/newhellosignwidget`, application);
  }

  showapplicationassignments(applicationid: string): Observable<any> {
    return this.http.get(`${environment.api}/applications/${applicationid}/showapplicationassignments`);
  }

  findadminusers(userid: string): Observable<any> {
    return this.http.get(`${environment.api}/users/${userid}/findadminusers`);
  }

  updateApplicationAssignments(form: any): Observable<any> {
    return this.http.post(`${environment.api}/applications/updateapplicationassignments`, form);
  }

  adminUploadApplicationDocument(params: any): Observable<any> {
    return this.http.get(`${environment.api}/configs/privatewritepolicy?Key=${params.Key}&mimeType=${params.mimeType}`);
  }

  adminDeleteApplicationDocument(params: any): Observable<any> {
    const encodeKey = encodeURI(params.key);
    return this.http.get(`${environment.api}/configs/objectremove?key=${encodeKey}`);
  }

  amoUpdateApplication(form: any): Observable<any> {
    return this.http.put(`${environment.api}/applications/update`, form);
  }

  triggerApplicationEmail(applicationid: string, emailType: string): Observable<any> {
    return this.http.get(`${environment.api}/applications/${applicationid}/triggerapplicationemail?type=${emailType}`);
  }

  amoShowPayments(applicationid: string): Observable<any> {
    return this.http.get(`${environment.api}/applications/${applicationid}/payments`);
  }

  amoAssignCoach(applicationid: string): Observable<any> {
    return this.http.get(`${environment.api}/applications/${applicationid}/assigncoach`);
  }

  amoAssignCoordinator(applicationid: string): Observable<any> {
    return this.http.get(`${environment.api}/applications/${applicationid}/assigncoordinator`);
  }

  amoDeleteApplication(applicationid: string): Observable<any> {
    return this.http.delete(`${environment.api}/applications/${applicationid}/destroy`);
  }

  amoCreateApplication(form: any): Observable<any> {
    return this.http.post(`${environment.api}/applications/createapplication`, form);
  }

  amoCreateApplicationHold(form: any): Observable<any> {
    return this.http.post(`${environment.api}/applications/createhold`, form);
  }

  getProgramCapacityBySessionDate(applicationid: string): Observable<any> {
    return this.http.get(`${environment.api}/applications/${applicationid}/getprogramcapacitybysessiondate`);
  }

  amoApplicationSwitchProcess(form: any): Observable<any> {
    return this.http.post(`${environment.api}/applications/switchprocess`, form);
  }

  exportGridToCsv(queryObject: any, columnHeaders: Array<String>): Observable<any> {
    return this.http.post(`${environment.api}/applications/exportgridcsv`, {
      query: JSON.stringify(queryObject),
      columns: JSON.stringify(columnHeaders),
    });
  }

  /**
   *
   * @param applicationid
   * @returns list of application requirements from ApplicationRequirementMap DB
   */
  getApplicationAdditionalRequirements(applicationid: string): Observable<any> {
    return this.http.get(`${environment.api}/applications/${applicationid}/getapplicationadditionalrequirements`);
  }

  /**
   *
   * @param programId
   * @param programId
   * @returns cancel all of the pending/accepted applications with a specific program type.
   */
  cancelProgramTypeApplications(body: any): Observable<any> {
    return this.http.post(`${environment.api}/applications/cancelprogramtypeapplications`, {
      programId: body.programId,
      programType: body.programId,
    });
  }

  /**
   *
   * @param userid
   * @returns list of submitted requirements from trainee
   */
  getUserSubmittedApplicationAdditionalRequirements(userid: string): Observable<any> {
    return this.http.get(`${environment.api}/configs/objectlist?prefix=userdocuments/${userid}/requirements`);
  }

  /**
   *
   * @param userid
   * @param applicationid
   * @returns list of submitted malpractice documents from application
   */
  getUserSubmittedApplicationMalpracticeRequirements(userid: string, applicationid: string): Observable<any> {
    return this.http.get(`${environment.api}/configs/objectlist?prefix=userdocuments/${userid}/applications/${applicationid}/malpractice`);
  }

  /**
   *
   * @param userid
   * @returns list of submitted requirements from trainee
   */
  getUserSubmittedDocuments(userid: string): Observable<any> {
    return this.http.get(`${environment.api}/configs/objectlist?prefix=userdocuments/${userid}/`);
  }

  updateAmoApplication(applicationForm: any) {
    this.amoUpdateApplication(applicationForm).subscribe((response: any) => {
      if (response.success === 'success') {
        this.setApplicationStore(response.application);
        this.presentToast(response.message, response.type);
      }
    });
  }

  async updateApplicationRequirementDocuments(files, requirementName, uploadDestination, application, existingRequirments: any = []) {
    //
    const loading = await this.loadingController.create({
      message: 'Saving...',
    });
    loading.present();

    //
    this.uploadService.handleApplicationRequirementUpload(files, requirementName, uploadDestination, existingRequirments).then((response: any) => {
      loading.dismiss();
      if (response.success) {
        // we update the store submitted requirements

        forkJoin({
          list: this.getUserSubmittedApplicationAdditionalRequirements(application.userid),
          malpractice: this.getUserSubmittedApplicationMalpracticeRequirements(application.userid, application.id),
        }).subscribe(({ list, malpractice }): any => {
          const requirementList = !list ? [] : [...list];
          const malpracticeList = !malpractice ? [] : [...malpractice];
          const combinedList = [...requirementList, ...malpracticeList];

          this.setSubmittedApplicationRequirementListStore(combinedList);
        });
      }
    });
  }

  applicationRequirementDocumentsPrePostUpload(files, requirementName, uploadDestination, application, existingRequirments: any = []): Promise<any> {
    return new Promise(async (resolve, reject) => {
      //
      const loading = await this.loadingController.create({
        message: 'Saving...',
      });
      loading.present();
      //
      this.uploadService.handleApplicationRequirementUpload(files, requirementName, uploadDestination, existingRequirments).then((response: any) => {
        loading.dismiss();
        if (response.success) {
          // we update the store submitted requirements
          forkJoin({
            list: this.getUserSubmittedApplicationAdditionalRequirements(application.userid),
            malpractice: this.getUserSubmittedApplicationMalpracticeRequirements(application.userid, application.id),
          }).subscribe(({ list, malpractice }): any => {
            const requirementList = !list ? [] : [...list];
            const malpracticeList = !malpractice ? [] : [...malpractice];
            const combinedList = [...requirementList, ...malpracticeList];

            if (!combinedList.length) {
              reject({ type: 'warning', list: null });
            } else {
              this.setSubmittedApplicationRequirementListStore(combinedList);
              resolve({ type: 'success', list: combinedList });
            }
          });
        }
      });
    });
  }

  async presentToast(message, type) {
    const toast = await this.toastController.create({
      message,
      color: type,
      duration: 10000,
      position: 'top',
    });
    toast.present();
  }
  // end of application service

  userupdateprofileimage(key: any, userid: string): Observable<any> {
    return this.http.post(`${environment.api}/users/userupdateprofileimage`, {
      profileimageurl: key,
      userid: userid,
    });
  }

  getprofileimage(traineeId: string): Observable<any> {
    return this.http.get(`${environment.api}/users/profileimage?traineeid=${traineeId}`);
  }

  applicationArchiveDocument(data: any): Observable<any> {
    return this.http.post(`${environment.api}/applications/applicationarchivedocument`, {
      originalfilename: data.originalfilename,
      userid: data.userid,
      itemno: data.itemno,
    });
  }

  updateApplicationAttribute(data: any): Observable<any> {
    return this.http.post(`${environment.api}/applications/updateapplicationattributes`, data);
  }

  updateApplicationTransferPaymentStatus(data: any): Observable<any> {
    return this.http.post(`${environment.api}/applications/updateapplicationtransferpaymentstatus`, data);
  }

  findReviewByApplicationId(query: {}) {
    const encodedQuery = encodeURI(`query=${JSON.stringify(query)}`);

    return this.http.get(`${environment.api}/reviews/search?${encodedQuery}`);
  }

  public isApplicationFormDirty: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   *
   * @param application application object from api
   * @description Sets application's deadline countdown and date
   * @returns object {countdown, lateDate}
   */
  setDeadlineDaysValues(application: Application) {
    if (application.Program) {
      this.applicationGlobalProgramDeadlinedays = application.Program.deadlinedays;
    }

    this.applicationDeadlineObject = getDeadlineDaysValues(application.startdate, this.applicationGlobalProgramDeadlinedays);
  }

  /**
   *
   * @param application application object from api
   * @param ApplicationForm application form from forms service
   * @description Sets application form patch values
   */
  setApplicationForm(application: Partial<Application>, ApplicationForm: FormGroup) {
    if (JSON.stringify(application) !== '{}') {
      const applicationChainId = application.ApplicationChain && application.ApplicationChain.length > 0 ? application.ApplicationChain[0].id : '';

      ApplicationForm.patchValue({
        id: application.id,
        userid: application.userid,
        programid: application.programid,
        applicationChainId: applicationChainId,
        email: application.useremail,
        fullname: application.fullname,
        coupons: application.coupons,
        visitorstatus: application.visitorstatus,
        type: application.type,
        // hostemail: application.Program.Host.email,
        // host: application.Program.Host.name,
        states: application.states,
        name: application.name ? application.name : application.Program.name,
        // preapprovalrequired: application.Program.preapprovalrequired,
        enrollable: application.enrollable,
        contracturl: application.contracturl,
        month: application.month,
        startyear: application.startyear,
        startdate: application.startdate,
        enddate: application.enddate,
        expires: application.expires,
        trellourl: application.trellourl,
        specialty: application.specialty,
        city: application.city,
        state: application.state,
        programlocation: application.programlocation,
        price: application.price,
        mergedocuments: false,
        deposit: application.deposit,
        madedeposit: application.madedeposit,
        depositid: application.depositid,
        madepayment: application.madepayment,
        paymentid: application.paymentid,
        accepteddate: application.accepteddate,
        preacceptancereviewable: application.preacceptancereviewable,
        signedcontract: application.signedcontract,
        reserveddate: application.reserveddate,
        enrolleddate: application.enrolleddate,
        malpracticepaid: application.malpracticepaid ? true : false,
        malpracticeid: application.malpracticeid,
        housingaddress: application.housingaddress,
        housingtype: application.housingtype,
        hashousing: application.hashousing,
        hasrequestedinvitation: application.hasrequestedinvitation,
        hostevaluation: application.hostevaluation,
        visitorevaluation: application.visitorevaluation,
        airbnblink: application.airbnblink,
        applicationtags: application.applicationtags,
        hubspotdealid: application.hubspotdealid,
        usmle: application.usmle,
        nolatefee: application.nolatefee,
        university: application.university,
        visastatus: application.visastatus,
        preenrollmentorientation: application.preenrollmentorientation,
        predepartureorientation: application.predepartureorientation,
        studentobservershipinvitation: false,
        studentelectiveinvitation: false,
        graduateobservershipinvitation: false,
        switchprotectionpaid: application.switchprotectionpaid ? true : false,
        switchprotectionid: application.switchprotectionid,
        migrationdocumentdate: application.migrationdocumentdate,
        limbo: application.limbo,
        hostpaid: application.hostpaid ? true : false,
        stipendcost: application.stipendcost,
        referredhostpaid: application.referredhostpaid,
        physician: application.physician,
        pricechange: application.pricechange,
        applicationordertype: application.applicationordertype,
        nursing_hours: application.nursing_hours,
        hourlyprice: application.hourlyprice,
        postponedtoreserveddate: application.postponedtoreserveddate,
      });
    }
  }

  transferFilesForApplicationRequirements(applicationId: string): Observable<any> {
    return this.http.post(`${environment.api}/applications/${applicationId}/transferfilesforapplicationrequirements`, {});
  }

  getlistApplicationRequirement(applicationId: any) {
    return this.http.get(`${environment.api}/applications/${applicationId}/getapplicationadditionalrequirements`);
  }

  cancelApplicationHold(applicationId: string) {
    return this.http.get(`${environment.api}/applications/${applicationId}/cancelhold`);
  }

  removeApplicationHold(applicationId: string) {
    return this.http.delete(`${environment.api}/applications/${applicationId}/deleteHold`);
  }

  cancelProgramsCustomAvailability(programId: number, availabilityId: string): Observable<any> {
    return this.http.post(`${environment.api}/applications/cancelprogramscustomavailability`, {
      programId: programId,
      availabilityId: availabilityId,
    });
  }

  /**
   * @description swap two application start dates
   */
  swapapplicationsessionstartdates(application1: string, application2: string): Observable<any> {
    return this.http.post(`${environment.api}/applications/swapapplicationsessionstartdates`, { applicationid1: application1, applicationid2: application2 });
  }

  amomanagerShowApplicationchain(applicationId: string): Observable<any> {
    return this.http.get(`${environment.api}/applicationchains/${applicationId}/adminshow`);
  }

  amoManagerCreateApplicationChain(payload: any): Observable<any> {
    return this.http.post(`${environment.api}/applicationchains/managercreateapplicationchain`, payload);
  }

  /**
   * Cancel a programs month or year or both avalabilites and cancel active applications.
   * @param body
   * @returns {object}
   */
  cancelProgramMonthYearAvalability(body: any): Observable<any> {
    return this.http.post(`${environment.api}/applications/cancelprogrammonthyearavailability`, body);
  }

  bulkchangeapplicationstates(applicationIdArray: string[], state: string): Observable<any> {
    return this.http.post(`${environment.api}/applications/bulkchangeapplicationstates`, { applicationIdArray: applicationIdArray, newState: state });
  }

  adminremoveapplicationfromchain(applicationid: string, chainid: string): Observable<any> {
    return this.http.post(`${environment.api}/applicationchains/adminremoveapplicationfromchain`, { applicationid: applicationid, chainid: chainid });
  }

  admindeletechainandmappings(chainid: string): Observable<any> {
    return this.http.post(`${environment.api}/applicationchains/admindeletechainandmappings`, { chainid: chainid });
  }

  /**
   * applicationselectquery ex: { query: 08 }
   * @param query with saerched value (Application Id or User Id or Program Id)
   * @returns {object} of application ids
   */
  applicationSelectQuery(query: any) {
    return this.http.post(`${environment.api}/applications/applicationselectquery`, query);
  }

  addApplicationToChain(payload: any): Observable<any> {
    return this.http.post(`${environment.api}/applicationchains/adminaddapplicationtochain`, payload);
  }

  getImmunizationMapList(applicationid: string): Observable<any> {
    return this.http.get(`${environment.api}/applications/${applicationid}/getimmunizationmaplist`).pipe(
      map(event => {
        return [...event['rows']].map(row => {
          if (row['ImmunizationMaps'] && row['ImmunizationMaps'].length > 0) {
            return {
              ...row,
              immunizationmapid: row['ImmunizationMaps'][0]['id'],
              status: row['ImmunizationMaps'][0]['status'],
            };
          } else {
            return {
              ...row,
              status: 'Not Submitted',
            };
          }
        });
      }),
    );
  }

  createApplicationChecklist(applicationid: string): Observable<any> {
    return this.http.post(`${environment.api}/applications/${applicationid}/createChecklist`, { id: applicationid });
  }

  updateApplicationChecklist(applicationid: string, requirement: RequirementItems): Observable<any> {
    return this.http.post(`${environment.api}/applications/${applicationid}/updateChecklist`, { newDoc: requirement });
  }

  updateImmunizationStatuses(applicationid: string, statuses: string[]): Observable<any> {
    return this.http.post(`${environment.api}/applications/${applicationid}/immunizationupdatestatus`, { statuses: statuses });
  }

  bulkUpdateApplicationChecklistItems(applicationid: string, requirements: RequirementItems[]): Observable<any> {
    return this.http.post(`${environment.api}/applications/${applicationid}/bulkupdateapplicationchecklist`, { requirements: requirements });
  }

  migrateImmunizationRecords(applicationid: string): Observable<any> {
    return this.http.post(`${environment.api}/applications/bulkupdateapplicationchecklist`, { applicationid: applicationid });
  }

  validateHubspotApplication(applicationid: string, hubspotdealid: string): Observable<any> {
    return this.http.post(`${environment.api}/applications/${applicationid}/validateHubspotApplication?ngsw-bypass=true`, { hubspotdealid });
  }

  regenerateDocuments(applicationid: string, documentType: string): Observable<any> {
    return this.http.post(`${environment.api}/applications/${applicationid}/regeneratedocuments`, { documentType });
  }
}

// * = switched to cognito auth guard
