import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-charts-modal',
  templateUrl: './charts-modal.component.html',
  styleUrls: ['./charts-modal.component.scss'],
})
export class ChartsModalComponent implements OnInit {
  @Input() type: string;
  @Input() chartOptions: any;

  constructor() {}

  ngOnInit() {}
}
