import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ApplicationService } from '@Services/application/application.service';
import { NavigationService } from '@Services/navigation/navigation.service';
import { Warning } from '@shared/services';
import { SmartToastService } from '@shared/services/smartToast.service';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-application-chain-actions',
  templateUrl: './application-chain-actions.component.html',
  styleUrls: ['./application-chain-actions.component.scss'],
})
export class ApplicationChainActionsComponent implements OnInit {
  @Input() applicationchainid: string;

  @Input() selectedApplications: string[] = [];

  showApplicationAddInterface: boolean = false;

  constructor(
    private applicationService: ApplicationService,
    private smartToastService: SmartToastService,
    private warning: Warning,
    private popoverCtrl: PopoverController,
    private navService: NavigationService,
  ) {}

  ngOnInit() {}

  toggleApplicationAddInterface() {
    this.popoverCtrl.dismiss({ showApplicationAddInterface: true });
  }

  bulkApplicationStateUpdate(type: string) {
    this.popoverCtrl.dismiss();
    if (this.selectedApplications.length > 0 && type) {
      this.applicationService.bulkchangeapplicationstates(this.selectedApplications, type).subscribe(
        response => {
          if (response.applications) {
            response.applications.forEach(application => {
              this.smartToastService.show(application.message, application.type, 10000);
            });
          }

          if (response.selectedApplicationsError) {
            response.selectedApplicationsError.forEach(error => {
              this.smartToastService.show(error.message, error.type, 10000);
            });
          }
        },
        err => {
          this.warning.create('We were unable to complete this action. Please contact support for help resolving this issue.');
        },
      );
    }
  }

  removeSelectionsFromChain() {
    if (this.selectedApplications.length > 0 && this.applicationchainid) {
      this.popoverCtrl.dismiss();
      let allResults = [];
      let allerrors = [];

      const removeList = [...this.selectedApplications].map(applicationid =>
        this.applicationService.adminremoveapplicationfromchain(applicationid, this.applicationchainid).pipe(catchError(error => of(error))),
      );
      forkJoin(removeList).subscribe(
        responses => {
          allResults = responses;
        },
        errors => {
          allerrors = errors;
        },
        () => {
          allResults.map(i => {
            if (i && i.success) {
              //console.log(i.message || "document Deleted")
              this.smartToastService.show('Removed Application ' + i.id + ' from chain ' + this.applicationchainid + '.', 'success', 5000);
              setTimeout(() => {
                location.reload();
              }, 5000);
            } else {
              const err = i;
              if (err.error && err.error.message) {
                //console.log(err.error.message);
                this.smartToastService.show(err.error.message, 'danger', 5000);
              } else {
                //console.log("document Delete failed");
                this.smartToastService.show('Application Cancel Failed', 'danger', 5000);
              }
            }
          });
        },
      );
    } else {
      alert(this.selectedApplications + ' ' + this.applicationchainid);
    }
  }

  disassembleChain() {
    if (this.applicationchainid) {
      this.applicationService.admindeletechainandmappings(this.applicationchainid).subscribe(response => {
        if (response.success) {
          this.smartToastService.show(response.message, 'success', 10000);
          this.navService.main('applicationchain.list');
        } else {
          this.smartToastService.show(response.message, 'warning', 10000);
        }
      });
    }
  }
}
