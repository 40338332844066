import { Component, ViewChild, TemplateRef, ViewContainerRef, HostListener, AfterViewInit } from '@angular/core';
import { Platform, MenuController } from '@ionic/angular';
import {
  faAlignLeft,
  faCommentAlt,
  faFile,
  faHandHolding,
  faHandHoldingHeart,
  faIdCard,
  faQuestionCircle,
  faSearch,
  faSlidersH,
  faUserCog,
  faCog,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import { faListAlt, faTimes } from '@fortawesome/pro-regular-svg-icons';
import {
  faUsers,
  faFileCertificate,
  faFolders,
  faPaperPlane,
  faClipboard,
  faTachometerSlow,
  faPollH,
  faSignInAlt,
  faSignOutAlt,
  faFileExclamation,
} from '@fortawesome/pro-duotone-svg-icons';

import { faCreditCard, faHandHoldingBox } from '@fortawesome/pro-regular-svg-icons';
import { ToolsService } from './core/services/tools/tools.service';
import { AuthService } from './core/services/auth/auth.service';
import { Store, select } from '@ngrx/store';
import { State } from './core/ngrx/reducers';
import { authSelectors, userSelectors } from './core/ngrx/selectors';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Subscription, fromEvent } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthActions, UserActions } from './core/ngrx/actions';
import { defineCustomElements } from '@amoadmin/amo-admin-application-components/loader';
import awsconfig from '../aws-exports.js';
import Auth from '@aws-amplify/auth';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { SendEventActionsService } from 'src/app/core/services/events/sendEventActions.service';
import { datadogRum } from '@datadog/browser-rum';
import { LoadingService } from './core/services/loading/loading.service';
import { Title } from '@angular/platform-browser';
import { WorkerService } from './core/services/worker/worker.service';
import { NavigationService } from './core/services/navigation/navigation.service';
import { Warning } from './shared/services';
import { debounce } from './core/decorators/debounce.decorator';
import * as supportedBrowsers from '../supportedBrowsers';
import { detect } from 'detect-browser';
import { environment } from 'src/environments/environment';
/* eslint-disable  @typescript-eslint/no-explicit-any */

Auth.configure(awsconfig);
defineCustomElements(window);
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  sub: Subscription;
  showUpdateBanner: boolean;
  constructor(
    public router: Router,
    public overlay: Overlay,
    private platform: Platform,
    private store: Store<State>,
    public route: ActivatedRoute,
    public authService: AuthService,
    private menuCtrl: MenuController,
    private toolService: ToolsService,
    public loadingService: LoadingService,
    public viewContainerRef: ViewContainerRef,
    public angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private sendEventActionService: SendEventActionsService,
    public workerService: WorkerService,
    private title: Title,
    private navService: NavigationService,
    private activatedRoute: ActivatedRoute,
    private warning: Warning,
  ) {
    try {
      if (detect()?.name == 'safari') {
        if (!supportedBrowsers.test(navigator.userAgent)) {
          alert(
            "We are not able to support your browser's current version number. Please update your browser version or use" +
              ' a different browser. Your current version is ' +
              detect()?.name +
              ' ' +
              detect()?.version,
          );
        }
      }
    } catch (e) {
      // console.log('detect browser');
    }

    angulartics2GoogleTagManager.startTracking();
    this.initializeApp();
    this.setUserStates();
    this.hasToken();
    this.menuCtrl.swipeGesture(false);
    this.workerService.checkForUpdates();
    this.workerService.showUpdateBanner.subscribe(data => {
      this.showUpdateBanner = data;
    });
    this.orderIntakeFormLink = environment.clinicalRotationsRequestFormUrl;
  }

  overlayRef: OverlayRef | null; // right click menu check
  @ViewChild('navMenu', { static: true }) navMenu: TemplateRef<any>; // ng template for right click menu

  //window size
  public innerWidth: any;
  alreadyShowingMobileWarning: boolean = false;

  faFileExclamation = faFileExclamation;
  faAlignLeft = faAlignLeft;
  faTachometerSlow = faTachometerSlow;
  faCommentAlt = faCommentAlt;
  faSignOutAlt = faSignOutAlt;
  faPollH = faPollH;
  faFile = faFile;
  faCog = faCog;
  faHandHolding = faHandHolding;
  faIdCard = faIdCard;
  faSearch = faSearch;
  faSignInAlt = faSignInAlt;
  faSlidersH = faSlidersH;
  faHandHoldingHeart = faHandHoldingHeart;
  faQuestionCircle = faQuestionCircle;
  faUserCog = faUserCog;
  faHandHoldingBox = faHandHoldingBox;
  faCreditCard = faCreditCard;

  faUsers = faUsers;
  faFileCertificate = faFileCertificate;
  faFolders = faFolders;
  faPaperPlane = faPaperPlane;
  faClipboard = faClipboard;
  faExclamationCircle = faExclamationCircle;
  faListAlt = faListAlt;
  faTimes = faTimes;

  isLoggedIn = this.store.pipe(select(authSelectors.isAuthenticated));
  isReadOnlyExp = this.store.pipe(select(userSelectors.isReadOnly));
  managementType = this.store.pipe(select(userSelectors.isManagementType));
  user$ = this.store.pipe(select(userSelectors.userProfile));

  showVisitorInvites = false;
  showTraineeList = false;
  showProgramList = false;
  showManagerList = false;

  managementName = this.authService.getManagementName();
  isHostMat = this.authService.isHost();
  isRecruitmentMat = this.authService.isRecruitment();
  userIconName: string = '';
  loadActivityLogs: boolean = false;
  mobileUrls: String[] = ['/signup', '/login'];
  orderIntakeFormLink: string;
  dashboardUrl: string;
  initializeApp() {
    this.platform.ready().then(() => {});

    this.managementName.subscribe((name: string) => {
      this.userIconName = name
        .split(' ')
        .slice(0, 2)
        .map(l => l[0])
        .join('');
    });
  }

  ngAfterViewInit() {
    this.innerWidth = window.innerWidth;

    this.menuCtrl.swipeGesture(false, 'logs-menu');
    this.showMobileMessage();
  }

  logout() {
    this.navService.main('login');
    // this.authService.logout();
    // this.menuCtrl.enable(false);
  }

  updateMenuState(state: boolean) {
    if (state) {
      this.loadActivityLogs = true;
      this.menuCtrl.open('logs-menu');
      this.loadActivityLogs = true;
    } else {
      this.loadActivityLogs = false;
      this.menuCtrl.close('logs-menu');
    }
  }

  /**
   *
   * @param event mouse event, use coordinates to build template on
   * @param selectedTab string value of selected tab
   */
  open({ x, y }: MouseEvent, selectedTab: string) {
    // this try catch for event might not work later one
    // need another way to add to params for event
    try {
      // tslint:disable-next-line: deprecation
      event.preventDefault();
    } catch (err) {
      throw err;
    }

    this.close();
    // setup for context menu template
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close(),
    });
    // use ng template reference and attach tab string to it
    this.overlayRef.attach(
      new TemplatePortal(this.navMenu, this.viewContainerRef, {
        $implicit: selectedTab,
      }),
    );

    this.sub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1),
      )
      .subscribe(() => this.close());
  }

  /**
   * closes context menu
   */
  close() {
    this.sub && this.sub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  /**
   *
   * @param tab sting value for tab selected by right click context menu
   *
   */
  openNewTab(tab: string) {
    const url = window.location.origin;
    window.open(`${url}/${tab}`, '_blank');
    this.close();
  }

  hasToken() {
    this.authService.identifyAuthenticatedUser().subscribe(
      (res: any) => {
        if (res.isAuthenticated === true) {
          this.store.dispatch(
            AuthActions.setIsAuthenticated({
              is_authenticated: res.isAuthenticated,
            }),
          );
        } else {
          this.store.dispatch(
            AuthActions.setIsAuthenticated({
              is_authenticated: res.isAuthenticated,
            }),
          );
        }
      },
      (err: any) => {
        this.store.dispatch(
          AuthActions.setIsAuthenticated({
            is_authenticated: false,
          }),
        );
      },
    );
  }

  setUserStates() {
    // MTR add department hierarchy
    this.isLoggedIn.subscribe((auth: boolean) => {
      if (auth) {
        this.authService.getUserCache(false).subscribe(
          (res: any) => {
            // add user context to datadog
            datadogRum.setGlobalContext({
              usr: {
                id: res.id,
                email: res.email,
                name: res.name,
                type: res.type,
                role: res.role,
                departmentid: res.departmentid,
                department: res.Department ? res.Department.account : null,
              },
            });
            setTimeout(() => {
              datadogRum.addAction('loginSuccess', {
                id: res.id,
                email: res.email,
                name: res.name,
                type: res.type,
                role: res.role,
                department: res.Department ? res.Department.account : null,
              });
            }, 0);
            this.sendEventActionService.sendEventActions({
              evAction: 'admin_ManagerSetStates',
              evCategory: 'Admin Login',
              customDimensions: {
                cdAdminId: res.id,
                cdAdminType: res.type,
                cdAdminRole: res.role,
                cdInstitution: res.Department ? res.Department.account : null,
                cdHostId: res.hostId,
              },
              ddAction: 'user_login_buttonClick_success',
            });

            let managerType = res.type;

            // set dashboard type
            if (managerType === 'Hosting') {
              this.dashboardUrl = '/clinical-rotations';
            } else {
              this.dashboardUrl = '/dashboard';
            }

            let permissions: string;

            // find manager permissions based on manager type
            if (managerType === 'AMOEMPLOYEE') {
              permissions = res.permissions;
              // set employee state true
              this.store.dispatch(
                AuthActions.setIsEmployee({
                  isEmployee: true,
                }),
              );
            } else {
              permissions = res.rules && res.rules.permissions ? res.rules.permissions : '';
            }
            const checkApplicationWrite = permissions.includes('application:write');
            const checkProgramWrite = permissions.includes('program:write');

            this.showVisitorInvites = managerType.includes('Visitor-Recruitment');

            if (permissions && (permissions.includes('users:list') || permissions.includes('superuser'))) {
              this.showTraineeList = true;
            }

            if (permissions && (permissions.includes('program:list') === true || permissions.includes('superuser') === true)) {
              this.showProgramList = true;
            } else {
              this.showProgramList = false;
            }

            let ReadOnlyPermission;
            if (managerType !== 'AMOEMPLOYEE') {
              // check if both write permissions are missing
              if (!checkApplicationWrite && !checkProgramWrite) {
                ReadOnlyPermission = true;
              } else {
                ReadOnlyPermission = false;
              }
              // set read only experience for entire app
              this.store.dispatch(
                UserActions.setReadOnly({
                  isReadOnly: ReadOnlyPermission,
                }),
              );
            }

            const applicationWrite: boolean = ['application:write', 'superuser'].some(term => permissions.includes(term));

            this.store.dispatch(
              UserActions.setApplicationReadOnly({
                isApplicationReadOnly: !applicationWrite,
              }),
            );

            const hostWrite: boolean = ['host:write', 'host:create', 'superuser'].some(term => permissions.includes(term));

            this.store.dispatch(
              UserActions.setHostReadOnly({
                isHostReadOnly: !hostWrite,
              }),
            );

            const traineeWrite: boolean = ['user:write', 'user:create', 'superuser'].some(term => permissions.includes(term));

            this.store.dispatch(
              UserActions.setTraineeReadOnly({
                isTraineeReadOnly: !traineeWrite,
              }),
            );

            const paymentWrite: boolean = ['user:write', 'user:create', 'superuser'].some(term => permissions.includes(term));

            this.store.dispatch(
              UserActions.setPaymentReadOnly({
                isPaymentReadOnly: !paymentWrite,
              }),
            );

            const documentUpload: boolean = ['document:upload', 'superuser', 'manager', 'advisor', 'coordinator', 'coach'].some(term =>
              permissions.includes(term),
            );

            this.store.dispatch(
              UserActions.setDocumentUpload({
                canDocumentUpload: documentUpload,
              }),
            );

            const documentView: boolean = ['application:document:view', 'superuser', 'manager', 'advisor', 'coordinator', 'coach'].some(term =>
              permissions.includes(term),
            );

            this.store.dispatch(
              UserActions.setDocumentView({
                canDocumentView: documentView,
              }),
            );

            const orderWrite: boolean = ['orders:edit', 'admin', 'superuser'].some(term => permissions.includes(term));

            this.store.dispatch(
              UserActions.setOrderEdit({
                canOrderEdit: orderWrite,
              }),
            );

            const programWrite: boolean = ['superuser', 'program:write'].some(term => permissions.includes(term));

            this.store.dispatch(
              UserActions.setProgramWrite({
                canProgramWrite: programWrite,
              }),
            );

            // set manager type (hosting, visitor-recruitment, amo-employee)
            if (res.type) {
              this.store.dispatch(
                UserActions.setManagementType({
                  isManagementType: managerType.toUpperCase(),
                }),
              );
            }

            if (res.id) {
              this.store.dispatch(
                UserActions.setUser({
                  userProfile: {
                    id: res.id,
                    permissions: permissions,
                    type: managerType,
                    managerPermissions: res.permissions,
                    settings: res.settings,
                    rules: res.rules,
                    name: res.name,
                    updatedAt: res.updatedAt,
                  },
                }),
              );
            }

            // set manager name to nav bar
            if (res.name) {
              this.authService.setMgmtNameSubject.next(res.name);
            }
          },
          (err: any) => {},
        );

        this.store.dispatch(AuthActions.setIsAuthenticated({ is_authenticated: true }));
      } else {
        // Manager is not logged in send them to the login page with the next query string.
        let redirectUrl = decodeURIComponent(window.location.href);

        if (
          redirectUrl.includes('login') === false &&
          redirectUrl.includes('signup') === false &&
          redirectUrl.includes('reset-password') === false &&
          redirectUrl.includes('document') === false &&
          redirectUrl.includes('__auth') === false
        ) {
          this.router.navigate(['/__auth'], {
            relativeTo: this.activatedRoute,
            queryParams: {
              next: redirectUrl,
            },
            replaceUrl: true,
          });
        }
      }
    });
  }

  openOrderIntake() {
    if (this.orderIntakeFormLink) {
      window.open(this.orderIntakeFormLink, '_blank');
    }
  }

  getwebsiteUpdates() {
    this.workerService.clearShowBanner();
    this.workerService.reload();
  }

  @HostListener('window:resize', ['$event'])
  @debounce()
  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    this.showMobileMessage();
  }

  showMobileMessage() {
    setTimeout(() => {
      if (this.innerWidth < 750 && !this.alreadyShowingMobileWarning && !this.mobileUrls.includes(this.router.url)) {
        this.warning.create('Mobile Version Coming Soon. Please use a desktop computer.');
        this.alreadyShowingMobileWarning = true;
      } else {
        this.alreadyShowingMobileWarning = false;
      }
    }, 1000);
  }
}
