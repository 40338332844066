import { Component } from '@angular/core';

// import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { IAfterGuiAttachedParams } from 'ag-grid-community';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import { faSignIn } from '@fortawesome/pro-solid-svg-icons';
import { ToolsService } from '../../tools/tools.service';
import { environment } from 'src/environments/environment';
import { ApplicationService } from '../../application/application.service';
import { Warning } from 'src/app/shared/services';
import { AuthService } from '../../auth/auth.service';
import { throwError } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { LoadingService } from '../../loading/loading.service';
import { Router } from '@angular/router';

@Component({
  selector: 'child-cell',
  template: ` <a class="select-cell" (click)="eventSelection()"><fa-icon [icon]="faSignIn" class="link-icon"></fa-icon></a> `,
  styles: [
    `
      .link-icon {
        line-height: 0.5;
        color: var(--ion-color-teal);
        margin-left: 0.4em;
      }
      .select-cell {
        cursor: pointer;
        width: 100%;
        height: 100%;
      }
    `,
  ],
})
export class GridSelectionRenderer implements ICellRendererAngularComp {
  visitors: [];
  goodStandingVisitors = [];
  gridApi: any;

  applicants = '';

  constructor(
    private toolService: ToolsService,
    private applicationService: ApplicationService,
    private warning: Warning,
    private authService: AuthService,
    private cookie: CookieService,
    private loading: LoadingService,
    private route: Router,
  ) {
    this.toolService.getVisitorsList.subscribe((data: any) => {
      this.visitors = data;
    });
  }

  private params: any;

  public userName = '';
  public faExternalLink = faExternalLink;
  public faSignIn = faSignIn;
  refresh(params: any): boolean {
    throw new Error('Method not implemented.');
  }
  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    throw new Error('Method not implemented.');
  }

  agInit(params: any): void {
    this.gridApi = params.api;
    this.params = params;
  }

  eventSelection(): void {
    switch (this.params.page) {
      case 'dashboard':
        this.openApplication();
        break;
      case 'activeApplications':
        this.openApplication();
        break;
      case 'application':
        this.openApplication();
        break;
      case 'amoApplication':
        this.openAmoApplication();
        break;
      case 'program':
        this.openProgram();
        break;
      case 'host':
        this.openHost();
        break;
      case 'trainee':
        this.openTrainee();
        break;
      case 'managers':
        this.openManager();
        break;
      case 'departments':
        this.openDepatments();
        break;
      case 'payment':
        this.openPayment();
        break;
      case 'documents':
        this.openDocuments();
        break;
      case 'requirements':
        this.openRequirements();
        break;
      case 'applicationChain':
        this.openApplicationChain();
        break;
      case 'programbundles':
        this.openProgramBundle();
        break;
      case 'coupons':
        this.openCoupons();
        break;
      case 'reviewTemplates':
        this.openReviewTemplates();
        break;
      case 'reviews':
        this.openReviews();
        break;
      case 'nestedinstitutionapplication':
        this.openAmoApplication();
        break;
      case 'fieldOfStudies':
        this.openFieldofstudies();
        break;
      case 'orders':
        this.openOrders();
        break;
      case 'applicationordertype':
        this.openApplicationOrderTypes();
        break;
      case 'orderFromHost':
        this.openOrdersFromHost();
        break;
      case 'adminstudenttype':
        this.openAdminStudentTypes();
        break;
      default:
        break;
    }
  }

  openAmoApplication() {
    if (this.params) {
      const applicationid = this.params.data.applicationid !== undefined ? this.params.data.applicationid : this.params.data.id;
      this.route.navigate([`/new-application/details/${applicationid}/application-quick-summary`]);
    }
  }

  openApplication() {
    if (this.params) {
      const applicationid = this.params.data.applicationid !== undefined ? this.params.data.applicationid : this.params.data.id;
      this.route.navigate([`/application-host/${applicationid}`]);
    }
  }

  openApplicationChain() {
    if (this.params) {
      const applicationid = this.params.data.applicationid !== undefined ? this.params.data.applicationid : this.params.data.id;
      this.route.navigate([`/new-application/chains/details/${applicationid}`]);
    }
  }

  openProgram() {
    const programid = this.params.data.id;

    if (this.visitors.length > 0) {
      this.checkForApplication(programid);
    } else if (this.params.user.type === 'Visitor-Recruitment') {
      const url = `${environment.app}/program/${programid}?mgmttype=permalink`;
      window.open(url, '_blank');
    } else {
      this.route.navigate([`/programs/details/${programid}`]);
    }
  }

  openTrainee() {
    if (this.params && this.params.data) {
      this.route.navigate([`/trainees/details/${this.params.data.id}/trainee-information`]);
    }
  }

  openDepatments() {
    //institutions
    if (this.params && this.params.data) {
      this.route.navigate([`/institutions/details/institutions-information`, this.params.data.id], {
        queryParams: {
          id: this.params.data.id,
        },
      });
    }
  }

  openManager() {
    if (this.params && this.params.data) {
      if (this.params.data.departmentid) {
        this.route.navigate([`/institutions/details/managers/edit`], {
          queryParams: {
            id: this.params.data.departmentid,
            managerid: this.params.node.data.id,
          },
        });
      } else {
        this.route.navigate([`/managers/details/${this.params.data.id}`]);
      }
    }
  }

  // runs a check on visitors selected for program compatibility;
  // if the visitor can't apply to the program, we give a warning and not go app side
  checkForApplication(id) {
    let jobs = 0;
    this.visitors.forEach((app: any) => {
      const applicationcheck = {
        programid: id,
        userid: app.id,
        fullname: app.name,
        useremail: app.email,
        status: app.status,
      };
      this.applicationService.checkpartnersapplication(applicationcheck).subscribe(data => {
        if (data.type === 'warning') {
          this.warning.create(`${data.message}`);
          try {
            // makes the already selected program selectable again after click
            this.gridApi.deselectAll();
          } catch (error) {
            return throwError(error);
          }
        } else {
          // jim carrey: 'it's goooooood!'
          // application for visitors are able to be created
          // we will process their data to a token
          if (data.status === 'good') {
            const check = this.goodStandingVisitors.some((checkingUser: any) => {
              return checkingUser.id === data.user.id;
            });
            if (check === false) {
              this.goodStandingVisitors.push(data.user);
            }
            jobs++;
            if (jobs === this.visitors.length) {
              this.goAppSide(id);
              // makes the already selected program selectable again after click
              try {
                this.gridApi.deselectAll();
              } catch (error) {
                return throwError(error);
              }
            }
          }
        }
      });
    });
  }

  goAppSide(id) {
    this.loading.presentLazyLoadingCtrl('Loading...', 2000);
    if (this.params.user.type === 'Hosting') {
      this.toolService.hostProcessVisitorURL(this.goodStandingVisitors).subscribe(
        (res: any) => {
          this.authService.createCookie('visitorList', res.token);
          this.applicants = this.authService.findCookie('visitorList');

          // tslint:disable-next-line:max-line-length
          const url = `${environment.app}/program/${id}?visitors=${this.applicants}&mgmttype=${this.params.user.type}`;
          window.open(url, '_blank');
          setTimeout(() => {
            this.clearVisitors().then((res: any) => {
              this.route.navigate(['/dashboard']);
            });
          }, 10000);
        },
        err => this.warning.create('You do not have permissions to create visitor applications.'),
      );
    } else {
      this.toolService.processVisitorsURL(this.goodStandingVisitors).subscribe(
        (res: any) => {
          this.authService.createCookie('visitorList', res.token);
          this.applicants = this.authService.findCookie('visitorList');
          // tslint:disable-next-line:max-line-length
          const url = `${environment.app}/program/${id}?&visitors=${this.applicants}&mgmttype=${this.params.user.type}`;
          window.open(url, '_blank');
          setTimeout(() => {
            this.clearVisitors().then((res: any) => {});
          }, 10000);
        },
        err => this.warning.create('You do not have permissions to create visitor applications.'),
      );
    }
  }

  clearVisitors() {
    return new Promise((resolve, reject) => {
      this.toolService.setVisitorList([]);
      localStorage.removeItem('names');
      this.cookie.delete('visitorList');
      resolve(true);
    });
  }

  openHost() {
    if (this.params.user.type === 'AMOEMPLOYEE' && this.params.data.departmentid) {
      // this.route.navigate(["/institutions/details/hosts/edit"], {
      //   queryParams: {
      //     id: this.params.data.departmentid,
      //     hostid: this.params.data.id
      //   }
      // });
      this.route.navigate(['/hosts/details/edit'], {
        queryParams: {
          id: this.params.data.departmentid,
          hostid: this.params.data.id,
        },
      });
    } else {
      this.route.navigate([`/hosts/edit/${this.params.data.id}`]);
    }
  }

  openPayment() {
    this.route.navigate([`/payments/details/${this.params.data.paymentrecordid}`]);
  }

  openDocuments() {
    if (this.params) {
      this.route.navigate([`/documents/details/${this.params.data.id}`]);
    }
  }

  openRequirements() {
    if (this.params) {
      this.route.navigate([`/requirements/details/${this.params.data.id}`]);
    }
  }

  openProgramBundle() {
    if (this.params) {
      this.route.navigate([`/program-bundles/details/${this.params.data.id}`]);
    }
  }

  openCoupons() {
    if (this.params) {
      this.route.navigate([`/coupons/details/${this.params.data.id}`]);
    }
  }

  openReviews() {
    this.route.navigate([`/reviews/details/${this.params.data.id}`]);
  }

  openReviewTemplates() {
    this.route.navigate([`/review-templates/details/${this.params.data.id}`]);
  }

  openFieldofstudies() {
    this.route.navigate([`/settings/field-of-studies/details/${this.params.data.id}`]);
  }

  openOrders() {
    if (this.params.extra === 'orderPrograms') {
      this.route.navigate([`/orders/details/${this.params.data.Order.id}`]);
    } else {
      this.route.navigate([`/orders/details/${this.params.data.id}`]);
    }
  }

  openOrdersFromHost() {
    this.route.navigate([`/orders/details/${this.params.data.ProgramOrder.Order.id}`]);
  }

  openApplicationOrderTypes() {
    this.route.navigate([`/settings/application-order-types/detail/${this.params.data.id}`]);
  }

  openAdminStudentTypes() {
    this.route.navigate([`/settings/admin-student-types/details/${this.params.data.id}`]);
  }
}
