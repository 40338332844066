<ion-app>
  <ion-header class="ion-no-border" contentId="menu-content">
    <ion-progress-bar *ngIf="loadingService.isLoading | async" type="indeterminate"> </ion-progress-bar>
    <ion-toolbar class="tabs-container">
      <ion-buttons slot="start">
        <a [routerLink]="['/dashboard']">
          <img class="amo-logo" src="../assets/logo/logo_orange.png" alt="AMO" />
        </a>
        <ion-chip color="primary" mode="ios" outline="false" *ngIf="isReadOnlyExp | async" class="readonly-chip">
          <ion-icon name="eye"></ion-icon>

          <ion-label class="readonly-label"> Read-only </ion-label>
        </ion-chip>
      </ion-buttons>
      <ion-buttons slot="primary">
        <div *ngIf="isLoggedIn | async" class="header-button-row">
          <button
            *ngIf="(managementType | async) === 'VISITOR-RECRUITMENT'"
            class="global-nav-bar custom-school-request-button"
            mat-button
            (click)="openOrderIntake()"
            matTooltip="Clinical Rotations Request Form "
          >
            Submit New Request
          </button>
          <button
            *ngIf="(managementType | async) === 'PROVISIONAL'"
            class="global-nav-bar"
            mat-button
            [routerLink]="['/program-availability']"
            (contextmenu)="open($event, 'program-availability')"
            matTooltip="Program Availability"
          >
            Program Availability
          </button>
          <button
            *ngIf="(managementType | async) !== 'PROVISIONAL'"
            class="global-nav-bar"
            mat-button
            [routerLink]="
              (managementType | async) === 'HOSTING' && (user$ | async)?.settings?.ui?.defaultview === 'calendar'
                ? [dashboardUrl + '/calendar']
                : [dashboardUrl]
            "
            (contextmenu)="open($event, 'dashboard')"
            matTooltip="Dashboard"
          >
            Dashboard
          </button>
          <button
            *ngIf="(managementType | async) === 'VISITOR-RECRUITMENT'"
            class="global-nav-bar"
            mat-button
            [routerLink]="['/clinical-rotations']"
            (contextmenu)="open($event, 'clinical-rotations')"
            matTooltip="My Clinical Rotations"
          >
            My Clinical Rotations
          </button>
          <button
            *ngIf="(managementType | async) === 'AMOEMPLOYEE'"
            class="global-nav-bar"
            mat-button
            [routerLink]="['/institutions']"
            (contextmenu)="open($event, 'institutions')"
            matTooltip="Institutions"
          >
            Institutions
          </button>

          <!-- MANAGERS -->
          <button
            class="global-nav-bar"
            *ngIf="(managementType | async) === 'AMOEMPLOYEE'"
            mat-button
            [routerLink]="['/managers']"
            (contextmenu)="open($event, 'managers')"
            matTooltip="Managers"
          >
            Managers
          </button>

          <!-- <button
            class="global-nav-bar"
            *ngIf="
              showProgramList || (managementType | async) === 'AMOEMPLOYEE'
            "
            mat-button
            [routerLink]="['/program-bundles']"
            (contextmenu)="open($event, 'program-bundles')"
            matTooltip="Program Bundles"
          >
            Program Bundles
          </button> -->

          <!-- PROGRAMS -->

          <button
            class="global-nav-bar"
            *ngIf="showProgramList && (managementType | async) !== 'AMOEMPLOYEE'"
            mat-button
            [routerLink]="['/programs']"
            (contextmenu)="open($event, 'programs')"
            matTooltip="Programs"
          >
            Programs
          </button>

          <button class="global-nav-bar" *ngIf="(managementType | async) === 'AMOEMPLOYEE'" mat-button [matMenuTriggerFor]="menuPrograms" matTooltip="Programs">
            Programs
            <ion-icon name="caret-down-outline"></ion-icon>
          </button>
          <mat-menu #menuPrograms="matMenu">
            <a class="help-links" target="_self" [routerLink]="['/programs']" (contextmenu)="open($event, 'programs')" mat-menu-item> Program Table </a>
            <a class="help-links" target="_self" [routerLink]="['/program-bundles']" (contextmenu)="open($event, 'program-bundles')" mat-menu-item>
              Program Bundles Table
            </a>
            <a
              *ngIf="(managementType | async) === 'AMOEMPLOYEE'"
              class="help-links"
              target="_self"
              [routerLink]="['/program-availability']"
              (contextmenu)="open($event, 'program-availability')"
              mat-menu-item
            >
              Program Availability Table
            </a>
          </mat-menu>

          <!-- <button class="global-nav-bar" *ngIf="showTraineeList" mat-button [routerLink]="['/visitors']" (contextmenu)="open($event, 'visitors')" matTooltip="Trainee">
            Trainees
          </button> -->
          <button
            class="global-nav-bar"
            *ngIf="(managementType | async) === 'VISITOR-RECRUITMENT' || showTraineeList"
            mat-button
            [routerLink]="['/trainees']"
            (contextmenu)="open($event, 'trainees')"
            matTooltip="Trainee"
          >
            Trainees
          </button>
          <button
            *ngIf="(managementType | async) === 'HOSTING' || (managementType | async) === 'AMOEMPLOYEE'"
            class="global-nav-bar"
            mat-button
            [routerLink]="['/hosts']"
            (contextmenu)="open($event, 'hosts')"
            matTooltip="Hosts"
          >
            Hosts
          </button>
          <button
            class="global-nav-bar"
            *ngIf="(managementType | async) === 'AMOEMPLOYEE'"
            mat-button
            [matMenuTriggerFor]="menuApplication"
            matTooltip="Applications"
          >
            Applications
            <ion-icon name="caret-down-outline"></ion-icon>
          </button>
          <mat-menu #menuApplication="matMenu">
            <a class="help-links" target="_self" (contextmenu)="open($event, 'new-application')" [routerLink]="['/new-application']" mat-menu-item>
              Application Table
            </a>
            <a
              class="help-links"
              target="_self"
              (contextmenu)="open($event, 'new-application/chains')"
              [routerLink]="['/new-application/chains']"
              mat-menu-item
            >
              Application Chain Table
            </a>
          </mat-menu>
          <button
            *ngIf="(managementType | async) === 'HOSTING'"
            class="global-nav-bar"
            mat-button
            [routerLink]="['/active-applications']"
            (contextmenu)="open($event, 'active-applications')"
            matTooltip="Applications"
          >
            Applications
          </button>
          <button
            *ngIf="(managementType | async) === 'VISITOR-RECRUITMENT'"
            class="global-nav-bar"
            mat-button
            [routerLink]="['/applications']"
            (contextmenu)="open($event, 'applications')"
            matTooltip="Applications"
          >
            Applications
          </button>
          <!-- <button
            *ngIf="(managementType | async) === 'VISITOR-RECRUITMENT'"
            class="global-nav-bar"
            mat-button
            [routerLink]="['/invite-visitors']"
            (contextmenu)="open($event, 'invite-visitors')"
            matTooltip="Invite"
          >
            Invite
          </button> -->
          <button
            *ngIf="(managementType | async) === 'AMOEMPLOYEE'"
            class="global-nav-bar"
            mat-button
            [routerLink]="['/payments']"
            (contextmenu)="open($event, 'payments')"
            matTooltip="Payments"
          >
            Payments
          </button>
          <!-- <button
            *ngIf="(managementType | async) === 'AMOEMPLOYEE'"
            class="global-nav-bar"
            mat-button
            [routerLink]="['/documents']"
            (contextmenu)="open($event, 'documents')"
            matTooltip="Documents"
          >
            Documents
          </button> -->
          <!-- <button
            *ngIf="(managementType | async) === 'AMOEMPLOYEE'"
            class="global-nav-bar"
            mat-button
            [routerLink]="['/reviews']"
            (contextmenu)="open($event, 'reviews')"
            matTooltip="Reviews"
          >
            Reviews
          </button> -->

          <!-- <button
            *ngIf="(managementType | async) === 'AMOEMPLOYEE'"
            class="global-nav-bar"
            mat-button
            [routerLink]="['/requirements']"
            (contextmenu)="open($event, 'requirements')"
            matTooltip="Requirements"
          >
            Requirements
          </button> -->

          <!-- <button
            *ngIf="(managementType | async) === 'AMOEMPLOYEE'"
            class="global-nav-bar"
            mat-button
            [routerLink]="['/coupons']"
            (contextmenu)="open($event, 'coupons')"
            matTooltip="Coupons"
          >
            Coupons
          </button> -->

          <button
            class="global-nav-bar"
            *ngIf="(managementType | async) === 'AMOEMPLOYEE' || (managementType | async) === 'VISITOR-RECRUITMENT'"
            mat-button
            [matMenuTriggerFor]="menuAdditionals"
            (contextmenu)="open($event, 'additional-pages')"
            matTooltip="Applications"
          >
            Additional Pages
            <ion-icon name="caret-down-outline"></ion-icon>
          </button>
          <mat-menu #menuAdditionals="matMenu" class="whitebackground">
            <a
              *ngIf="(managementType | async) === 'AMOEMPLOYEE'"
              class="help-links"
              target="_self"
              [routerLink]="['/coupons']"
              (contextmenu)="open($event, 'coupons')"
              mat-menu-item
            >
              Coupons
            </a>
            <a
              *ngIf="(managementType | async) === 'AMOEMPLOYEE'"
              class="help-links"
              target="_self"
              [routerLink]="['/documents']"
              (contextmenu)="open($event, 'documents')"
              mat-menu-item
            >
              Documents
            </a>
            <a
              *ngIf="(managementType | async) === 'VISITOR-RECRUITMENT'"
              class="help-links"
              target="_self"
              [routerLink]="['/invite-visitors']"
              (contextmenu)="open($event, 'invite-visitors')"
              mat-menu-item
            >
              Invite
            </a>
            <a
              *ngIf="(managementType | async) === 'AMOEMPLOYEE'"
              class="help-links"
              target="_self"
              [routerLink]="['/requirements']"
              (contextmenu)="open($event, 'requirements')"
              mat-menu-item
            >
              Requirements
            </a>
            <a
              *ngIf="(managementType | async) === 'AMOEMPLOYEE'"
              class="help-links"
              target="_self"
              [routerLink]="['/application-requirements-map']"
              (contextmenu)="open($event, 'application-requirements-map')"
              mat-menu-item
            >
              Application Requirements Map
            </a>
            <a
              *ngIf="(managementType | async) === 'AMOEMPLOYEE'"
              class="help-links"
              target="_self"
              [routerLink]="['/reviews']"
              (contextmenu)="open($event, 'reviews')"
              mat-menu-item
            >
              Reviews
            </a>

            <a
              *ngIf="(managementType | async) === 'AMOEMPLOYEE'"
              class="help-links"
              target="_self"
              [routerLink]="['/review-templates']"
              (contextmenu)="open($event, 'review-templates')"
              mat-menu-item
            >
              Reviews Templates
            </a>

            <a
              *ngIf="(managementType | async) === 'AMOEMPLOYEE'"
              class="help-links"
              target="_self"
              [routerLink]="['/itineraries']"
              (contextmenu)="open($event, 'itineraries')"
              mat-menu-item
            >
              Itineraries
            </a>
            <a
              *ngIf="(managementType | async) === 'AMOEMPLOYEE'"
              class="help-links"
              target="_self"
              [routerLink]="['/orders']"
              (contextmenu)="open($event, 'orders')"
              mat-menu-item
            >
              Orders
            </a>
          </mat-menu>

          <ion-menu-toggle menu="end" fill="clear" color="light" class="log-list-menu" *ngIf="(managementType | async) === 'AMOEMPLOYEE'">
            <ion-button fill="clear" color="light">
              <fa-icon [icon]="faListAlt"></fa-icon>
            </ion-button>
          </ion-menu-toggle>

          <button class="global-nav-bar" mat-button [matMenuTriggerFor]="menuSettings" matTooltip="Manager Profile">
            <!-- <ion-icon name="contact"></ion-icon>
            {{ managementName | async }}
            <ion-icon name="caret-down-outline"></ion-icon> -->
            <div class="profile-text-wrapper">
              <div class="icon-wrapper">
                <span>{{ userIconName }}</span>
              </div>
              <ion-icon name="caret-down-outline"></ion-icon>
            </div>
          </button>

          <mat-menu #menuSettings="matMenu">
            <div class="mat-menu-item title-button">
              <ion-label class="profile-title">
                {{ managementName | async }}
              </ion-label>
            </div>

            <a
              class="help-links"
              matTooltip="Profile"
              (contextmenu)="open($event, 'profile')"
              mat-menu-item
              [routerLink]="['/settings/profile']"
              [routerDirection]="'forward'"
            >
              Profile
            </a>
            <a
              class="help-links"
              matTooltip="Settings"
              (contextmenu)="open($event, 'settings')"
              mat-menu-item
              [routerLink]="['/settings/account-settings']"
              [routerDirection]="'forward'"
            >
              Settings
            </a>
            <a class="help-links" mat-menu-item (click)="logout()" matTooltip="Login"> Logout </a>
          </mat-menu>
        </div>
        <div *ngIf="(isLoggedIn | async) === false" class="header-button-row">
          <button class="global-nav-bar" mat-button [routerLink]="['/signup']" matTooltip="Create Account">Create Account</button>
        </div>
        <div *ngIf="(isLoggedIn | async) === false" class="header-button-row">
          <button class="global-nav-bar" mat-button [routerLink]="['/login']" matTooltip="Login">Login</button>
        </div>
      </ion-buttons>

      <!-- Template for right click menu -->
      <ng-template #navMenu let-selectedTab>
        <section>
          <ion-item class="context-menu" button (click)="openNewTab(selectedTab)">
            <ion-label>Open in new tab</ion-label>
          </ion-item>
        </section>
      </ng-template>
    </ion-toolbar>
  </ion-header>
  <ion-menu side="start" swipe-gesture="false" contentId="menu-content">
    <ion-header>
      <ion-toolbar slot="start">
        <ion-title>Menu </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list *ngIf="(isLoggedIn | async) === false">
        <ion-menu-toggle auto-hide="false">
          <ion-item [routerDirection]="'root'" [routerLink]="['/login']">
            <ion-label> Log In </ion-label>
          </ion-item>
          <ion-item [routerDirection]="'root'" [routerLink]="['/signup']">
            <ion-label> Create Account </ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
      <ion-list *ngIf="isLoggedIn | async">
        <ion-menu-toggle>
          <ion-item button *ngIf="(managementType | async) === 'PROVISIONAL'" [routerLink]="['/program-availability']">
            <ion-label> Program Availability </ion-label>
          </ion-item>
          <ion-item
            button
            [routerLink]="
              (managementType | async) === 'HOSTING' && (user$ | async)?.settings?.ui?.defaultview === 'calendar'
                ? [dashboardUrl + '/calendar']
                : [dashboardUrl]
            "
            *ngIf="(managementType | async) !== 'PROVISIONAL'"
          >
            <ion-label> Dashboard </ion-label>
          </ion-item>
          <ion-item button *ngIf="(managementType | async) === 'AMOEMPLOYEE'" [routerLink]="['/institutions']">
            <ion-label> Institutions </ion-label>
          </ion-item>

          <ion-item button *ngIf="(managementType | async) === 'AMOEMPLOYEE'" [routerLink]="['/managers']">
            <ion-label> Managers </ion-label>
          </ion-item>
          <ion-item button *ngIf="showProgramList" [routerLink]="['/experiences']">
            <ion-label> Programs </ion-label>
          </ion-item>
          <ion-item button *ngIf="(managementType | async) === 'VISITOR-RECRUITMENT' || showTraineeList" [routerLink]="['/trainees']">
            <ion-label> Trainees </ion-label>
          </ion-item>
          <ion-item button *ngIf="(managementType | async) === 'HOSTING' || (managementType | async) === 'AMOEMPLOYEE'" [routerLink]="['/hosts']">
            <ion-label> Hosts </ion-label>
          </ion-item>
          <ion-item button *ngIf="(managementType | async) === 'HOSTING'" [routerLink]="['/active-applications']">
            <ion-label> Applications </ion-label>
          </ion-item>
          <ion-item *ngIf="(managementType | async) === 'VISITOR-RECRUITMENT'" button [routerLink]="['/applications']">
            <ion-label> Applications </ion-label>
          </ion-item>
          <ion-item *ngIf="(managementType | async) === 'VISITOR-RECRUITMENT'" button [routerLink]="['/invite-visitors']">
            <ion-label> Invite </ion-label>
          </ion-item>
          <ion-item button *ngIf="(managementType | async) === 'AMOEMPLOYEE'" [routerLink]="['/reviews']">
            <ion-label> Reviews </ion-label>
          </ion-item>
          <ion-item (click)="logout()">
            <ion-label> Log out </ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>
  </ion-menu>
  <div *ngIf="showUpdateBanner" class="text-center bannerUpdateShow">
    <div class="icon">
      <fa-icon [icon]="faExclamationCircle"></fa-icon>
    </div>
    <div class="banner-msg">
      <ion-label>Website Update Detected.</ion-label>
      <ion-label
        >A new version of the website is available, click &apos;Update Now&apos; for best experience. Please save all changes before updating.</ion-label
      >
    </div>
    <div>
      <ion-button id="letsupdate" mode="md" size="small" fill="outline" color="light" type="button" (click)="getwebsiteUpdates()">Update Now </ion-button>
    </div>
  </div>
  <ion-menu
    *ngIf="(isLoggedIn | async) && (managementType | async) === 'AMOEMPLOYEE'"
    class="logs"
    side="end"
    swipe-gesture="false"
    contentId="menu-content"
    type="overlay"
    menuId="logs-menu"
    (ionWillClose)="updateMenuState(false)"
    (ionWillOpen)="updateMenuState(true)"
  >
    <ion-header>
      <div class="log-list-wrapper">
        <ion-label class="log-model-title">Admin Activity Log</ion-label>
        <ion-menu-toggle menu="end" fill="clear" class="log-list-close">
          <ion-button fill="clear" color="light">
            <fa-icon [icon]="faTimes"></fa-icon>
          </ion-button>
        </ion-menu-toggle>
      </div>
    </ion-header>
    <ion-content>
      <!-- AMOEMployees, Hosting, Visitor Recruitment -->
      <app-activity-log
        [action]="'all'"
        [actionId]="'none'"
        [showSpinner]="true"
        *ngIf="loadActivityLogs && (managementType | async) === 'AMOEMPLOYEE'"
      ></app-activity-log>
    </ion-content>
  </ion-menu>
  <ion-router-outlet id="menu-content"></ion-router-outlet>
</ion-app>
